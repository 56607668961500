import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {EdwCustomProject} from './CustomProject';

export class EdwCustomProjectSerializer implements EntitySerializer {

    public mapping = new Map([
        ['attachedFileName', 'attached_file_name'],
        ['portfolio', 'portfolio'],
        ['project', 'project'],
        ['projectExport', 'project_export'],
        ['files', 'files'],
    ]);

    fromJson(json: any): EdwCustomProject {
        const edwCustomProject = new EdwCustomProject();
        this.mapping.forEach((value, key, map) => {
            edwCustomProject[key] = json[value];
        });
        return edwCustomProject;
    }

    toApi(edwCustomProject: EdwCustomProject): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = edwCustomProject[key];
        });
        return obj;
    }
}
