import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {APP_INITIALIZER, ModuleWithProviders, NgModule, Provider, Type} from '@angular/core';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {
    DateInterceptor,
    EnvConfigService,
    ErrorInterceptor,
    JwtInterceptor,
    WorkspaceInterceptor
} from '@edward-software/edw-fwk-angular-lib/providers';
import {ENVIRONMENT_PARAMETER, EnvironmentParameter} from '@edward-software/edw-fwk-angular-lib/models';
import {Observable} from 'rxjs';

export function init(provider: EnvConfigService) {
    return () => provider.getEnvConfig();
}


/**
 * HACK EN ATTENDANT LA MAJ de INTERCOM
 * QUI DOIT TYPER SON MODULE
 */
declare module '@angular/core' {
    interface ModuleWithProviders<T = any> {
        ngModule: Type<T>;
        providers?: Provider[];
    }
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
    wheelPropagation: true
};



@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [
        EnvConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: init,
            deps: [EnvConfigService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: WorkspaceInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DateInterceptor,
            multi: true
        },

    ]
})
export class EdwFwkAngularLibModule {
    static forRoot(environmentParameter: EnvironmentParameter): ModuleWithProviders<EdwFwkAngularLibModule> {
        return {
            ngModule: EdwFwkAngularLibModule,
            providers: [
                {
                    provide: ENVIRONMENT_PARAMETER,
                    useValue: environmentParameter,
                },
                {
                    provide: PERFECT_SCROLLBAR_CONFIG,
                    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
                }
            ],
        };
    }
}
