import {Entity} from '../entity-dir/Entity';
import {EdwValidation} from './Validation';

/**
 * On utilise ValidationType pour recuperer les validations de type 'custom', 'zone' et 'attachment'
 */
export class EdwValidationType extends Entity {
    private _validation?: EdwValidation;
    private _data?: any;
    private _creationDate?: Date;
    private _files?: any[];
    private _attachedFiles?: any[];
    private _objectId: string;
    private _objectType: string;
    private _content: object;
    private _zoneId: string;
    private _lockObject: boolean;
    private _lockedField: string;

    constructor() {
        super();
    }

    get validation(): EdwValidation {
        return this._validation;
    }

    set validation(value: EdwValidation) {
        this._validation = value;
    }

    get data(): any {
        return this._data;
    }

    set data(value: any) {
        this._data = value;
    }

    get creationDate(): Date {
        return this._creationDate;
    }

    set creationDate(value: Date) {
        this._creationDate = value;
    }

    get files(): any[] {
        return this._files;
    }

    set files(value: any[]) {
        this._files = value;
    }

    get attachedFiles(): any[] {
        return this._attachedFiles;
    }

    set attachedFiles(value: any[]) {
        this._attachedFiles = value;
    }

    get objectId(): string {
        return this._objectId;
    }

    set objectId(value: string) {
        this._objectId = value;
    }

    get objectType(): string {
        return this._objectType;
    }

    set objectType(value: string) {
        this._objectType = value;
    }

    get content(): object {
        return this._content;
    }

    set content(value: object) {
        this._content = value;
    }

    get zoneId(): string {
        return this._zoneId;
    }

    set zoneId(value: string) {
        this._zoneId = value;
    }

    get lockObject(): boolean {
        return this._lockObject;
    }

    set lockObject(value: boolean) {
        this._lockObject = value;
    }

    get lockedField(): string {
        return this._lockedField;
    }

    set lockedField(value: string) {
        this._lockedField = value;
    }

}
