import {Entity} from '../entity-dir/Entity';
import {EdwValidationStepResult} from './ValidationStepResult';
import {EdwContactValidationStep} from './ContactValidationStep';

export class EdwValidationStep extends Entity {

    private _finalResult: string;
    private _validationDate: Date;
    private _label: string;
    private _position: number;
    private _step_duration: string;
    private _description: string;
    private _validationStepResults: EdwValidationStepResult[];
    private _contactValidationSteps: EdwContactValidationStep[];



    constructor() {
        super();
        this.contactValidationSteps = [];
    }

    get finalResult(): string {
        return this._finalResult;
    }

    set finalResult(value: string) {
        this._finalResult = value;
    }

    get validationDate(): Date {
        return this._validationDate;
    }

    set validationDate(value: Date) {
        this._validationDate = value;
    }

    get label(): string {
        return this._label;
    }

    set label(value: string) {
        this._label = value;
    }

    get position(): number {
        return this._position;
    }

    set position(value: number) {
        this._position = value;
    }

    get step_duration(): string {
        return this._step_duration;
    }

    set step_duration(value: string) {
        this._step_duration = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get contactValidationSteps(): EdwContactValidationStep[] {
        return this._contactValidationSteps;
    }

    set contactValidationSteps(value: EdwContactValidationStep[]) {
        this._contactValidationSteps = value;
    }

    get validationStepResults(): EdwValidationStepResult[] {
        return this._validationStepResults;
    }

    set validationStepResults(value: EdwValidationStepResult[]) {
        this._validationStepResults = value;
    }

    public findContactValidationStepByPriority(priority: number) {
        if (this.contactValidationSteps && this.contactValidationSteps instanceof Array) {
            return this.contactValidationSteps.find(cvs => cvs.priority === priority);
        } else {
            return null;
        }
    }
}
