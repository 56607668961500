import {Entity} from '../entity-dir/Entity';

export class EdwCustomProject extends Entity {
    private _attachedFileName?: string;
    private _portfolio?: object;
    private _project?: object;
    private _projectExport?: object;
    private _files?: object[];
    constructor() {
        super();
    }
    get attachedFileName(): string {
        return this._attachedFileName;
    }

    set attachedFileName(value: string) {
        this._attachedFileName = value;
    }

    get portfolio(): object {
        return this._portfolio;
    }

    set portfolio(value: object) {
        this._portfolio = value;
    }

    get project(): object {
        return this._project;
    }

    set project(value: object) {
        this._project = value;
    }

    get projectExport(): object {
        return this._projectExport;
    }

    set projectExport(value: object) {
        this._projectExport = value;
    }

    get files(): object[] {
        return this._files;
    }

    set files(value: object[]) {
        this._files = value;
    }
}
