import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SafeHtmlPipe} from './safe-html.pipe';
import {SpaceNumberPipe} from './space-number.pipe';
import { LocalizedDatePipe } from './localized-date.pipe';
import { ConvertFileSizePipe } from './convert-file-size.pipe';

@NgModule({
    declarations: [
        SafeHtmlPipe,
        SpaceNumberPipe,
        LocalizedDatePipe,
        ConvertFileSizePipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        SafeHtmlPipe,
        SpaceNumberPipe,
        LocalizedDatePipe,
        ConvertFileSizePipe
    ]
})
export class EdwPipeModule {
}
