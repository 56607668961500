import {EdwContact} from '../contact-dir/EdwContact';
import {EdwPublicationContent} from './EdwPublicationContent';
import {Entity} from '../entity-dir/Entity';

export class EdwPublication extends Entity {

    private _senderContactId: string;
    private _senderUserId: string;
    private _plannedSendingDate: Date;
    private _sendingDate: Date;
    private _sendingDateAgo?: string;
    private _objectId: string;
    private _objectType: string;
    private _isPublic: boolean;
    private _priority: number;
    private _publicationContents: EdwPublicationContent[];
    private _rootComment: Comment;
    private _senderContact: EdwContact;
    private _fromRobot: boolean;
    private _showTnb: boolean;


    constructor() {
        super();
        this.publicationContents = [];
        this.isPublic = true;
        this.showTnb = false;
    }

    get senderContactId(): string {
        return this._senderContactId;
    }

    set senderContactId(value: string) {
        this._senderContactId = value;
    }
    get senderContact(): EdwContact {
        return this._senderContact;
    }

    set senderContact(value: EdwContact) {
        this._senderContact = value;
    }
    get senderUserId(): string {
        return this._senderUserId;
    }

    set senderUserId(value: string) {
        this._senderUserId = value;
    }

    get plannedSendingDate(): Date {
        return this._plannedSendingDate;
    }

    set plannedSendingDate(value: Date) {
        this._plannedSendingDate = value;
    }

    get sendingDate(): Date {
        return this._sendingDate;
    }

    set sendingDate(value: Date) {
        this._sendingDate = value;
    }

    get objectId(): string {
        return this._objectId;
    }

    set objectId(value: string) {
        this._objectId = value;
    }

    get objectType(): string {
        return this._objectType;
    }

    set objectType(value: string) {
        this._objectType = value;
    }

    get isPublic(): boolean {
        return this._isPublic;
    }

    set isPublic(value: boolean) {
        this._isPublic = value;
    }

    get priority(): number {
        return this._priority;
    }

    set priority(value: number) {
        this._priority = value;
    }
    get rootComment(): Comment {
        return this._rootComment;
    }

    set rootComment(value: Comment) {
        this._rootComment = value;
    }
    get fromRobot(): boolean {
        return this._fromRobot;
    }

    set fromRobot(value: boolean) {
        this._fromRobot = value;
    }
    get showTnb(): boolean {
        return this._showTnb;
    }

    set showTnb(value: boolean) {
        this._showTnb = value;
    }
    get publicationContents(): EdwPublicationContent[] {
        return this._publicationContents;
    }

    set publicationContents(value: EdwPublicationContent[]) {
        this._publicationContents = value;
    }
    get sendingDateAgo(): string {
        return this._sendingDateAgo;
    }

    set sendingDateAgo(value: string) {
        this._sendingDateAgo = value;
    }
}
