import {Entity} from '../entity-dir/Entity';
import {Structure} from './Structure';

export class Schedule extends Entity {
    private _name: string;
    private _structure: Structure;


    constructor() {
        super();
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get structure(): Structure {
        return this._structure;
    }

    set structure(value: Structure) {
        this._structure = value;
    }
}
