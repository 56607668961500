import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {EdwValidationCustom} from './ValidationCustom';
import {EdwValidationAttachmentFile} from './ValidationAttachmentFile';
import {EdwValidationAttachmentFileSerializer} from './ValidationAttachmentFileSerializer';
import {EdwCustomProjectSerializer} from './CustomProjectSerializer';

export class EdwValidationCustomSerializer implements EntitySerializer {

    private validationAttachmentFileSerializer = new EdwValidationAttachmentFileSerializer();
    private customProjectSerializer = new EdwCustomProjectSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['zoneId', 'zone_id'],
        ['attachedFiles', 'attached_files'],
        ['data', 'data'],
    ]);


    fromJson(json: any): EdwValidationCustom {
        const validationCustom = new EdwValidationCustom();
        for (const prop in json) {
            if (Object.prototype.hasOwnProperty.call(json, prop)) {
                validationCustom[prop] = json[prop];
            }
        }
        this.mapping.forEach((value, key, map) => {
            validationCustom[key] = json[value];
        });

        /**
         * Création des validation custom
         */
        const valAttachment = json['validation_attachment_files'];
        if (valAttachment && valAttachment instanceof Array) {
            const vSteps: EdwValidationAttachmentFile[] = [];
            valAttachment.forEach(vStep => {
                vSteps.push(this.validationAttachmentFileSerializer.fromJson(vStep));
            });
            validationCustom.validationAttachmentFiles = vSteps;
        }
        /**
         * Création customPpmProject
         */
        if (json['data'] !== null && typeof (json['data']) !== 'undefined') {
            validationCustom.customProject = this.customProjectSerializer.fromJson(json['data']);
        }
        /**
         * On parce json content pour recree objet et l'afficher sur la page publique validation (project id Crd)
         */
        if (json['content'] !== null && typeof (json['content']) !== 'undefined') {
            validationCustom.content = JSON.parse(json['content']);
        }
        return validationCustom;
    }

    toApi(validationCustom: EdwValidationCustom): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = validationCustom[key];
        });

        return obj;
    }
}
