import {Entity} from '../entity-dir/Entity';
import {EdwWorkflowStep} from './WorkflowStep';

export class EdwWorkflow extends Entity {
    private _name?: string;
    private _description?: string;
    private _type?: string;
    private _api?: string;
    private _apiType?: string;
    private _validationUrl?: string;
    private _status?: string;
    private _workflowId?: string;
    private _workflowName?: string;
    private _workflowDescription?: string;
    private _workflowSteps: EdwWorkflowStep[];


    constructor() {
        super();
        this._workflowSteps = [];
    }


    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get validationUrl(): string {
        return this._validationUrl;
    }

    set validationUrl(value: string) {
        this._validationUrl = value;
    }

    get workflowSteps(): EdwWorkflowStep[] {
        return this._workflowSteps;
    }

    set workflowSteps(value: EdwWorkflowStep[]) {
        this._workflowSteps = value;
    }

    get workflowId(): string {
        return this._workflowId;
    }

    set workflowId(value: string) {
        this._workflowId = value;
    }

    get workflowName(): string {
        return this._workflowName;
    }

    set workflowName(value: string) {
        this._workflowName = value;
    }

    get workflowDescription(): string {
        return this._workflowDescription;
    }

    set workflowDescription(value: string) {
        this._workflowDescription = value;
    }

    get status(): string {
        return this._status;
    }

    set status(value: string) {
        this._status = value;
    }
    get api(): string {
        return this._api;
    }

    set api(value: string) {
        this._api = value;
    }

    get apiType(): string {
        return this._apiType;
    }

    set apiType(value: string) {
        this._apiType = value;
    }

    toString() {
        return this.name;
    }
}
