import {EdwError} from './EdwError';

export class EdwHttpError {
    private _code: number;
    private _message: string;
    private _errors: EdwError[];


    constructor() {
        this.code = null;
        this.message = null;
        this.errors = [];
    }

    get code(): number {
        return this._code;
    }

    set code(value: number) {
        this._code = value;
    }

    get message(): string {
        return this._message;
    }

    set message(value: string) {
        this._message = value;
    }

    get errors(): EdwError[] {
        return this._errors;
    }

    set errors(value: EdwError[]) {
        this._errors = value;
    }

    static fromError(err: Object) {
        const edwHttpError = new EdwHttpError();
        for (const propEdw in edwHttpError) {
            if (edwHttpError.hasOwnProperty(propEdw)) {
                const formatProp = propEdw.substring(1);
                if (formatProp === 'errors' && err[formatProp]) {
                    for (const propErr in err[formatProp]) {
                        if (err[formatProp].hasOwnProperty(propErr)) {
                            edwHttpError.errors.push(EdwError.fromError(err[formatProp][propErr]));
                        }
                    }
                } else {
                    edwHttpError[propEdw] = err[formatProp];
                }
            }
        }
        return edwHttpError;
    }
}
