import {Entity} from '../entity-dir/Entity';


export class WorkspaceModule extends Entity {

    private _api: string;
    private _endDate: Date;
    private _startDate: Date;
    private _availability: boolean;
    private _skipConversion = true;


    get api(): string {
        return this._api;
    }

    set api(value: string) {
        this._api = value;
    }

    get endDate(): Date {
        return this._endDate;
    }

    set endDate(value: Date) {
        this._endDate = value;
    }

    get startDate(): Date {
        return this._startDate;
    }

    set startDate(value: Date) {
        this._startDate = value;
    }

    get availability(): boolean {
        return this._availability;
    }

    set availability(value: boolean) {
        this._availability = value;
    }

    get skipConversion(): boolean {
        return this._skipConversion;
    }

    set skipConversion(value: boolean) {
        this._skipConversion = value;
    }

    constructor() {
        super();
    }


}
