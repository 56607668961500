import {Entity} from '../entity-dir/Entity';
import {Workspace} from '../workspace-dir/Workspace';
import {User} from '../user-dir/User';
import {EdwNoteColor} from '../note-color-dir/EdwNoteColor';

export class EdwNote extends Entity {
    private _dateCreation: Date;
    private _dateUpdate: Date;
    private _deleted: Date;
    private _title?: string;
    private _content?: string;
    private _uiZoneId?: string;
    private _deadline: Date;
    private _objectId?: string;
    private _objectType?: string;
    private _appFront?: string;
    private _uiUrl?: string;
    private _workspace: Workspace;
    private _user: User;
    private _noteColor: EdwNoteColor;

    get workspace(): Workspace {
        return this._workspace;
    }

    set workspace(value: Workspace) {
        this._workspace = value;
    }

    get user(): User {
        return this._user;
    }

    set user(value: User) {
        this._user = value;
    }

    get noteColor(): EdwNoteColor {
        return this._noteColor;
    }

    set noteColor(value: EdwNoteColor) {
        this._noteColor = value;
    }

    get appFront(): string {
        return this._appFront;
    }

    set appFront(value: string) {
        this._appFront = value;
    }
    get objectType(): string {
        return this._objectType;
    }

    set objectType(value: string) {
        this._objectType = value;
    }
    get objectId(): string {
        return this._objectId;
    }

    set objectId(value: string) {
        this._objectId = value;
    }
    get deadline(): Date {
        return this._deadline;
    }

    set deadline(value: Date) {
        this._deadline = value;
    }
    get uiZoneId(): string {
        return this._uiZoneId;
    }

    set uiZoneId(value: string) {
        this._uiZoneId = value;
    }
    get content(): string {
        return this._content;
    }

    set content(value: string) {
        this._content = value;
    }
    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }
    get deleted(): Date {
        return this._deleted;
    }

    set deleted(value: Date) {
        this._deleted = value;
    }
    get dateUpdate(): Date {
        return this._dateUpdate;
    }

    set dateUpdate(value: Date) {
        this._dateUpdate = value;
    }
    get dateCreation(): Date {
        return this._dateCreation;
    }

    set dateCreation(value: Date) {
        this._dateCreation = value;
    }

    get uiUrl(): string {
        return this._uiUrl;
    }

    set uiUrl(value: string) {
        this._uiUrl = value;
    }
}
