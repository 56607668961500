import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {MenuItem} from 'primeng/api';
import {EdwToolsWorkspaceService} from '../tools/edw-tools-workspace.service';

@Injectable({
    providedIn: 'root'
})
export class EdwBreadcrumbService {

    private breadcrumbItems: Subject<MenuItem[]> = new Subject<MenuItem[]>();
    readonly breadcrumbItems$: Observable<MenuItem[]> = this.breadcrumbItems.asObservable();
    public visibleBreadCrumb: Subject<boolean> = new Subject<boolean>();

    constructor(public edwWorkspaceService: EdwToolsWorkspaceService) {
    }


    setBreadcrumbItems(breadcrumbItems: MenuItem[]) {
        this.breadcrumbItems.next(breadcrumbItems);
    }

    setBreadCrumbVisible(value) {
        this.visibleBreadCrumb.next(value);
    }

    getIsBreadCrumbPinned() {
        return JSON.parse(localStorage.getItem(this.edwWorkspaceService.getSystemName() + 'breadcrumbPinned'));
    }

    setBreadcrumbPinned(val: boolean) {
        localStorage.setItem(this.edwWorkspaceService.getSystemName() + 'breadcrumbPinned', JSON.stringify(val));
    }

}
