import {Entity} from '../entity-dir/Entity';
import {EdwCompany} from '../company-dir/EdwCompany';

export class EdwContactCompany extends Entity {
    private _comment?: string;
    private _dateOfEntry?: Date;
    private _department?: string;
    private _isMain?: boolean;
    private _jobTitle?: string;
    private _company: EdwCompany;


    constructor() {
        super();
    }


    get comment(): string {
        return this._comment;
    }

    set comment(value: string) {
        this._comment = value;
    }

    get dateOfEntry(): Date {
        return this._dateOfEntry;
    }

    set dateOfEntry(value: Date) {
        this._dateOfEntry = value;
    }

    get department(): string {
        return this._department;
    }

    set department(value: string) {
        this._department = value;
    }

    get isMain(): boolean {
        return this._isMain;
    }

    set isMain(value: boolean) {
        this._isMain = value;
    }

    get jobTitle(): string {
        return this._jobTitle;
    }

    set jobTitle(value: string) {
        this._jobTitle = value;
    }

    get company(): EdwCompany {
        return this._company;
    }

    set company(value: EdwCompany) {
        this._company = value;
    }
}
