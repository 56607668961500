import {Entity} from '../entity-dir/Entity';

export class ActivityScheduleInfo extends Entity {
    private _activityId: string;
    private _diffStartDate: number;


    constructor() {
        super();
    }

    get activityId(): string {
        return this._activityId;
    }

    set activityId(value: string) {
        this._activityId = value;
    }

    get diffStartDate(): number {
        return this._diffStartDate;
    }

    set diffStartDate(value: number) {
        this._diffStartDate = value;
    }
}
