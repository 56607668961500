import {EdwNoteColor} from './EdwNoteColor';
import {EntitySerializer} from '../entity-dir/EntitySerializer';

export class EdwNoteColorSerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['color', 'color'],
        ['name', 'name'],
        ['userId', 'user_id'],
        ['dateCreation', 'date_creation']
    ]);

    fromJson(json: any): EdwNoteColor {
        const noteColor = new EdwNoteColor();
        this.mapping.forEach((value, key, map) => {
            noteColor[key] = json[value];
        });
        return noteColor;
    }

    toApi(noteColor: EdwNoteColor): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = noteColor[key];
        });
        return obj;
    }

}
