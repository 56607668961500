import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {Structure} from './Structure';
import {ScheduleYear} from './ScheduleYear';
import {ScheduleYearSerializer} from './ScheduleYearSerializer';
import {ScheduleMonth} from './ScheduleMonth';
import {ScheduleMonthSerializer} from './ScheduleMonthSerializer';
import {ScheduleQuarter} from './ScheduleQuarter';
import {ScheduleQuarterSerializer} from './ScheduleQuarterSerializer';
import {Activity} from '../activity-dir/Activity';
import {ActivityScheduleInfo} from '../activity-dir/ActivityScheduleInfo';
import {ActivityScheduleInfoSerializer} from '../activity-dir/ActivityScheduleInfoSerializer';
import {ActivitySerializer} from '../activity-dir/ActivitySerializer';
import {MilestoneSerializer} from '../milestone-dir/MilestoneSerializer';
import {MilestoneScheduleInfoSerializer} from '../milestone-dir/MilestoneScheduleInfoSerializer';
import {MilestoneScheduleInfo} from '../milestone-dir/MilestoneScheduleInfo';
import {Milestone} from '../milestone-dir/Milestone';

export class StructureSerializer implements EntitySerializer {

    private scheduleYearSerializer = new ScheduleYearSerializer();
    private scheduleMonthSerializer = new ScheduleMonthSerializer();
    private scheduleQuarterSerializer = new ScheduleQuarterSerializer();
    private activitySerializer = new ActivitySerializer();
    private activityScheduleInfoSerializer = new ActivityScheduleInfoSerializer();
    private milestoneSerializer = new MilestoneSerializer();
    private milestoneScheduleInfoSerializer = new MilestoneScheduleInfoSerializer();

    public mapping = new Map([
        ['startDate', 'start_date'],
        ['endDate', 'end_date'],
        ['duration', 'duration'],
        ['totalNbDays', 'total_nb_days']
    ]);

    fromJson(json: any): Structure {
        const structure = new Structure();
        this.mapping.forEach((value, key, map) => {
            structure[key] = json[value];
        });

        /**
         * Création des years
         */
        const years = json['years'];
        if (years && years instanceof Array) {
            const yrs: ScheduleYear[] = [];
            years.forEach(year => {
                yrs.push(this.scheduleYearSerializer.fromJson(year));
            });
            structure.years = yrs;
        }

        /**
         * Création des months
         */
        const months = json['months'];
        if (months && months instanceof Array) {
            const mths: ScheduleMonth[] = [];
            months.forEach(month => {
                mths.push(this.scheduleMonthSerializer.fromJson(month));
            });
            structure.months = mths;
        }

        /**
         * Création des years
         */
        const quarters = json['quarters'];
        if (quarters && quarters instanceof Array) {
            const quarts: ScheduleQuarter[] = [];
            quarters.forEach(quarter => {
                quarts.push(this.scheduleQuarterSerializer.fromJson(quarter));
            });
            structure.quarters = quarts;
        }

        /**
         * Création des activities
         */
        const activities = json['activities'];
        if (activities && activities instanceof Array) {
            const acts: Activity[] = [];
            activities.forEach(activity => {
                acts.push(this.activitySerializer.fromJson(activity));
            });
            structure.activities = acts;
        }

        /**
         * Créaction des activitiesScheduleInfos
         */
        const activitiesScheduleInfos = json['activity_schedule_infos'];
        if (activitiesScheduleInfos && activitiesScheduleInfos instanceof Array) {
            const actScheduleInfos: ActivityScheduleInfo[] = [];
            activitiesScheduleInfos.forEach(actScheduleInfo => {
                actScheduleInfos.push(this.activityScheduleInfoSerializer.fromJson(actScheduleInfo));
            });
            structure.activityScheduleInfos = actScheduleInfos;
        }

        /**
         * Création des milestones
         */
        const milestones = json['milestones'];
        if (milestones && milestones instanceof Array) {
            const miles: Milestone[] = [];
            milestones.forEach(milestone => {
                miles.push(this.milestoneSerializer.fromJson(milestone));
            });
            structure.milestones = miles;
        }

        /**
         * Créaction des activitiesScheduleInfos
         */
        const milestonesScheduleInfos = json['milestone_schedule_infos'];
        if (milestonesScheduleInfos && milestonesScheduleInfos instanceof Array) {
            const milesScheduleInfos: MilestoneScheduleInfo[] = [];
            milestonesScheduleInfos.forEach(milestoneScheduleInfo => {
                milesScheduleInfos.push(this.milestoneScheduleInfoSerializer.fromJson(milestoneScheduleInfo));
            });
            structure.milestoneScheduleInfos = milesScheduleInfos;
        }

        return structure;
    }

    toApi(structure: Structure): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = structure[key];
        });
        return obj;
    }

}
