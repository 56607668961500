import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {ScheduleMonth} from './ScheduleMonth';

export class ScheduleMonthSerializer implements EntitySerializer {

    public mapping = new Map([
        ['year', 'year'],
        ['quarter', 'quarter'],
        ['month', 'month'],
        ['label', 'label'],
        ['labelShort', 'label_short'],
        ['firstDate', 'first_date'],
        ['lastDate', 'last_date'],
        ['nbDays', 'nb_days'],
    ]);

    fromJson(json: any): ScheduleMonth {
        const scheduleMonth = new ScheduleMonth();
        this.mapping.forEach((value, key, map) => {
            scheduleMonth[key] = json[value];
        });
        return scheduleMonth;
    }

    toApi(scheduleMonth: ScheduleMonth): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = scheduleMonth[key];
        });
        return obj;
    }

}
