import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ResponsiveGuard implements CanActivate {

    constructor(private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (window.innerWidth > 600 || sessionStorage.getItem('acceptResponsive') === '1') {
            return true;
        }
        // Si device width <= 600, on le redirige vers une page d'alerte

        this.router.navigate(['error'], {queryParams: {e: 'responsive', callbackUrl: state.url}});
        return false;
    }
}
