import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Entity} from '@edward-software/edw-fwk-angular-lib/models';
import {ThreadNavbarItem} from '@edward-software/edw-fwk-angular-lib/models';

@Injectable({
    providedIn: 'root'
})
export class ThreadNavbarService {

    isVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private dataSubject: BehaviorSubject<Entity> = new BehaviorSubject(null);
    data = this.dataSubject.asObservable();
    /**
     * On stocke les IDs des items dans le currentItemTab
     * Cela permet d'afficher si un objet est déjà stick
     */
    currentItems: string[] = [];

    private threadNavbarItemSubject: BehaviorSubject<ThreadNavbarItem> = new BehaviorSubject(null);
    threadNavbarItem = this.threadNavbarItemSubject.asObservable();

    private componentIdSubject: Subject<string> = new Subject<string>();
    componentId = this.componentIdSubject.asObservable();

    constructor() {
    }

    updateThreadNavbarItemSubject(threadNavbarItem: ThreadNavbarItem) {
        this.threadNavbarItemSubject.next(threadNavbarItem);
    }

    updateData(data: Entity) {
        this.currentItems.push(data.id);
        this.dataSubject.next(data);
    }

    removeCurrent(index: number, id: string) {
        this.currentItems.splice(this.currentItems.findIndex(val => val === id), 1);
        this.componentIdSubject.next(id);
    }

    isCurrentItem(id: string) {
        return this.currentItems.findIndex(it => it === id) > -1;
    }

}
