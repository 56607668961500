import {EdwNote} from './EdwNote';
import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {WorkspaceSerializer} from '../workspace-dir/WorkspaceSerializer';
import {UserSerializer} from '../user-dir/UserSerializer';
import {EdwNoteColorSerializer} from '../note-color-dir/EdwNoteColorSerializer';
import {Workspace} from '../workspace-dir/Workspace';
import {User} from '../user-dir/User';
import {EdwNoteColor} from '../note-color-dir/EdwNoteColor';

export class EdwNoteSerializer implements EntitySerializer {

    private workspaceSerializer: WorkspaceSerializer = new WorkspaceSerializer();
    private userSerializer: UserSerializer = new UserSerializer();
    private noteColorSerializer: EdwNoteColorSerializer = new EdwNoteColorSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['title', 'title'],
        ['uiZoneId', 'ui_zone_id'],
        ['content', 'content'],
        ['objectId', 'object_id'],
        ['objectType', 'object_type'],
        ['appFront', 'app_front'],
        ['deadline', 'deadline'],
        ['uiUrl', 'ui_url'],
        ['dateCreation', 'date_creation']
    ]);

    fromJson(json: any): EdwNote {
        const note = new EdwNote();
        this.mapping.forEach((value, key, map) => {
            note[key] = json[value];
        });

        /**
         * Création de user
         */
        const user = json['user'];
        if (user) {
            note.user = this.userSerializer.fromJson(user);
        }

        /**
         * Création de workspace
         */
        const workspace = json['workspace'];
        if (workspace) {
            note.workspace = this.workspaceSerializer.fromJson(workspace);
        }

        /**
         * Création de noteColor
         */
        const noteColor = json['note_color'];
        if (noteColor) {
            note.noteColor = this.noteColorSerializer.fromJson(noteColor);
        }

        return note;
    }

    toApi(note: EdwNote): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = note[key];
        });
        if (note.workspace !== null && note.workspace instanceof Workspace) {
            obj['workspace_id'] = note.workspace.id;
        }
        if (note.user !== null && note.user instanceof User) {
            obj['user_id'] = note.user.id;
        }
        if (note.noteColor !== null && note.noteColor instanceof EdwNoteColor) {
            obj['note_color_id'] = note.noteColor.id;
        }
        return obj;
    }

}
