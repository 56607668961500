import {Injectable} from '@angular/core';
import {EdwToolsJwtService} from './edw-tools-jwt.service';
import {Router} from '@angular/router';
import {JwtPayload, JwtWorkspace} from '@edward-software/edw-fwk-angular-lib/models';
import {EdwWorkspaceService} from '../workspace/edw-workspace.service';
import {EdwToolsNavigatorService} from './edw-tools-navigator.service';

@Injectable({
    providedIn: 'root'
})
export class EdwToolsWorkspaceService {

    temporaryId: string = null;

    constructor(private workspaceService: EdwWorkspaceService,
                private navigatorService: EdwToolsNavigatorService,
                private jwtService: EdwToolsJwtService,
                private router: Router) {
    }


    public getWorkspace() {
        if (sessionStorage.getItem('workspace')) {
            return JSON.parse(sessionStorage.getItem('workspace'));
        } else {
            return JSON.parse(localStorage.getItem('workspace'));
        }
    }

    public getSystemName() {
        if (sessionStorage.getItem('workspace')) {
            return JSON.parse(sessionStorage.getItem('workspace')).systemName;
        } else if (localStorage.getItem('workspace')) {
            return JSON.parse(localStorage.getItem('workspace')).systemName;
        } else {
            return null;
        }
    }

    public getId() {
        if (sessionStorage.getItem('workspace')) {
            return JSON.parse(sessionStorage.getItem('workspace')).id;
        } else if (localStorage.getItem('workspace')) {
            return JSON.parse(localStorage.getItem('workspace')).id;
        } else {
            return null;
        }
    }


    /*************************************************
     * TempraryId permet de modifier le workspaceId temporairement
     * C'est utile pour modifier le workspaceId passé dans le header de la requête : cf workspace.interceptor.ts
     ************************************************/
    public getTemporaryId() {
        return this.temporaryId;
    }

    public setTemporaryId(workspaceId: string) {
        this.temporaryId = workspaceId;
    }

    public isWorkspaceDefined() {
        return (JSON.parse(localStorage.getItem('workspace')) !== null);
    }

    /**
     * Save current workspace-dir (in the URL) in the localStorage to serve it everywhere in the app
     * Si pas de token => On renvoit vers la page de login
     * Si le workspace-dir demandé n'est pas dans le token, on renvoit vers la homepage pour choisir son workspace-dir
     * Sinon, on stocke les infos du workspace-dir demandé dans le localStorage
     */
    public setWorkspace(workspaceName: string) {
        const tokenPayload: JwtPayload = this.jwtService.getDecodedToken();
        if (tokenPayload && typeof tokenPayload !== 'undefined') {
            const workspace = this.isMember(workspaceName, tokenPayload.workspaces);
            if (typeof workspace !== 'undefined') {
                /**
                 * On stocke d'abord le workspace partiel au cas où la requête est en erreur, on ne veut pas bloquer l'appli
                 */
                this.storeWorkspace(workspace);
                /**
                 * On fait une requête pour récupérer le workspace dans son intégralité
                 */
                this.workspaceService.read(workspace.id)
                    .subscribe(
                        resp => {
                            this.storeWorkspace(resp);
                            this.workspaceService.setCurrentWorkSpace(resp);
                        }
                    );
            } else {
                this.navigatorService.navigateToHome();

            }
        } else {
            this.router.navigate(['login']);
        }
    }

    /**
     * Vérifie si les worspaces dans le JWT Token contient le workspace passé en param
     */
    public isMember(workspace: string, wks: JwtWorkspace[]) {
        return wks.find(wk => wk.systemName === workspace);
    }

    private storeWorkspace(workspace: any) {
        localStorage.setItem('workspace', JSON.stringify(workspace).replace(/[_]/g, ''));
        sessionStorage.setItem('workspace', JSON.stringify(workspace).replace(/[_]/g, ''));
    }

    public removeWorkspace() {
        localStorage.removeItem('workspace');
        sessionStorage.removeItem('workspace');
    }


}
