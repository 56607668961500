import {EnvConfig} from './EnvConfig';
import {EntitySerializer} from '../entity-dir/EntitySerializer';

export class EnvConfigSerializer implements EntitySerializer {


    public mapping = new Map([
        ['id', 'id'],
        ['platformName', 'platform_name'],
        ['platformAbbreviation', 'platform_abbreviation'],
        ['platformDescription', 'platform_description'],
        ['platformLogo', 'platform_logo'],
        ['platformFavicon', 'platform_favicon'],
        ['edsBranding', 'eds_branding'],
        ['intercomId', 'intercom_id'],
        ['loginShowLogo', 'login_show_logo'],
        ['loginShowCgu', 'login_show_cgu'],
        ['loginShowSignup', 'login_show_signup'],
        ['loginCopyRightContent', 'login_copy_right_content'],
        ['loginFooterTemplateContent', 'login_footer_template_content'],
        ['enableSso', 'enable_sso'],
        ['enableSsoGoogle', 'enable_sso_google'],
        ['enableSsoLinkedin', 'enable_sso_linkedin'],
        ['enableSsoMsoffice', 'enable_sso_msoffice'],
        ['enableSsoApple', 'enable_sso_apple'],
        ['menuNavbarShowMyProfil', 'menu_navbar_show_my_profil'],
        ['menuNavbarShowHome', 'menu_navbar_show_home'],
        ['menuNavbarShowNote', 'menu_navbar_show_note'],
        ['menuNavbarShowSupport', 'menu_navbar_show_support'],
        ['menuNavbarShowWorkspace', 'menu_navbar_show_workspace'],
        ['moduleShowDisabled', 'module_show_disabled'],
        ['moduleShowAdb', 'module_show_adb'],
        ['moduleShowPmov0', 'module_show_pmov0'],
        ['moduleShowPpm', 'module_show_ppm'],
        ['moduleShowSocial', 'module_show_social'],
        ['moduleShowWkf', 'module_show_wkf'],
        ['moduleShowNgTools', 'module_show_ng_tools'],
        ['moduleShowTsm', 'module_show_tsm'],
        ['moduleShowFile', 'module_show_file'],
        ['moduleShowMdm', 'module_show_mdm'],
        ['moduleOrder', 'module_order'],
        ['monoWorkspaceEnabled', 'mono_workspace_enabled'],
        ['apiAccessUrl', 'api_access_url'],
        ['apiAuthUrl', 'api_auth_url'],
        ['apiProjectUrl', 'api_project_url'],
        ['apiScheduleUrl', 'api_schedule_url'],
        ['apiSocialUrl', 'api_social_url'],
        ['apiAdbUrl', 'api_adb_url'],
        ['apiTranslationUrl', 'api_translation_url'],
        ['apiWorkflowUrl', 'api_workflow_url'],
        ['apiRobotUrl', 'api_robot_url'],
        ['apiTsmUrl', 'api_tsm_url'],
        ['apiMeetingUrl', 'api_meeting_url'],
        ['apiGovUrl', 'api_gov_url'],
        ['apiIdaUrl', 'api_ida_url'],
        ['apiMessageUrl', 'api_message_url'],
        ['apiFileUrl', 'api_file_url'],
        ['apiKbUrl', 'api_kb_url'],
        ['apiPpmUrl', 'api_ppm_url'],
        ['frontHomeUrl', 'front_home_url'],
        ['frontAdbUrl', 'front_adb_url'],
        ['frontWorkflowUrl', 'front_workflow_url'],
        ['frontFileUrl', 'front_file_url'],
        ['frontTsmUrl', 'front_tsm_url'],
        ['frontPpmUrl', 'front_ppm_url'],
        ['frontPmoUrl', 'front_pmo_url'],
        ['frontSocialUrl', 'front_social_url'],
        ['frontNgToolsUrl', 'front_ng_tools_url'],
        ['frontMdmUrl', 'front_mdm_url'],
        ['appName', 'app_name'],
        ['appAbbreviation', 'app_abbreviation'],
        ['appDescription', 'app_description'],
        ['appLogo', 'app_logo'],
        ['appFavicon', 'app_favicon'],
        ['enableMonoWorkspace', 'enable_mono_workspace'],
        ['cdnUrl', 'cdn_url'],
        ['apiToolsUrl', 'api_tools_url'],
        ['defaultRoute', 'default_route'],
        ['noWorkspaceUrls', 'no_workspace_urls'],
        ['apiMdmUrl', 'api_mdm_url'],
        ['showLaunchpadHeader', 'show_launchpad_header'],
        ['appTitle', 'app_title']
    ]);

    fromJson(json: any): EnvConfig {
        const envConfig = new EnvConfig();
        this.mapping.forEach((value, key, map) => {
            envConfig[key] = json[value];
        });

        return envConfig;
    }

    toApi(envConfig: EnvConfig): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = envConfig[key];
        });
        return obj;
    }

}
