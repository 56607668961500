import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {AuthService} from '../services/auth/auth.service';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {EdwHttpError} from '@edward-software/edw-fwk-angular-lib/models';

/**
 * A chaque fois que l'on fait une requête vers le back,
 * Si on a un access_token dans le localStorage, on ajoute le token du access_token aux Headers de la requête
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {


    /**
     * booleen permettant de savoir si une requête de refreshToken est en cours
     */
    private isRefreshing = false;

    /**
     * Behavior subject qui permet de savoir quand la requête de refreshToken est revenue
     */
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(public authService: AuthService) {

    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        /**
         * ajout du token s'il existe dans le local storage
         */
        const accessToken = JSON.parse(localStorage.getItem('access_token'));

        if (accessToken && accessToken.token) {
            request = this.addToken(request, accessToken);
        }

        /**
         * Ensuite on souscrit à la réponse de la requête, et si on a une erreur 401 Expired JWT Token
         * on handle l'erreur
         */
        return next.handle(request).pipe(catchError(error => {
            /**
             * Si on a une erreur 401, de type 'Expired JWT Token' alors on veut la gérer spécifiqiement
             */
            if (error instanceof EdwHttpError && error.code === 401 && error.message === 'Expired JWT Token') {
                return this.handle401Error(request, next, accessToken);
            } else {
                return throwError(error);
            }
        }));

    }

    /**
     * Gestion de l'erreur 401 Expired JWT Token
     * On exécute une requete de refreshToken et on renvoie la requête initiale une fois le novueau token récupéré
     */
    private handle401Error(request: HttpRequest<any>, next: HttpHandler, accessToken: any) {
        /**
         * On vérifie d'abord qu'il n'y a pas une requête de Refresh en cours
         */
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authService.getTokenWithRefreshToken(accessToken.refresh_token).pipe(
                switchMap((newToken: any) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(newToken);
                    return next.handle(this.addToken(request, newToken));
                }));

        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(jwt => {
                    return next.handle(this.addToken(request, jwt));
                }));
        }
    }

    /**
     * Ajoute le token dans le header de la requête
     */
    private addToken(request: HttpRequest<any>, accessToken: any) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${accessToken.token}`
            }
        });
    }
}
