import {Entity} from '../entity-dir/Entity';
import {Picture} from '../picture-dir/Picture';

export class UserData extends Entity {
    private _username?: string;
    private _firstName?: string;
    private _lastName?: string;
    private _avatar: Picture;

    constructor() {
        super();
    }

    get username(): string {
        return this._username;
    }

    set username(value: string) {
        this._username = value;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value;
    }

    get lastName(): string {
        return this._lastName;
    }

    set lastName(value: string) {
        this._lastName = value;
    }

    get avatar(): Picture {
        return this._avatar;
    }

    set avatar(value: Picture) {
        this._avatar = value;
    }
}
