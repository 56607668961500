import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {EdwPhoneNumber} from './EdwPhoneNumber';

export class EdwPhoneNumberSerializer implements EntitySerializer {

    public mapping = new Map([
        ['e164Number', 'e164_number'],
        ['internationalNumber', 'international_number'],
        ['nationalNumber', 'national_number'],
    ]);

    fromJson(json: any): EdwPhoneNumber {
        const phoneNumber = new EdwPhoneNumber();
        this.mapping.forEach((value, key, map) => {
            phoneNumber[key] = json[value];
        });
        return phoneNumber;
    }

    toApi(phoneNumber: EdwPhoneNumber): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = phoneNumber[key];
        });
        return obj;
    }

}
