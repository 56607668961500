import {Entity} from '../entity-dir/Entity';

export class Picture extends Entity {

    private _url: string;

    constructor() {
        super();
    }

    get url(): string {
        return this._url;
    }

    set url(value: string) {
        this._url = value;
    }
}
