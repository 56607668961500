import {Entity} from '../entity-dir/Entity';
import {EdwValidation} from './Validation';
import {EdwValidationStep} from './ValidationStep';
import {EdwWorkflow} from './Workflow';

export class EdwPublicValidation extends Entity {

    private _validation: EdwValidation;
    private _validationSteps: EdwValidationStep[];
    private _workflow: EdwWorkflow;
    private _currentValidationStep: EdwValidationStep;

    constructor() {
        super();
        this.validationSteps = [];
    }

    get validation(): EdwValidation {
        return this._validation;
    }

    set validation(value: EdwValidation) {
        this._validation = value;
    }

    get validationSteps(): EdwValidationStep[] {
        return this._validationSteps;
    }

    set validationSteps(value: EdwValidationStep[]) {
        this._validationSteps = value;
    }

    get currentValidationStep(): EdwValidationStep {
        return this._currentValidationStep;
    }

    set currentValidationStep(value: EdwValidationStep) {
        this._currentValidationStep = value;
    }

    get workflow(): EdwWorkflow {
        return this._workflow;
    }

    set workflow(value: EdwWorkflow) {
        this._workflow = value;
    }
}
