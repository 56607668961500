import {Entity} from '../entity-dir/Entity';
import {Picture} from '../picture-dir/Picture';
import {EdwContactCompany} from '../contact-company-dir/EdwContactCompany';
import {EdwPhoneNumber} from '../phone-number-dir/EdwPhoneNumber';

export class EdwContact extends Entity {

    private _title?: string;
    private _firstName?: string;
    private _lastName?: string;
    private _firstName2?: string;
    private _lastName2?: string;
    private _secondName?: string;
    private _nickname?: string;
    private _cellPhoneNumber?: string;
    private _jobTitle?: string;
    private _email?: string;
    private _comment: string;
    private _company: { id: string, name: string };
    private _associatedUser: string;
    private _contactPicture: Picture;
    private _pictureUrl?: string;
    private _contactCompanies: EdwContactCompany[];
    private _phoneNumberCountryCode?: string;
    private _phoneNumberCountryCode2?: string;
    private _phoneNumber?: EdwPhoneNumber;
    private _phoneNumber2?: EdwPhoneNumber;
    private _contactAvatarClass?: string;


    constructor() {
        super();
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value;
    }

    get contactPicture(): Picture {
        return this._contactPicture;
    }

    set contactPicture(value: Picture) {
        this._contactPicture = value;
    }

    get lastName(): string {
        return this._lastName;
    }

    set lastName(value: string) {
        this._lastName = value;
    }

    get nickname(): string {
        return this._nickname;
    }

    set nickname(value: string) {
        this._nickname = value;
    }
    get cellPhoneNumber(): string {
        return this._cellPhoneNumber;
    }

    set cellPhoneNumber(value: string) {
        this._cellPhoneNumber = value;
    }
    get phoneNumberCountryCode(): string {
        return this._phoneNumberCountryCode;
    }

    set phoneNumberCountryCode(value: string) {
        this._phoneNumberCountryCode = value;
    }

    get phoneNumberCountryCode2(): string {
        return this._phoneNumberCountryCode2;
    }

    set phoneNumberCountryCode2(value: string) {
        this._phoneNumberCountryCode2 = value;
    }

    get phoneNumber(): EdwPhoneNumber {
        return this._phoneNumber;
    }

    set phoneNumber(value: EdwPhoneNumber) {
        this._phoneNumber = value;
    }

    get phoneNumber2(): EdwPhoneNumber {
        return this._phoneNumber2;
    }

    set phoneNumber2(value: EdwPhoneNumber) {
        this._phoneNumber2 = value;
    }

    get jobTitle(): string {
        return this._jobTitle;
    }

    set jobTitle(value: string) {
        this._jobTitle = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get comment(): string {
        return this._comment;
    }

    set comment(value: string) {
        this._comment = value;
    }

    get company(): { id: string; name: string } {
        return this._company;
    }

    set company(value: { id: string; name: string }) {
        this._company = value;
    }

    get associatedUser(): string {
        return this._associatedUser;
    }

    get fullName(): string {
        return this.firstName + ' ' + this._lastName;
    }

    set associatedUser(value: string) {
        this._associatedUser = value;
    }

    get trigram(): string {
        if (!this.nickname || (this.nickname && this.nickname.length < 3)) {
            this.generateTrigram();
        }
        return this.nickname;
    }


    get secondName(): string {
        return this._secondName;
    }

    set secondName(value: string) {
        this._secondName = value;
    }


    get contactCompanies(): EdwContactCompany[] {
        return this._contactCompanies;
    }

    set contactCompanies(value: EdwContactCompany[]) {
        this._contactCompanies = value;
    }

    get pictureUrl(): string {
        return this._pictureUrl;
    }

    set pictureUrl(value: string) {
        this._pictureUrl = value;
    }
    get firstName2(): string {
        return this._firstName2;
    }

    set firstName2(value: string) {
        this._firstName2 = value;
    }

    get lastName2(): string {
        return this._lastName2;
    }

    set lastName2(value: string) {
        this._lastName2 = value;
    }
    get contactAvatarClass(): string {
        return this._contactAvatarClass;
    }

    set contactAvatarClass(value: string) {
        this._contactAvatarClass = value;
    }
    toString() {
        return (this.firstName || '') + ' ' + (this.lastName || '');
    }

    /***********************************
     * GENERATEUR de trigram
     ***********************************/
    private generateTrigram() {

        let firstName: any = this.firstName;
        let lastName: any = this.lastName;
        let trigram = '';
        let doubleFirstName = false;
        let doubleLastName = false;

        /**
         * Algorithme Standard qui permert de générer un trigram basic :
         * Prmière lettre du prénom, première lettre du nom et dernière lettre du nom
         */
        if (firstName.match('\-')) {
            doubleFirstName = true;
        }

        if (lastName.match('\-')) {
            doubleLastName = true;
        }

        if (!doubleFirstName && !doubleLastName) {
            trigram = firstName.substr(0, 1) + lastName.substr(0, 1) + lastName.substr(-1, 1);
        }

        /**
         * Prénom composé et nom standard :
         * Première lettre du prénom, première lettre du deuxième prénom, première lettre du nom
         */
        if (doubleFirstName && !doubleLastName) {
            if (firstName.match('-')) {
                firstName = firstName.split('-');
            } else if (firstName.match('\ ')) {
                firstName = firstName.split('\ ');
            }

            if (firstName.length === 2) {
                trigram = firstName[0].substr(0, 1) + firstName[1].substr(0, 1) + lastName.substr(0, 1);
            }
        }

        /**
         * Prénom simple et nom composé :
         * Première lettre du prénom, première lettre du premier nom, première lettre du deuxième nom
         */
        if ((!doubleFirstName && doubleLastName) || (doubleFirstName && doubleLastName)) {
            if (lastName.match('-')) {
                lastName = lastName.split('-');
            } else if (lastName.match('\ ')) {
                lastName = lastName.split('\ ');
            } else if (lastName.match('\'')) {
                lastName = lastName.split('\'');
            }

            if (lastName.length === 2) {
                trigram = firstName.substr(0, 1) + lastName[0].substr(0, 1) + lastName[1].substr(0, 1);
            }
        }

        // this.nickname = trigram.toUpperCase();
    }
}
