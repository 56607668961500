import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EdwPublicValidation, EdwPublicValidationSerializer, ENVIRONMENT_PARAMETER} from '@edward-software/edw-fwk-angular-lib/models';

@Injectable({
    providedIn: 'root'
})
/**********************************
 * Service utilisé pour la gestion de la validation d'un workflow
 * Seul getValidationSteps() n'est pas une URL "public" mais elle est placée ici car elle fait partie de la même logique métier et utilise les mêmes données techniques
 *********************************/
export class EdwWorkflowPublicService {

    private publicValidationSerializer = new EdwPublicValidationSerializer();

    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private http: HttpClient) {
    }

    /**
     * Récupère les informations de la validationStep courante à valider
     */
    getValidationStep(workspaceId: string, validationStepId: string, token: string): Observable<EdwPublicValidation> {
        return this.http.get(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiWorkflowUrl}/public/validation_steps/${workspaceId}/${validationStepId}/${token}?get_contact_steps=1`)
            .pipe(map(
                resp => this.publicValidationSerializer.fromJson(resp)
            ));
    }

    /**
     * Enregistrement de la réponse du validateur à une validationStep
     */
    postValidationResult(workspaceId: string, validationStepId: string, token: string, result: { answer, comment, subscribe }) {
        return this.http.post(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiWorkflowUrl}/public/validation_steps/${workspaceId}/${validationStepId}/${token}/results`, {
                answer: result.answer,
                comment: result.comment,
                subscribe: result.subscribe
            },
            this.options)
            .pipe(map(
                resp => resp
            ));
    }

    /**
     * Possibilité de s'abonner ou se désabonner d'une validation
     */
    subscribeValidation(workspaceId: string, validationStepId: string, token: string, val: boolean) {
        return this.http.post(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiWorkflowUrl}/public/workflows/validation_steps/${workspaceId}/${validationStepId}/${token}/subscribe`, {
                subscribe: val
            },
            this.options)
            .pipe(map(
                resp => resp
            ));
    }

}
