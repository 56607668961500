import {Injectable} from '@angular/core';
import {EntityService} from '../entity.service';
import {HttpClient} from '@angular/common/http';
import {EdwSnapshot, EdwSnapshotSerializer, ListResponse, QueryOption} from '@edward-software/edw-fwk-angular-lib/models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EdwSnapshotService extends EntityService<EdwSnapshot> {

    constructor(protected http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiPpmUrl,
            'project_exports',
            new EdwSnapshotSerializer()
        );
    }

    listFromProject(projectId: string, queryOption: QueryOption): Observable<ListResponse<EdwSnapshot>> {
        return this.http.get(`${this.url}/projects/${projectId}/${this.resource}?${queryOption.toQueryString()}`)
            .pipe(map((data: any) => {
                return new ListResponse(
                    this.convertData(data.data),
                    this.paginationMetaSerializer.fromJson(data.meta)
                );
            }));
    }

    readPDF(snapshotId: string) {
        return this.http.get(`${this.url}/${this.resource}/${snapshotId}/pdf`)
            .pipe(map(res => res));
    }

    addProjectExport(projectId: string) {
        return this.http.post(`${this.url}/projects/${projectId}/${this.resource}?get_classifications=1`, this.options)
            .pipe(map(snapshot => {
                return this.serializer.fromJson(snapshot) as EdwSnapshot;
            }));
    }
}
