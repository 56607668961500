import {Injectable} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {QuestionBase} from '@edward-software/edw-fwk-angular-lib/models';

@Injectable({
    providedIn: 'root'
})
export class QuestionControlService {

    constructor() {
    }

    toFormGroup(questions: QuestionBase<any>[]) {
        const group = {};

        questions.forEach(question => {
            group[question.key] = question.required ? new FormControl(question.value || '', Validators.required) : new FormControl(question.value || '');
        });

        return new FormGroup(group);
    }
}
