import {EdwContactStep} from './ContactStep';
import {EdwContact} from '../contact-dir/EdwContact';
import {EntitySerializer} from '../entity-dir/EntitySerializer';

export class EdwContactStepSerializer implements EntitySerializer {

    public mapping = new Map([
        ['priority', 'priority']
    ]);

    fromJson(json: any): EdwContactStep {
        const contactStep = new EdwContactStep();
        this.mapping.forEach((value, key, map) => {
            contactStep[key] = json[value];
        });
        const ctc: EdwContact = new EdwContact();
        ctc.id = json['contact_id'];
        contactStep.contact = ctc;
        return contactStep;
    }

    toApi(contactStep: EdwContactStep): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = contactStep[key];
        });
        return obj;
    }
}
