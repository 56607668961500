import {Injectable} from '@angular/core';
import {EntityService} from '../entity.service';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {EdwContact, EdwContactSerializer} from '@edward-software/edw-fwk-angular-lib/models';

@Injectable({
    providedIn: 'root'
})
export class EdwContactService extends EntityService<EdwContact> {

    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiAdbUrl,
            'contacts',
            new EdwContactSerializer()
        );
    }

    addContactCompany(contactId: string, companyId: string) {
        return this.http.post(`${this.url}/contacts/${contactId}/companies/${companyId}`,
            this.options
        )
            .pipe(map(
                contact => this.serializer.fromJson(contact) as EdwContact
            ));
    }

    /**
     * Récupérer les informations d'un contact avec le userId
     * On ne peut pas faire un contactService.list() car un seul résultat (un json object) est renvoyé
     * par le back-end avec cette route
     * (Méthode utilisée pour le composant changes)
     */
    getByUserId(userId) {
        return this.http.get(`${this.url}/${this.resource}?get_by_user=${userId}`,
            this.options
        )
            .pipe(map(
                contact => this.serializer.fromJson(contact) as EdwContact
            ));
    }

    generateTrigram(fName: string, lName: string) {
        let firstName: any = fName;
        let lastName: any = lName;
        let trigram = '';
        let doubleFirstName = false;
        let doubleLastName = false;

        /**
         * Algorithme Standard qui permert de générer un trigram basic :
         * Prmière lettre du prénom, première lettre du nom et dernière lettre du nom
         */
        if (firstName.match('\-')) {
            doubleFirstName = true;
        }

        if (lastName.match('\-')) {
            doubleLastName = true;
        }

        if (!doubleFirstName && !doubleLastName) {
            trigram = firstName.substr(0, 1) + lastName.substr(0, 1) + lastName.substr(-1, 1);
        }

        /**
         * Prénom composé et nom standard :
         * Première lettre du prénom, première lettre du deuxième prénom, première lettre du nom
         */
        if (doubleFirstName && !doubleLastName) {
            if (firstName.match('-')) {
                firstName = firstName.split('-');
            } else if (firstName.match('\ ')) {
                firstName = firstName.split('\ ');
            }

            if (firstName.length === 2) {
                trigram = firstName[0].substr(0, 1) + firstName[1].substr(0, 1) + lastName.substr(0, 1);
            }
        }

        /**
         * Prénom simple et nom composé :
         * Première lettre du prénom, première lettre du premier nom, première lettre du deuxième nom
         */
        if ((!doubleFirstName && doubleLastName) || (doubleFirstName && doubleLastName)) {
            if (lastName.match('-')) {
                lastName = lastName.split('-');
            } else if (lastName.match('\ ')) {
                lastName = lastName.split('\ ');
            } else if (lastName.match('\'')) {
                lastName = lastName.split('\'');
            }

            if (lastName.length === 2) {
                trigram = firstName.substr(0, 1) + lastName[0].substr(0, 1) + lastName[1].substr(0, 1);
            }
        }

        return trigram.toUpperCase();
    }

}
