import {Entity} from '../entity-dir/Entity';
import {EdwWorkflow} from './Workflow';
import {EdwValidationStep} from './ValidationStep';
import {EdwValidationCustom} from './ValidationCustom';
import {EdwContact} from '../contact-dir/EdwContact';

export class EdwValidation extends Entity {


    private _dateCreation: Date;
    private _dateUpdate: Date;
    private _contactSender: EdwContact;
    private _contactSenderId: string;
    private _contactSenderInfos: EdwContact;
    private _contactSenderSubscribe: boolean;
    private _type: string;
    private _locale: string;
    private _status: string;
    private _workflow: EdwWorkflow;
    private _validationSteps: EdwValidationStep[];
    private _launchDate: Date;
    private _endDate: Date;
    private _custom: EdwValidationCustom;
    private _currentPosition: number;
    private _name: string;
    private _participants: EdwContact[];
    private _data: any;

    constructor() {
        super();
    }
    get dateCreation(): Date {
        return this._dateCreation;
    }

    set dateCreation(value: Date) {
        this._dateCreation = value;
    }
    get dateUpdate(): Date {
        return this._dateUpdate;
    }

    set dateUpdate(value: Date) {
        this._dateUpdate = value;
    }
    get contactSender(): EdwContact {
        return this._contactSender;
    }

    set contactSender(value: EdwContact) {
        this._contactSender = value;
    }

    get contactSenderId(): string {
        return this._contactSenderId;
    }

    set contactSenderId(value: string) {
        this._contactSenderId = value;
    }

    get contactSenderSubscribe(): boolean {
        return this._contactSenderSubscribe;
    }

    set contactSenderSubscribe(value: boolean) {
        this._contactSenderSubscribe = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get locale(): string {
        return this._locale;
    }

    set locale(value: string) {
        this._locale = value;
    }

    get status(): string {
        return this._status;
    }

    set status(value: string) {
        this._status = value;
    }

    get workflow(): EdwWorkflow {
        return this._workflow;
    }

    set workflow(value: EdwWorkflow) {
        this._workflow = value;
    }

    get validationSteps(): EdwValidationStep[] {
        return this._validationSteps;
    }

    set validationSteps(value: EdwValidationStep[]) {
        this._validationSteps = value;
    }

    get launchDate(): Date {
        return this._launchDate;
    }

    set launchDate(value: Date) {
        this._launchDate = value;
    }

    get endDate(): Date {
        return this._endDate;
    }

    set endDate(value: Date) {
        this._endDate = value;
    }

    get custom(): EdwValidationCustom {
        return this._custom;
    }

    set custom(value: EdwValidationCustom) {
        this._custom = value;
    }

    get currentPosition(): number {
        return this._currentPosition;
    }

    set currentPosition(value: number) {
        this._currentPosition = value;
    }
    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }
    get contactSenderInfos(): EdwContact {
        return this._contactSenderInfos;
    }

    set contactSenderInfos(value: EdwContact) {
        this._contactSenderInfos = value;
    }
    get participants(): EdwContact[] {
        return this._participants;
    }

    set participants(value: EdwContact[]) {
        this._participants = value;
    }
    get data(): any {
        return this._data;
    }

    set data(value: any) {
        this._data = value;
    }
}
