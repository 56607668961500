import {Entity} from '../entity-dir/Entity';
import {ScheduleYear} from './ScheduleYear';
import {ScheduleQuarter} from './ScheduleQuarter';
import {ScheduleMonth} from './ScheduleMonth';
import {Activity} from '../activity-dir/Activity';
import {ActivityScheduleInfo} from '../activity-dir/ActivityScheduleInfo';
import {Milestone} from '../milestone-dir/Milestone';
import {MilestoneScheduleInfo} from '../milestone-dir/MilestoneScheduleInfo';

export class Structure extends Entity {
    private _startDate: Date;
    private _endDate: Date;
    private _duration: number;
    private _totalNbDays: number;
    private _years: ScheduleYear[];
    private _quarters: ScheduleQuarter[];
    private _months: ScheduleMonth[];
    private _activities: Activity[];
    private _activityScheduleInfos: ActivityScheduleInfo[];
    private _milestones: Milestone[];
    private _milestoneScheduleInfos: MilestoneScheduleInfo[];

    constructor() {
        super();
    }

    get startDate(): Date {
        return this._startDate;
    }

    set startDate(value: Date) {
        this._startDate = value;
    }

    get endDate(): Date {
        return this._endDate;
    }

    set endDate(value: Date) {
        this._endDate = value;
    }

    get duration(): number {
        return this._duration;
    }

    set duration(value: number) {
        this._duration = value;
    }

    get totalNbDays(): number {
        return this._totalNbDays;
    }

    set totalNbDays(value: number) {
        this._totalNbDays = value;
    }

    get years(): ScheduleYear[] {
        return this._years;
    }

    set years(value: ScheduleYear[]) {
        this._years = value;
    }

    get quarters(): ScheduleQuarter[] {
        return this._quarters;
    }

    set quarters(value: ScheduleQuarter[]) {
        this._quarters = value;
    }

    get months(): ScheduleMonth[] {
        return this._months;
    }

    set months(value: ScheduleMonth[]) {
        this._months = value;
    }

    get activities(): Activity[] {
        return this._activities;
    }

    set activities(value: Activity[]) {
        this._activities = value;
    }

    get activityScheduleInfos(): ActivityScheduleInfo[] {
        return this._activityScheduleInfos;
    }

    set activityScheduleInfos(value: ActivityScheduleInfo[]) {
        this._activityScheduleInfos = value;
    }

    get milestones(): Milestone[] {
        return this._milestones;
    }

    set milestones(value: Milestone[]) {
        this._milestones = value;
    }

    get milestoneScheduleInfos(): MilestoneScheduleInfo[] {
        return this._milestoneScheduleInfos;
    }

    set milestoneScheduleInfos(value: MilestoneScheduleInfo[]) {
        this._milestoneScheduleInfos = value;
    }

    /**
     * Parcourt toutes les activityScheduleInfos de la Structure
     * Cherche celle dont l'actibityId est égale à l'activity en param
     * Renvoie le diffStartDate de celle trouvée, renvoie 0 sinon
     */
    public getDiffStartDateActivity(activity: Activity): number {
        let diffStartDate = 0;
        if (this.activityScheduleInfos instanceof Array) {
            this.activityScheduleInfos.forEach(activityScheduleInfo => {
                if (activityScheduleInfo.activityId === activity.id) {
                    diffStartDate = activityScheduleInfo.diffStartDate;
                }
            });
        }
        return diffStartDate;
    }

    /**
     * Parcourt toutes les activityScheduleInfos de la Structure
     * Cherche celle dont l'actibityId est égale à l'activity en param
     * Renvoie le diffStartDate de celle trouvée, renvoie 0 sinon
     */
    public getDiffStartDateMilestone(milestone: Milestone): number {
        let diffStartDate = 0;
        if (this.milestoneScheduleInfos instanceof Array) {
            this.milestoneScheduleInfos.forEach(milestoneScheduleInfo => {
                if (milestoneScheduleInfo.milestoneId === milestone.id) {
                    diffStartDate = milestoneScheduleInfo.diffStartDate;
                }
            });
        }
        return diffStartDate;
    }

    /**
     * Return une activity recherchée par nom
     * Return false si pas d'activity
     */
    public getActivityByName(name: string) {
        if (this.activities && this.activities instanceof Array && this.activities.length > 0) {
            return this.activities.find(act => act.name === name);
        }
        return null;
    }

    /**
     * Calcul le total des durations des activités
     */
    getTotalActivitiesDuration(): number {
        let total = 0;
        if (this.activities && this.activities instanceof Array) {
            this.activities.forEach(act => {
                total += +act.duration;
            });
        }
        return total;
    }
}
