import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[edwThreadNavbarContentDirective]'
})
export class ThreadNavbarContentDirective {

    constructor(public viewContainerRef: ViewContainerRef) { }

}
