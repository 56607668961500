import {Entity} from '../entity-dir/Entity';

export class EdwSnapshot extends Entity {
    private _name?: string;
    private _dateCreation: Date;

    constructor() {
        super();
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get dateCreation(): Date {
        return this._dateCreation;
    }

    set dateCreation(value: Date) {
        this._dateCreation = value;
    }
}
