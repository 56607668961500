import {NgModule} from '@angular/core';
import {CommonAppComponent} from './common-app.component';


@NgModule({
    declarations: [CommonAppComponent],
    exports: [
        CommonAppComponent
    ],
    imports: []
})
export class EdwCommonAppModule {
}
