export class Toast {
    private _severity: string;
    private _summary: string;
    private _detail: string;
    private _life: number;
    private _data?: any;

    constructor(severity: string, summary: string, detail: string) {
        this._severity = severity;
        this._summary = summary;
        this._detail = detail;
        this._life = 3000;
    }


    get severity(): string {
        return this._severity;
    }

    set severity(value: string) {
        this._severity = value;
    }

    get summary(): string {
        return this._summary;
    }

    set summary(value: string) {
        this._summary = value;
    }

    get detail(): string {
        return this._detail;
    }

    set detail(value: string) {
        this._detail = value;
    }

    get life(): number {
        return this._life;
    }

    set life(value: number) {
        this._life = value;
    }

    get data(): any {
        return this._data;
    }

    set data(value: any) {
        this._data = value;
    }
}
