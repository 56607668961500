import {Entity} from '../entity-dir/Entity';

export class GlobalSearchRequiredDatas extends Entity {

    private _service: any;
    private _objects: string;
    constructor() {
        super();
    }

    get service(): any {
        return this._service;
    }

    set service(value: any) {
        this._service = value;
    }

    get objects(): string {
        return this._objects;
    }

    set objects(value: string) {
        this._objects = value;
    }
}
