import {JwtWorkspace} from './JwtWorkspace';

export class JwtPayload {
  private _iat: number;
  private _exp: number;
  private _roles: string[];
  private _username: string;
  private _userId: string;
  private _userIpAddress: string;
  private _workspaces: JwtWorkspace[];


  get iat(): number {
    return this._iat;
  }

  get exp(): number {
    return this._exp;
  }

  get roles(): string[] {
    return this._roles;
  }

  get username(): string {
    return this._username;
  }

  get userId(): string {
    return this._userId;
  }

  get userIpAddress(): string {
    return this._userIpAddress;
  }

  get workspaces(): JwtWorkspace[] {
    return this._workspaces;
  }
}
