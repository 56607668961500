import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import {EdwToolsLocaleService} from '../services/tools/edw-tools-locale.service';

@Pipe({
    name: 'localizedDate',
    pure: false
})
export class LocalizedDatePipe implements PipeTransform {

    constructor(private localeService: EdwToolsLocaleService) {
    }


    transform(value: any, pattern: string = 'mediumDate'): any {
        const datePipe: DatePipe = new DatePipe(this.localeService.dateLocale);
        return datePipe.transform(value, pattern);
    }

}
