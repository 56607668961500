import {Entity} from '../entity-dir/Entity';

export class ShortcutItem extends Entity {
    private _userId: string;
    private _workspaceId: string;
    private _url: string;
    private _position: number;
    private _name: string;
    private _shortName: string;
    private _appFront?: string;
    private _picto: string;


    constructor(userId: string = null, workspaceId: string = null, url: string = null, position: number = null, name: string = null, shortName: string = null, appFront: string = null) {
        super();
        this.userId = userId;
        this.workspaceId = workspaceId;
        this.url = url;
        this.position = position;
        this.name = name;
        this.shortName = shortName;
        this._appFront = appFront;
    }

    get userId(): string {
        return this._userId;
    }

    set userId(value: string) {
        this._userId = value;
    }

    get workspaceId(): string {
        return this._workspaceId;
    }

    set workspaceId(value: string) {
        this._workspaceId = value;
    }

    get url(): string {
        return this._url;
    }

    set url(value: string) {
        this._url = value;
    }

    get position(): number {
        return this._position;
    }

    set position(value: number) {
        this._position = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get shortName(): string {
        return this._shortName;
    }

    set shortName(value: string) {
        this._shortName = value;
    }

    get appFront(): string {
        return this._appFront;
    }

    set appFront(value: string) {
        this._appFront = value;
    }

    get picto(): string {
        return this._picto;
    }

    set picto(value: string) {
        this._picto = value;
    }
}
