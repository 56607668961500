import {Inject, Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {EntitySerializer, EnvConfig, EnvConfigSerializer, ENVIRONMENT_PARAMETER, Organization} from '@edward-software/edw-fwk-angular-lib/models';
import {map} from 'rxjs/operators';
import {EntityService} from '../entity.service';

@Injectable({
    providedIn: 'root'
})
export class EnvConfigService extends EntityService<EnvConfig> {

    envConfig: any;
    httpClient: HttpClient;
    serializer: EnvConfigSerializer;
    configObject: {};

    constructor(protected http: HttpClient,
                @Inject(ENVIRONMENT_PARAMETER) protected environmentParameter: any,
                protected handler: HttpBackend) {
        super(
            http,
            '',
            'public/environments',
            new EnvConfigSerializer(),
            handler
        );

        this.httpClient = new HttpClient(handler);
    }

    /********************************
     * RÉCUPÉRATION de la config
     *********************************/
    async getEnvConfig() {
        return new Promise((resolve, reject) => {
            const envConfig = this.httpClient.get(`${this.environmentParameter.edsStaticUrl}/${this.environmentParameter.environment}_${this.environmentParameter.app}.json`)
                .pipe(map(resp => this.serializer.fromJson(resp) as EnvConfig));
            envConfig.subscribe(resp => {
                const envConfigSerialize = {};
                for (const key of Object.keys(resp)) {
                    envConfigSerialize[key.replace(/[_]/g, '')] = resp[key];
                }
                localStorage.setItem('ENV_CONFIG', JSON.stringify(envConfigSerialize));
                resolve(true);
            });
        });
    }

    setNavData(config) {
        this.envConfig = config;
    }

    getNavData() {
        if (this.envConfig) {
            return this.envConfig;
        }
    }


}
