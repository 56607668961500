import {Injectable} from '@angular/core';
import {User} from '@edward-software/edw-fwk-angular-lib/models';
import {BehaviorSubject} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EdwHeaderNavbarService {

    private savedUser: User;
    currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    menuItemEditing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);


    constructor(private router: Router) {
        router.events
            .pipe(
                filter(e => e instanceof NavigationEnd))
            .forEach(e => {
                if (this.savedUser && this.savedUser instanceof User) {
                    this.currentUser.next(this.savedUser);
                }
            });
    }

    setCurrentUser(usr: User) {
        this.savedUser = usr;
        this.currentUser.next(usr);
    }

    setMenuItemEditing(value: boolean) {
        this.menuItemEditing.next(value);
    }

}
