import {MissingTranslationHandler, MissingTranslationHandlerParams, TranslateService} from '@ngx-translate/core';

export class EdwMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        if (params.interpolateParams) {
            return params.interpolateParams['Default'] || params.key;
        }
        return params.key;
    }
}
