import {Picture} from './Picture';
import {EntitySerializer} from '../entity-dir/EntitySerializer';

export class PictureSerializer implements EntitySerializer {

    public mapping = new Map([
        ['url', 'url'],
    ]);

    fromJson(json: any): Picture {
        const picture = new Picture();
        this.mapping.forEach((value, key, map) => {
            picture[key] = json[value];
        });

        return picture;
    }

    toApi(picture: Picture): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = picture[key];
        });
        return obj;
    }
}
