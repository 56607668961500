import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {ScheduleQuarter} from './ScheduleQuarter';

export class ScheduleQuarterSerializer implements EntitySerializer {

    public mapping = new Map([
        ['year', 'year'],
        ['quarter', 'quarter'],
        ['startDate', 'start_date'],
        ['endDate', 'end_date'],
        ['duration', 'duration'],
        ['nbDays', 'nb_days'],
    ]);

    fromJson(json: any): ScheduleQuarter {
        const scheduleQuarter = new ScheduleQuarter();
        this.mapping.forEach((value, key, map) => {
            scheduleQuarter[key] = json[value];
        });
        return scheduleQuarter;
    }

    toApi(scheduleQuarter: ScheduleQuarter): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = scheduleQuarter[key];
        });
        return obj;
    }

}
