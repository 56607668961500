import {Entity} from '../entity-dir/Entity';
import {Picture} from '../picture-dir/Picture';

export class EdwCompany extends Entity {
    private _name: string;
    private _comment: string;
    private _companyPicture: Picture;
    private _jobTitle: string;

    constructor() {
        super();
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get comment(): string {
        return this._comment;
    }

    set comment(value: string) {
        this._comment = value;
    }

    get companyPicture(): Picture {
        return this._companyPicture;
    }

    set companyPicture(value: Picture) {
        this._companyPicture = value;
    }

    get jobTitle(): string {
        return this._jobTitle;
    }

    set jobTitle(value: string) {
        this._jobTitle = value;
    }
}
