import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {EdwWorkflowValidation} from './WorkflowValidation';

export class WorkflowValidationSerializer implements EntitySerializer {


    public mapping = new Map([
        ['id', 'id'],
    ]);

    fromJson(json: any): EdwWorkflowValidation {
        const workflowValidation = new EdwWorkflowValidation();
        this.mapping.forEach((value, key, map) => {
            workflowValidation[key] = json[value];
        });
        return workflowValidation;
    }

    toApi(workflowValidation: EdwWorkflowValidation): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = workflowValidation[key];
        });

        return obj;
    }
}
