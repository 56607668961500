import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {EdwValidationStepResult} from './ValidationStepResult';
import {EdwContactValidationStepSerializer} from './ContactValidationStepSerializer';

export class EdwValidationStepResultSerializer implements EntitySerializer {

    private contactValidationStepSerializer = new EdwContactValidationStepSerializer();

    public mapping = new Map([
        ['result', 'result'],
        ['comment', 'comment'],
        ['sendingDate', 'sending_date'],
        ['validationDate', 'validation_date'],
        ['contactId', 'contact_id']
    ]);


    fromJson(json: any): EdwValidationStepResult {
        const validationStepResult = new EdwValidationStepResult();
        this.mapping.forEach((value, key, map) => {
            validationStepResult[key] = json[value];
        });

        if (json['contact_validation_step']) {
            validationStepResult.contactValidationStep = this.contactValidationStepSerializer.fromJson(json['contact_validation_step']);
        }

        return validationStepResult;
    }

    toApi(validationStepResult: EdwValidationStepResult): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = validationStepResult[key];
        });

        return obj;
    }
}
