import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EntityService} from '../entity.service';
import {map} from 'rxjs/operators';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ListResponse, Organization, OrganizationSerializer, QueryOption} from '@edward-software/edw-fwk-angular-lib/models';

@Injectable({
    providedIn: 'root'
})
export class EdwOrganizationService extends EntityService<Organization> implements OnDestroy {

    private savedOrganization: Organization;
    public currentOrganization: BehaviorSubject<Organization> = new BehaviorSubject<Organization>(null);
    currentUserSubscription: Subscription;
    httpOptions: any;
    headers = new HttpHeaders().set('Content-Type', 'application/json');

    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiAuthUrl,
            'organizations',
            new OrganizationSerializer()
        );
    }

    /**
     * Récupération des organizations de l'utilisateur connecté
     */
    listMine(organizationQueryOption: QueryOption) {
        return this.http.get(`${this.url}/me/${this.resource}?${organizationQueryOption.toQueryString()}`)
            .pipe(map(
                (data: any) => {
                    return new ListResponse(
                        this.convertData(data.data),
                        this.paginationMetaSerializer.fromJson(data.meta)
                    );
                }
            ));
    }

    listFromUser(userId: string, organizationQueryOption: QueryOption) {
        return this.http.get(`${this.url}/users/${userId}/${this.resource}?${organizationQueryOption.toQueryString()}`)
            .pipe(map(
                (data: any) => {
                    return new ListResponse(
                        this.convertData(data.data),
                        this.paginationMetaSerializer.fromJson(data.meta)
                    );
                }
            ));
    }

    /**************************
     * Quand une nouvelle organization est sélectionnée,
     * on l'enregistre dans le localeStorage et on l'envoie aux observers
     *************************/

    setCurrentOrganization(orga: Organization) {
        this.savedOrganization = orga;
        this.currentOrganization.next(orga);
    }


    ngOnDestroy(): void {
        this.currentUserSubscription.unsubscribe();
    }

    /**************************
     *Remove user from organization
     *************************/

    removeUser(organizationId: string, userId: string) {
        return this.http.delete(`${this.url}/${this.resource}/${organizationId}/users/${userId}`)
            .pipe(map(
                resp => resp
            ));
    }


    /**************************
     *Edit Picture from organization
     *************************/
    public editAvatar(organizationId: string, file: string) {
        return this.http.post(
            `${this.url}/${this.resource}/${organizationId}/picture`,
            {
                picture_file: file,
                encodage: 'b64'
            },
            this.options
        )
            .pipe(map(resp => resp));
    }

    /**************************
     *Edit custom settings of organization
     *************************/
    public editCustomSettings(organization: Organization) {
        return this.http.put(
            `${this.url}/${this.resource}/${organization.id}/custom_settings`,
            {
                custom_settings: organization.customSettings
            },
            this.options
        )
            .pipe(map(resp => resp));
    }

    /**************************
     *Delete Picture from organization
     *************************/

    deleteAvatar(organizationId: string) {
        return this.http.delete(
            `${this.url}/${this.resource}/${organizationId}/picture`,
            {}
        )
            .pipe(map(resp => resp));
    }

    /**************************
     *Réordonner des organizations
     *************************/

    updatePositionList(ids: string[], userId: string) {
        return this.http.put(`${this.url}/users/${userId}/organizations/position`,
            {
                organizations: ids
            },
            this.options
        )
            .pipe(map(steps => steps));
    }

    getCountries() {
        // return this.http.get(`https://restcountries.eu/rest/v2/all`, this.httpOptions);
        // return this.http.get(`https://gorest.co.in/public-api/posts`);
        return this.http.get('https://restcountries.eu/rest/v2/all', this.httpOptions);
    }


}
