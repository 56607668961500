import {Pipe, PipeTransform} from '@angular/core';


/**
 * Formate les nombres avec des espaces entre les milliers
 * 100000000 devient 100 000 000
 * passe en écriture scientitfique a partir de 1e+21
 */

@Pipe({
    name: 'spaceNumber'
})
export class SpaceNumberPipe implements PipeTransform {

    transform(value: any): string {
        return (value) ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '';
    }

}
