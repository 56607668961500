import {Inject, Injectable} from '@angular/core';
import {EnvConfig, ENVIRONMENT_PARAMETER, Workspace} from '@edward-software/edw-fwk-angular-lib/models';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class EdwToolsNavigatorService {
    envConfig: EnvConfig;

    constructor(
        private router: Router,
        @Inject(ENVIRONMENT_PARAMETER) public environmentParameter: any) {

        this.getEnvConfig();


    }

    /************************************************
     * Récupération des tokens pour la redirection
     ***********************************************/

    private getToken() {
        return JSON.parse(localStorage.getItem('access_token'));
    }

    private getRefreshToken() {
        return this.getToken()?.refresh_token;
    }

    private openUrl(url: string, event: MouseEvent) {
        if (event && (event.ctrlKey || event.metaKey)) {
            window.open(url, '_blank');
        } else {
            window.location.href = url;
        }

        window.open(url, '_blank');
    }

    getEnvConfig() {
        this.envConfig = JSON.parse(localStorage.getItem('ENV_CONFIG'));
    }


    /************************************************
     * Navigation vers les fronts
     ***********************************************/

    /**
     * Navigation vers l'appli Home (utilisé pour les balises a avec href)
     */
    navigateToHome(callbackUrl = '', refresh = true, event: MouseEvent = null, returnUrl = false) {
        if (this.environmentParameter.app !== 'home' || returnUrl) {
            let url = `${this.envConfig.frontHomeUrl}`;
            if (refresh) {
                url += `/login/refresh?refreshToken=${this.getRefreshToken()}&callbackUrl=${callbackUrl}`;
            } else {
                url += callbackUrl;
            }
            if (returnUrl) {
                return url;
            }
            this.openUrl(url, event);
        } else {
            this.router.navigate([callbackUrl]);
        }
    }

    /**
     * Navigation vers l'appli Home (utilisé pour les balises a avec fonction au click)
     */
    navigateToHomeByNavbar(callbackUrl = '', refresh = true, returnUrl = false) {
        if (this.environmentParameter.app !== 'home' || returnUrl) {
            let url = `${this.envConfig.frontHomeUrl}`;
            if (refresh) {
                url += `/login/refresh?refreshToken=${this.getRefreshToken()}&callbackUrl=${callbackUrl}`;
            } else {
                url += callbackUrl;
            }
            window.open(url, '_blank');
        } else {
            this.router.navigate([callbackUrl]);
        }
    }

    /**
     * Navigation vers l'appli ADB
     */
    navigateToAdb(callbackUrl = '', refresh = true, event: MouseEvent = null, returnUrl = false) {
        if (this.environmentParameter.app !== 'adb' || returnUrl) {
            let url = `${this.envConfig.frontAdbUrl}`;
            if (refresh) {
                url += `/login/refresh?refreshToken=${this.getRefreshToken()}&callbackUrl=${callbackUrl}`;
            } else {
                url += callbackUrl;
            }
            if (returnUrl) {
                return url;
            }
            // this.openUrl(url, event);
        } else {
            this.router.navigate([callbackUrl]);
        }
    }

    /**
     * Navigation vers la homepage de l'appli DCM V1
     */
    navigateToPmoV1(callbackUrl = '', refresh = true, event: MouseEvent = null, returnUrl = false) {
        if (this.environmentParameter.app !== 'pmo' || returnUrl) {
            let url = `${this.envConfig.frontPmoUrl}`;
            if (refresh) {
                url += `/login/refresh?refreshToken=${this.getRefreshToken()}&callbackUrl=${callbackUrl}`;
            } else {
                url += callbackUrl;
            }
            if (returnUrl) {
                return url;
            }
            this.openUrl(url, event);
        } else {
            this.router.navigate([callbackUrl]);
        }
    }

    /**
     * Navigation vers l'appli PPM
     */
    navigateToPpm(callbackUrl = '', refresh = true, event: MouseEvent = null, returnUrl = false) {
        if (this.environmentParameter.app !== 'ppm' || returnUrl) {
            let url = `${this.envConfig.frontPpmUrl}`;
            if (refresh) {
                url += `/login/refresh?refreshToken=${this.getRefreshToken()}&callbackUrl=${callbackUrl}`;
            } else {
                url += callbackUrl;
            }
            if (returnUrl) {
                return url;
            }
            this.openUrl(url, event);
        } else {
            this.router.navigate([callbackUrl]);
        }
    }

    /**
     * Navigation vers la homepage de l'appli Social
     */
    navigateToSocial(callbackUrl = '', refresh = true, event: MouseEvent = null, returnUrl = false) {
        if (this.environmentParameter.app !== 'social' || returnUrl) {
            let url = `${this.envConfig.frontSocialUrl}`;
            if (refresh) {
                url += `/login/refresh?refreshToken=${this.getRefreshToken()}&callbackUrl=${callbackUrl}`;
            } else {
                url += callbackUrl;
            }
            if (returnUrl) {
                return url;
            }
            this.openUrl(url, event);
        } else {
            this.router.navigate([callbackUrl]);
        }
    }

    /**
     * Navigation vers la homepage de l'appli Workflow
     */
    navigateToWorkflow(callbackUrl = '', refresh = true, event: MouseEvent = null, returnUrl = false) {
        if (this.environmentParameter.app !== 'workflow' || returnUrl) {
            let url = `${this.envConfig.frontWorkflowUrl}`;
            if (refresh) {
                url += `/login/refresh?refreshToken=${this.getRefreshToken()}&callbackUrl=${callbackUrl}`;
            } else {
                url += callbackUrl;
            }
            if (returnUrl) {
                return url;
            }
            this.openUrl(url, event);
        } else {
            this.router.navigate([callbackUrl]);
        }
    }

    /**
     * Navigation vers la homepage de l'appli File
     */
    navigateToFile(callbackUrl = '', refresh = true, event: MouseEvent = null, returnUrl = false) {
        if (this.environmentParameter.app !== 'file' || returnUrl) {
            let url = `${this.envConfig.frontFileUrl}`;
            if (refresh) {
                url += `/login/refresh?refreshToken=${this.getRefreshToken()}&callbackUrl=${callbackUrl}`;
            } else {
                url += callbackUrl;
            }
            if (returnUrl) {
                return url;
            }
            this.openUrl(url, event);
        } else {
            this.router.navigate([callbackUrl]);
        }
    }

    /**
     * Navigation vers la homepage de l'appli TSM
     */
    navigateToTsm(callbackUrl = '', refresh = true, event: MouseEvent = null, returnUrl = false) {
        if (this.environmentParameter.app !== 'tsm' || returnUrl) {
            let url = `${this.envConfig.frontTsmUrl}`;
            if (refresh) {
                url += `/login/refresh?refreshToken=${this.getRefreshToken()}&callbackUrl=${callbackUrl}`;
            } else {
                url += callbackUrl;
            }
            if (returnUrl) {
                return url;
            }
            this.openUrl(url, event);
        } else {
            this.router.navigate([callbackUrl]);
        }
    }

    /**
     * Navigation vers la homepage de l'appli NGTools
     */
    navigateToNgTools(callbackUrl = '', refresh = true, event: MouseEvent = null, returnUrl = false) {
        if (this.environmentParameter.app !== 'ngTools' || returnUrl) {
            let url = `${this.envConfig.frontNgToolsUrl}`;
            if (refresh) {
                url += `/login/refresh?refreshToken=${this.getRefreshToken()}&callbackUrl=${callbackUrl}`;
            } else {
                url += callbackUrl;
            }
            if (returnUrl) {
                return url;
            }
            this.openUrl(url, event);
        } else {
            this.router.navigate([callbackUrl]);
        }
    }

    /**
     * Function qui permet de naviguer entre les différentes applications avec comme options ('_blank', '_self', module)
     * On passe le workspace en paramètre si le workspace n'est pas stockés dans le LocalStorage ==> Ex : Je me connecte sur HOME pour la première fois
     */

    navigateToModule(module: string, opening: string, workspace?: Workspace) {
        if (workspace) {
            localStorage.setItem('workspace', JSON.stringify(workspace).replace(/[_]/g, ''));
        }
        let configFrontUrl;
        if (module === 'ADB') {
            configFrontUrl = this.envConfig.frontAdbUrl;
        } else if (module === 'PPM') {
            configFrontUrl = this.envConfig.frontPpmUrl;
        } else if (module === 'WORKFLOW') {
            configFrontUrl = this.envConfig.frontWorkflowUrl;
        } else if (module === 'SOCIAL') {
            configFrontUrl = this.envConfig.frontSocialUrl;
        } else if (module === 'TSM') {
            configFrontUrl = this.envConfig.frontTsmUrl;
        } else if (module === 'FILE') {
            configFrontUrl = this.envConfig.frontFileUrl;
        } else if (module === 'HOME') {
            configFrontUrl = this.envConfig.frontHomeUrl;
        }

        window.open(`${configFrontUrl}/login/refresh?refreshToken=${JSON.parse(localStorage.getItem('access_token')).refresh_token}&callbackUrl=/w/${encodeURIComponent(this.getSystemName())}`, opening);
    }

    public getSystemName() {
        if (sessionStorage.getItem('workspace')) {
            return JSON.parse(sessionStorage.getItem('workspace')).systemName;
        } else if (localStorage.getItem('workspace')) {
            return JSON.parse(localStorage.getItem('workspace')).systemName;
        } else {
            return null;
        }
    }


}
