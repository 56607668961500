import {Injectable} from '@angular/core';
import {EntityService} from '../entity.service';
import {EdwContact, EdwWorkflowStep, WorkflowStepSerializer} from '@edward-software/edw-fwk-angular-lib/models';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EdwWorkflowStepService  extends EntityService<EdwWorkflowStep> {

    constructor(protected http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiWorkflowUrl,
            'steps',
            new WorkflowStepSerializer()
        );
    }

    /***************
     * Ajout d'une step à un workflow
     ***************/
    createFromWorkflow(workflowId: string, step: EdwWorkflowStep): Observable<EdwWorkflowStep> {
        return this.http.post(`${this.url}/workflows/${workflowId}/${this.resource}`,
            this.serializer.toApi(step),
            this.options
        )
            .pipe(map(
                resp => this.serializer.fromJson(resp) as EdwWorkflowStep
            ));
    }

    /**
     * Ajout d'un contact à une étape
     */
    public addContactStep(stepId: string, contactId: string, priority: number): Observable<any> {
        return this.http.post(`${this.url}/steps/${stepId}/contacts/${contactId}`,
            {
                priority: priority
            })
            .pipe(map(contact => {
                return this.serializer.fromJson(contact) as EdwContact;
            }));
    }
    listFromWorkflow(workflowId: string) {
        return this.http.get(`${this.url}/workflows/${workflowId}/${this.resource}`)
            .pipe(map(
                resp => this.convertData(resp)
            ));
    }
    deleteStep(stepId: string) {
        return this.http.delete(`${this.url}/steps/${stepId}`);
    }

    /**
     * Supprime un contact d'une step
     */
    deleteContact(stepId: string, contactId: string) {
        return this.http.delete(`${this.url}/${this.resource}/${stepId}/contacts/${contactId}`)
            .pipe(map(resp => resp));
    }
}
