import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {Milestone} from './Milestone';

export class MilestoneSerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['name', 'name'],
        ['stepDate', 'step_date'],
        ['position', 'position'],
        ['projectId', 'project_id'],
    ]);

    fromJson(json: any): Milestone {
        const milestone = new Milestone();
        this.mapping.forEach((value, key, map) => {
            milestone[key] = json[value];
        });
        return milestone;
    }

    toApi(milestone: Milestone): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = milestone[key];
        });
        return obj;
    }

}
