import {EdwAttachment} from './EdwAttachment';
import {EdwFileSerializer} from '../file-dir/EdwFileSerializer';
import {EdwFile} from '../file-dir/EdwFile';
import {EntitySerializer} from '../entity-dir/EntitySerializer';

export class EdwAttachmentSerializer implements EntitySerializer {

    private fileSerializer: EdwFileSerializer = new EdwFileSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['attachZoneId', 'attach_zone_id'],
        ['objectId', 'object_id'],
        ['objectType', 'object_type']
    ]);

    fromJson(json: any): EdwAttachment {
        const attachment = new EdwAttachment();
        this.mapping.forEach((value, key, map) => {
            attachment[key] = json[value];
        });

        /**
         * Création de workspace
         */
        const file = json['file'];
        if (file) {
            attachment.file = this.fileSerializer.fromJson(file);
        }

        return attachment;
    }

    toApi(attachment: EdwAttachment): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = attachment[key];
        });
        if (attachment.file !== null && attachment.file instanceof EdwFile) {
            obj['file_id'] = attachment.file.id;
        }
        return obj;
    }

}
