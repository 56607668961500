import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ListResponse, QueryOption, Workspace, WorkspaceSerializer} from '@edward-software/edw-fwk-angular-lib/models';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EntityService} from '../entity.service';
import {EnvConfigService} from '../env-config/env-config.service';

@Injectable({
    providedIn: 'root'
})
export class EdwWorkspaceService extends EntityService<Workspace> {

    private savedWorkSpace: Workspace;
    public currentWorkspace: BehaviorSubject<Workspace> = new BehaviorSubject<Workspace>(null);
    public editedCurrentWorkspace: BehaviorSubject<Workspace> = new BehaviorSubject<Workspace>(null);



    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiAuthUrl,
            'workspaces',
            new WorkspaceSerializer()
        );

    }

    listFromUser(userId, queryOption: QueryOption): Observable<ListResponse<Workspace>> {
        return this.http
            .get(`${this.url}/users/${userId}/${this.resource}?${queryOption.toQueryString()}`)
            .pipe(map((resp: any) => {
                return new ListResponse(
                    this.convertData(resp.data),
                    this.paginationMetaSerializer.fromJson(resp.meta)
                );
            }));
    }

    listMine(queryOption: QueryOption): Observable<ListResponse<Workspace>> {
        return this.http
            .get(`${this.url}/me/${this.resource}?${queryOption.toQueryString()}`)
            .pipe(map((resp: any) => {
                return new ListResponse(
                    this.convertData(resp.data),
                    this.paginationMetaSerializer.fromJson(resp.meta)
                );
            }));
    }

    setCurrentWorkSpace(wks: Workspace) {
        this.savedWorkSpace = wks;
        this.currentWorkspace.next(wks);
    }

    setEditedCurrentWorkspace(wks: Workspace) {
        this.editedCurrentWorkspace.next(wks);
    }
}
