import {EdwFileItem} from './EdwFileItem';
import {EntitySerializer} from '../entity-dir/EntitySerializer';

export class EdwFileItemSerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['mimeType', 'mime_type'],
        ['size', 'size'],
        ['versionNumber', 'version_number']
    ]);

    fromJson(json: any): EdwFileItem {
        const fileItem = new EdwFileItem();
        this.mapping.forEach((value, key, map) => {
            fileItem[key] = json[value];
        });


        return fileItem;
    }

    toApi(fileItem: EdwFileItem): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = fileItem[key];
        });
        return obj;
    }

}
