import {Entity} from '../entity-dir/Entity';

export class EdwPhoneNumber extends Entity {
    private _e164Number: string;
    private _internationalNumber: string;
    private _nationalNumber: string;


    constructor() {
        super();
    }

    get e164Number(): string {
        return this._e164Number;
    }

    set e164Number(value: string) {
        this._e164Number = value;
    }

    get internationalNumber(): string {
        return this._internationalNumber;
    }

    set internationalNumber(value: string) {
        this._internationalNumber = value;
    }

    get nationalNumber(): string {
        return this._nationalNumber;
    }

    set nationalNumber(value: string) {
        this._nationalNumber = value;
    }
}
