import {Injectable} from '@angular/core';
import {EntityService} from '../entity.service';
import {EdwWorkflow, EdwWorkflowSerializer, EnvConfig} from '@edward-software/edw-fwk-angular-lib/models';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EdwWorkflowService extends EntityService<EdwWorkflow> {

    envConfig: EnvConfig;

    constructor(protected http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiWorkflowUrl,
            'workflows',
            new EdwWorkflowSerializer()
        );

        this.getEnvConfig();

    }

    getEnvConfig() {
        this.envConfig = JSON.parse(localStorage.getItem('ENV_CONFIG'));
    }


    createFromPortfolio(portfolioId: string, workflow: EdwWorkflow): Observable<any> {
        return this.http.post(`${this.envConfig.apiPpmUrl}/portfolios/${portfolioId}/workflows`, this.serializer.toApi(workflow), this.options)
            .pipe(map(resp => this.serializer.fromJson(resp)));
    }

    createFromProject(projectId: string, workflow: EdwWorkflow): Observable<any> {
        return this.http.post(`${this.envConfig.apiPpmUrl}/projects/${projectId}/workflows`, this.serializer.toApi(workflow), this.options)
            .pipe(map(resp => this.serializer.fromJson(resp)));
    }
}
