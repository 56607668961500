import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {EdwContactStepSerializer} from './ContactStepSerializer';
import {EdwWorkflowStep} from './WorkflowStep';
import {EdwContactStep} from './ContactStep';

export class WorkflowStepSerializer implements EntitySerializer {

    private contactStepSerializer = new EdwContactStepSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['position', 'position'],
        ['label', 'label'],
        ['description', 'description'],
        ['stepDuration', 'step_duration'],
    ]);

    fromJson(json: any): EdwWorkflowStep {
        const workflowStep = new EdwWorkflowStep();
        this.mapping.forEach((value, key, map) => {
            workflowStep[key] = json[value];
        });

        /**
         * Création des contactSteps
         */
        const contactSteps = json['contact_steps'];
        if (contactSteps && contactSteps instanceof Array) {
            const cSteps: EdwContactStep[] = [];
            contactSteps.forEach(cStep => {
                cSteps.push(this.contactStepSerializer.fromJson(cStep));
            });
            workflowStep.contactSteps = cSteps;
        }
        workflowStep.stepDuration = Number((workflowStep.stepDuration / 86400).toFixed(0));
        return workflowStep;
    }

    toApi(workflowStep: EdwWorkflowStep): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = workflowStep[key];
        });
        // obj['step_duration'] = obj['step_duration'] * 86400;

        return obj;
    }
}
