import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EdwToolsLocaleService {

    dateFormat = {
        en: {
            firstDayOfWeek: 1,
            dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            today: 'Today',
            clear: 'Clear',
            dateFormat: 'mm/dd/yy',
            weekHeader: 'Wk'
        },
        fr: {
            firstDayOfWeek: 1,
            dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'ven', 'Sam'],
            dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
            monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
            monthNamesShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
            today: 'Ajd',
            clear: 'Vider',
            dateFormat: 'dd/mm/yy',
            weekHeader: 'Sem'
        }
    };

    private _locale: string;
    private _dateLocale: string;

    constructor() {
    }


    set locale(value: string) {
        this._locale = value;
    }

    get locale(): string {
        return this._locale || 'en-US';
    }

    set dateLocale(value: string) {
        this._dateLocale = value;
    }

    get dateLocale(): string {
        return this._dateLocale || 'en-US';
    }

    public registerLocale(locale: string, dateLocale: string = 'en') {
        switch (locale) {
            case  'fr':
            case 'fr-FR': {
                this.locale = 'fr-FR';
                break;
            }
            default: {
                this.locale = 'en-US';
                break;
            }
        }
        switch (dateLocale) {
            case  'fr':
            case 'fr-FR': {
                this.dateLocale = 'fr-FR';
                break;
            }
            default: {
                this.dateLocale = 'en-US';
                break;
            }
        }
    }

    /**
     * Retourne la configuration d'un calendrier en fonction du dateFormat de l'utilisateur connecté
     */
    getCalendarLocale() {
        let conf = this.dateFormat.en;
        if (!this.dateLocale) {
            return conf;
        }
        switch (this.dateLocale) {
            case 'fr':
            case 'fr-FR':
                conf = this.dateFormat.fr;
                break;
            default: {
                break;
            }
        }
        return conf;
    }


}
