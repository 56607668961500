import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {EdwPublicationContent} from './EdwPublicationContent';

export class EdwPublicationContentSerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['content', 'content'],
        ['locale', 'locale'],

    ]);

    fromJson(json: any): EdwPublicationContent {
        const publicationContent = new EdwPublicationContent();
        this.mapping.forEach((value, key, map) => {
            publicationContent[key] = json[value];
        });
        return publicationContent;
    }

    toApi(publicationContent: EdwPublicationContent): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = publicationContent[key];
        });
        return obj;
    }

}
