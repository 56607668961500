import {EdwCompany} from './EdwCompany';
import {EntitySerializer} from '../entity-dir/EntitySerializer';



export class EdwCompanySerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['name', 'name'],
        ['comment', 'comment'],
        ['companyPicture', 'company_picture'],
        ['jobTitle', 'job_title'],
    ]);

    fromJson(json: any): EdwCompany {
        const company = new EdwCompany();
        this.mapping.forEach((value, key, map) => {
            company[key] = json[value];
        });

        return company;
    }

    toApi(company: EdwCompany): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = company[key];
        });
        return obj;
    }

}
