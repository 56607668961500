import {EdwValidationType} from './ValidationType';
import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {EdwValidationSerializer} from './ValidationSerializer';

export class EdwValidationTypeSerializer implements EntitySerializer {
    private validationSerializer = new EdwValidationSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['data', 'data'],
        ['creationDate', 'date_creation'],
        ['files', 'files'],
        ['attachedFiles', 'attached_files'],
        ['objectId', 'object_id'],
        ['objectType', 'object_type'],
        ['content', 'content'],
        ['zoneId', 'zone_id'],
        ['lockObject', 'lock_object'],
        ['lockedField', 'locked_field'],
    ]);


    fromJson(json: any): EdwValidationType {
        const validationtype = new EdwValidationType();
        this.mapping.forEach((value, key, map) => {
            validationtype[key] = json[value];
        });

        /**
         * Création d'une validation
         */
        if (json['validation'] !== null && typeof (json['validation']) !== 'undefined') {
            validationtype.validation = this.validationSerializer.fromJson(json['validation']);
        }
        return validationtype;
    }

    toApi(validationType: EdwValidationType): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = validationType[key];
        });

        return obj;
    }
}
