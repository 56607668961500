import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {EdwPublication} from './EdwPublication';
import {EdwPublicationContent} from './EdwPublicationContent';
import {EdwPublicationContentSerializer} from './EdwPublicationContentSerializer';

export class EdwPublicationSerializer implements EntitySerializer {
    private publicationContentSerializer = new EdwPublicationContentSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['senderContactId', 'sender_contact_id'],
        ['senderUserId', 'sender_user_id'],
        ['plannedSendingDate', 'planned_sending_date'],
        ['sendingDate', 'sending_date'],
        ['objectId', 'object_id'],
        ['objectType', 'object_type'],
        ['isPublic', 'is_public'],
        ['priority', 'priority'],
        ['comments', 'comments'],
        ['reactions', 'reactions'],
        ['shares', 'shares'],
        ['mentions', 'mentions'],
        ['sendNotification', 'send_notification'],
        ['fromRobot', 'from_robot'],

    ]);

    fromJson(json: any): EdwPublication {
        const publication = new EdwPublication();
        this.mapping.forEach((value, key, map) => {
            publication[key] = json[value];
        });

        /**
         * Création des publicationContent
         */
        const publicationContents = json['publication_contents'];
        if (publicationContents && publicationContents instanceof Array) {
            const pContents: EdwPublicationContent[] = [];
            publicationContents.forEach(ind => {
                pContents.push(this.publicationContentSerializer.fromJson(ind));
            });
            publication.publicationContents = pContents;
        }


        return publication;
    }

    toApi(publication: EdwPublication): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = publication[key];
        });

        return obj;
    }

}
