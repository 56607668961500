import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {UserWorkspace} from './UserWorkspace';
import {UserDataSerializer} from './UserDataSerializer';

export class UserWorkspaceSerializer implements EntitySerializer {
    private userDataSerializer = new UserDataSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['position', 'position'],
        ['roles', 'roles'],
        ['user', 'user'],
    ]);

    fromJson(json: any): UserWorkspace {
        const userWorkspace = new UserWorkspace();
        this.mapping.forEach((value, key, map) => {
            userWorkspace[key] = json[value];
        });
        /**
         * Création des roles
         */
        const roles = json['roles'];
        if (roles && roles instanceof Array) {
            userWorkspace.roles = roles;
        }
        /**
         * user creation
         */
        if (json['user'] !== null && typeof (json['user']) !== 'undefined') {
            userWorkspace.user = this.userDataSerializer.fromJson(json['user']);
        }
        return userWorkspace;
    }

    toApi(userWorkspace: UserWorkspace): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = userWorkspace[key];
        });
        return obj;
    }

}
