import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {EdwToolsDateService} from '../services/tools/edw-tools-date.service';
import * as moment_ from 'moment';


@Injectable()
export class DateInterceptor implements HttpInterceptor {
    // Migrated from AngularJS https://raw.githubusercontent.com/Ins87/angular-date-interceptor/master/src/angular-date-interceptor.js
    iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;
    moment = moment_;


    constructor(private edwDateService: EdwToolsDateService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.body) {
            this.normalize(req.body);
        }
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                const body = event.body;
                this.denormalize(body);
            }
        }));

    }


    denormalize(body) {
        if (body === null || body === undefined) {
            return body;
        }

        if (typeof body !== 'object') {
            return body;
        }

        for (const key of Object.keys(body)) {
            const value = body[key];
            /**
             * On serialize tous les objets sauf de la partie governance
             */
            if (this.isIso8601(value) && !Object.keys(body).includes('governance_id') && !Object.keys(body).includes('meeting_id') && !Object.keys(body).includes('committee_id') && !Object.keys(body).includes('ida_list_id') && !Object.keys(body).includes('ida_list') && !Object.keys(body).includes('planned_start_date') && !Object.keys(body).includes('planned_end_date') && !Object.keys(body).includes('actual_start_date') && !Object.keys(body).includes('actual_end_date')) {
                body[key] = this.edwDateService.denormalize(value);
            } else if (typeof value === 'object') {
                this.denormalize(value);
            }
        }
    }

    normalize(body) {
        if (body === null || body === undefined) {
            return body;
        }

        if (typeof body !== 'object') {
            return body;
        }

        for (const key of Object.keys(body)) {
            const value = body[key];
            /**
             * On serialize tous les objets sauf de la partie governance
             */
            if ((value instanceof Date || this.isIso8601(value)) && !Object.keys(body).includes('governance_id') && !Object.keys(body).includes('meeting_id') && !Object.keys(body).includes('committee_id') && !Object.keys(body).includes('ida_list_id') && !Object.keys(body).includes('ida_list') && !Object.keys(body).includes('planned_start_date') && !Object.keys(body).includes('planned_end_date') && !Object.keys(body).includes('actual_start_date') && !Object.keys(body).includes('actual_end_date')) {
                body[key] = this.edwDateService.normalize(value);
            } else if (typeof value === 'object') {
                this.normalize(value);
            }
        }
    }

    isIso8601(value) {
        if (value === null || value === undefined) {
            return false;
        }
        return this.iso8601.test(value);
    }
}
