import {PaginationMeta} from './PaginationMeta';
import {Entity} from '../entity-dir/Entity';

/**
 * Classe utiséee pour structurer une réponse suite à une requête paginée
 * Toutes les requêtes sur des listes paginées sont retournées sont la forme :
 * data : {
 *
 * },
 * meta: {
 *
 * }
 */
export class ListResponse<T extends Entity> {
    private _data: T[];
    private _meta: PaginationMeta;


    constructor(data: T[], meta: PaginationMeta) {
        this._data = data;
        this._meta = meta;
    }

    get data(): T[] {
        return this._data;
    }

    set data(value: T[]) {
        this._data = value;
    }

    get meta(): PaginationMeta {
        return this._meta;
    }

    set meta(value: PaginationMeta) {
        this._meta = value;
    }
}
