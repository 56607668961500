import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertFileSize'
})
export class ConvertFileSizePipe implements PipeTransform {

    transform(value: any): string {
        value = Math.abs(parseInt(value, 10));
        const def = [[1, 'octets'], [1024, 'ko'], [1024 * 1024, 'Mo'], [1024 * 1024 * 1024, 'Go'], [1024 * 1024 * 1024 * 1024, 'To']];
        for (let i = 0; i < def.length; i++) {
            if (value < def[i][0]) {
                // @ts-ignore
                return (value / def[i - 1][0]).toFixed(2) + ' ' + def[i - 1][1];
            }
        }
        return value;
    }

}
