import {EdwSnapshot} from './EdwSnapshot';
import {EntitySerializer} from '../entity-dir/EntitySerializer';

export class EdwSnapshotSerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['name', 'name'],
        ['dateCreation', 'date_creation'],
    ]);

    fromJson(json: any): EdwSnapshot {
        const snapshot = new EdwSnapshot();
        this.mapping.forEach((value, key, map) => {
            snapshot[key] = json[value];
        });
        return snapshot;
    }

    toApi(snapshot: EdwSnapshot): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = snapshot[key];
        });
        return obj;
    }

}
