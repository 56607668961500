import {Injectable} from '@angular/core';
import {EntityService} from '../entity.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Schedule, ScheduleSerializer} from '@edward-software/edw-fwk-angular-lib/models';

@Injectable({
    providedIn: 'root'
})
export class EdwScheduleService extends EntityService<Schedule> {


    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiScheduleUrl,
            'schedules',
            new ScheduleSerializer()
        );
    }

    readStructure(scheduleId: string): Observable<Schedule> {
        return this.http
            .get(`${this.url}/${this.resource}/${scheduleId}/structure`)
            .pipe(map(
                resp => {
                    return this.serializer.fromJson(resp) as Schedule;
                }
            ));
    }
}
