import {EdwChanges} from './EdwChanges';
import {EntitySerializer} from '../entity-dir/EntitySerializer';

export class EdwChangesSerializer implements EntitySerializer {

    public mapping = new Map([
        ['userId', 'userId'],
        ['date', 'date'],
        ['objectId', 'objectId'],
        ['field', 'field'],
        ['value', 'value']
    ]);

    fromJson(json: any): EdwChanges {
        const changes = new EdwChanges();
        this.mapping.forEach((value, key, map) => {
            changes[key] = json[value];
        });
        return changes;
    }

    toApi(changes: EdwChanges): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = changes[key];
        });
        return obj;
    }

}
