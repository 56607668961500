import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {EdwCustomPpmProject} from './CustomPpmProject';

export class EdwCustomPpmProjectSerializer implements EntitySerializer {

    public mapping = new Map([
        ['attachedFileName', 'attached_file_name'],
        ['portfolio', 'portfolio'],
        ['project', 'project'],
        ['projectExport', 'project_export'],
    ]);

    fromJson(json: any): EdwCustomPpmProject {
        const edwCustomPpmProject = new EdwCustomPpmProject();
        this.mapping.forEach((value, key, map) => {
            edwCustomPpmProject[key] = json[value];
        });
        return edwCustomPpmProject;
    }

    toApi(edwCustomPpmProject: EdwCustomPpmProject): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = edwCustomPpmProject[key];
        });
        return obj;
    }
}
