import {Entity} from '../entity-dir/Entity';

export class ScheduleMonth extends Entity {

    private _year: number;
    private _quarter: number;
    private _month: number;
    private _label: string;
    private _labelShort: string;
    private _firstDate: Date;
    private _lastDate: Date;
    private _nbDays: number;


    constructor() {
        super();
    }

    get year(): number {
        return this._year;
    }

    set year(value: number) {
        this._year = value;
    }

    get quarter(): number {
        return this._quarter;
    }

    set quarter(value: number) {
        this._quarter = value;
    }

    get month(): number {
        return this._month;
    }

    set month(value: number) {
        this._month = value;
    }

    get label(): string {
        return this._label;
    }

    set label(value: string) {
        this._label = value;
    }

    get labelShort(): string {
        return this._labelShort;
    }

    set labelShort(value: string) {
        this._labelShort = value;
    }

    get firstDate(): Date {
        return this._firstDate;
    }

    set firstDate(value: Date) {
        this._firstDate = value;
    }

    get lastDate(): Date {
        return this._lastDate;
    }

    set lastDate(value: Date) {
        this._lastDate = value;
    }

    get nbDays(): number {
        return this._nbDays;
    }

    set nbDays(value: number) {
        this._nbDays = value;
    }
}
