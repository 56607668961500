import {Injectable} from '@angular/core';

/**
 * On stocke dans ce service un booleen qui permet de savoir si on peut accéder à la page de refreshToken
 * RefreshToken le passe à false une fois que l'on est passé
 *
 * On stocke également le referrerUrl qui permet de savoir à partir de quelle app on vient pour y retourner
 * Utilisé par exemple si on fait page précédente
 * ex :
 *  On est sur HOME
 *  On navigue vers PPM
 *  On passe par la page refreshToken
 *  Ca met canActivateRefresh à false
 *  On appuie sur "Page précédente"
 *  C'est censé nous ramener sur la page refreshToken
 *  Comme elle a déjà été activée, on redirige vers HOME
 */

@Injectable({
    providedIn: 'root'
})
export class RefreshActivateService {

    canActivateRefresh: boolean;
    referrerUrl: string;

    constructor() {
        this.canActivateRefresh = true;
        this.referrerUrl = JSON.parse(localStorage.getItem('ENV_CONFIG')).frontHomeUrl;
    }
}
