import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {EdwValidationAttachmentFile} from './ValidationAttachmentFile';

export class EdwValidationAttachmentFileSerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['size', 'size'],
        ['name', 'name'],
        ['description', 'description'],
        ['isMain', 'is_main'],
        ['mimeType', 'mime_type'],
        ['url', 'url'],

    ]);


    fromJson(json: any): EdwValidationAttachmentFile {
        const validationAttachmentFile = new EdwValidationAttachmentFile();
        this.mapping.forEach((value, key, map) => {
            validationAttachmentFile[key] = json[value];
        });
        return validationAttachmentFile;
    }

    toApi(validationAttachmentFile: EdwValidationAttachmentFile): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = validationAttachmentFile[key];
        });

        return obj;
    }
}
