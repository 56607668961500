import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {EdwChanges, EdwChangesSerializer} from '@edward-software/edw-fwk-angular-lib/models';
import {PaginationMetaSerializer} from '@edward-software/edw-fwk-angular-lib/models';
import {QueryOption} from '@edward-software/edw-fwk-angular-lib/models';
import {ListResponse} from '@edward-software/edw-fwk-angular-lib/models';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EdwChangesService {

    edwChangesSerializer: EdwChangesSerializer = new EdwChangesSerializer();
    paginationMetaSerializer = new PaginationMetaSerializer();

    constructor(private http: HttpClient) {
    }

    /**
     * Récupérer les changements effectués sur un objet
     * url : api sur laquelle on va chercher les changes (adb, project, schedule...)
     * objectType : CONTACT, PROJET...
     * objectId : uuid de l'objet
     */
    readChanges (url, objectType, objectId, queryOption: QueryOption): Observable<ListResponse<EdwChanges>> {
        return this.http
            .get(`${url}/egd_tools/changes/${objectType}/${objectId}?${queryOption.toQueryString()}`)
            .pipe(map((data: any) => {
                return new ListResponse(
                    data.data.map(item => this.edwChangesSerializer.fromJson(item)),
                    this.paginationMetaSerializer.fromJson(data.meta)
                );
                // return data.data.map(item => this.edwChangesSerializer.fromJson(item));
            }));
    }
}
