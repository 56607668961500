import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {ActivityScheduleInfo} from './ActivityScheduleInfo';

export class ActivityScheduleInfoSerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['name', 'name'],
        ['startDate', 'start_date'],
        ['endDate', 'end_date'],
        ['duration', 'duration'],
        ['description', 'description'],
        ['position', 'position'],
        ['projectId', 'project_id'],
    ]);

    fromJson(json: any): ActivityScheduleInfo {

        const activityScheduleInfo = new ActivityScheduleInfo();
        activityScheduleInfo.activityId = json['activity_id'];
        activityScheduleInfo.diffStartDate = json['diff_start_date'];

        return activityScheduleInfo;
    }

    toApi(activityScheduleInfo: ActivityScheduleInfo): any {
    }

}
