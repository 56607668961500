import {Entity} from '../entity-dir/Entity';
import {Picture} from '../picture-dir/Picture';
import {WorkspaceModule} from '../workspace-module-dir/WorkspaceModule';
import {Organization} from '../organization-dir/Organization';
import {UserWorkspace} from '../user-workspace-dir/UserWorkspace';


export class Workspace extends Entity {

    private _dateCreation: Date;
    private _name: string;
    private _shortName: string;
    private _systemName: string;
    private _description: string;
    private _organization: Organization;
    private _roles: string[];
    private _workspacePicture: Picture;
    private _position: number;
    private _collaboratorsNumber: number;
    private _workspaceModules: WorkspaceModule[];
    private _isFavorite?: boolean;
    private _isHide?: boolean;
    private _userWorkspace?: UserWorkspace[];


    constructor() {
        super();
        this.roles = [];
    }

    get dateCreation(): Date {
        return this._dateCreation;
    }

    set dateCreation(value: Date) {
        this._dateCreation = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get shortName(): string {
        return this._shortName;
    }

    set shortName(value: string) {
        this._shortName = value;
    }

    get systemName(): string {
        return this._systemName;
    }

    set systemName(value: string) {
        this._systemName = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get organization(): Organization {
        return this._organization;
    }

    set organization(value: Organization) {
        this._organization = value;
    }

    get roles(): string[] {
        return this._roles;
    }

    set roles(value: string[]) {
        this._roles = value;
    }

    get workspacePicture(): Picture {
        return this._workspacePicture;
    }

    set workspacePicture(value: Picture) {
        this._workspacePicture = value;
    }

    get position(): number {
        return this._position;
    }

    set position(value: number) {
        this._position = value;
    }

    get collaboratorsNumber(): number {
        return this._collaboratorsNumber;
    }

    set collaboratorsNumber(value: number) {
        this._collaboratorsNumber = value;
    }

    get workspaceModules(): WorkspaceModule[] {
        return this._workspaceModules;
    }

    set workspaceModules(value: WorkspaceModule[]) {
        this._workspaceModules = value;
    }
    get isFavorite(): boolean {
        return this._isFavorite;
    }

    set isFavorite(value: boolean) {
        this._isFavorite = value;
    }

    get isHide(): boolean {
        return this._isHide;
    }

    set isHide(value: boolean) {
        this._isHide = value;
    }

    get userWorkspace(): UserWorkspace[] {
        return this._userWorkspace;
    }

    set userWorkspace(value: UserWorkspace[]) {
        this._userWorkspace = value;
    }

    /**
     * Fonction vérifiant si un module est activé pour ce workspace
     * prend en param le "name --> api (changement 17/09/21)" du module
     *
     * return true si module
     * return false si moduel non activé ou si module non trouvé
     */
    isModuleAvailable(moduleApi: string) {
        let isAvailable = false;
        if (this.workspaceModules && this.workspaceModules.length > 0) {
            this.workspaceModules.forEach(wkModule => {
                    if (wkModule.api === moduleApi && wkModule.availability) {
                        isAvailable = true;
                    }
                }
            );
        }
        return isAvailable;
    }


}
