import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError, timer} from 'rxjs';
import {catchError, mergeMap, retryWhen} from 'rxjs/operators';
import {EdwHttpError} from '@edward-software/edw-fwk-angular-lib/models';
import {Router} from '@angular/router';


export const genericRetryStrategy = (
    {
        maxRetryAttempts = 3,
        scalingDuration = 1000,
        includedErrorMessages = []
    }: {
        maxRetryAttempts?: number;
        scalingDuration?: number;
        includedErrorMessages?: string[];
    } = {}
) => (attempts: Observable<any>) => {
    return attempts.pipe(
        mergeMap((error, i) => {
            const retryAttempt = i + 1;
            /**
             * si on a réalise le nombre max de retry
             * ou si le message d'erreur ne fait pas partie de ceux que l'on souhaite relancer
             */
            const edwErrorMessage = error.error.message;
            if (retryAttempt > maxRetryAttempts || typeof includedErrorMessages.find(e => e === edwErrorMessage) === 'undefined') {
                /**
                 * On retourne l'erreur => direction le catchError()
                 */
                return throwError(error);
            }
            /**
             * Sinon on retourne juste l'observable après un delai défini par le timer
             * On ne throw pas d'error donc on reste dans le retryWhen
             */
            return timer(retryAttempt * scalingDuration);
        })
    );
};

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {


    constructor(public router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            return next.handle(request).pipe(
                retryWhen(genericRetryStrategy(
                    {
                        maxRetryAttempts: 3,
                        scalingDuration: 1000,
                        includedErrorMessages: ['serverLoading']
                    }
                )),
                catchError(err => {
                    const error = EdwHttpError.fromError(err.error);
                    if (request.method === 'GET' && error.code === 401 && (error.message === 'userNotAuthorized' || error.message === 'unauthorized')) {
                        this.router.navigate(['error'], {queryParams: {e: 'userNotAuthorized', callbackUrl: null}});
                        const newError = new EdwHttpError();
                        return throwError(newError);
                    }
                    return throwError(error);
                }));

    }


}
