import {Entity} from '../entity-dir/Entity';
import {EdwContact} from '../contact-dir/EdwContact';

export class EdwContactValidationStep extends Entity {
    private _contact: EdwContact;
    private _contactInfos: EdwContact;
    private _priority: number;
    private _sendingDate: Date;
    private _subscribe: boolean;

    constructor() {
        super();
    }


    get contact(): EdwContact {
        return this._contact;
    }

    set contact(value: EdwContact) {
        this._contact = value;
    }

    get contactInfos(): EdwContact {
        return this._contactInfos;
    }

    set contactInfos(value: EdwContact) {
        this._contactInfos = value;
    }

    get priority(): number {
        return this._priority;
    }

    set priority(value: number) {
        this._priority = value;
    }

    get sendingDate(): Date {
        return this._sendingDate;
    }

    set sendingDate(value: Date) {
        this._sendingDate = value;
    }

    get subscribe(): boolean {
        return this._subscribe;
    }

    set subscribe(value: boolean) {
        this._subscribe = value;
    }
}
