import {Entity} from '../entity-dir/Entity';

export class Activity extends Entity {
    private _name: string;
    private _startDate: Date;
    private _endDate: Date;
    private _duration: number;
    private _description: string;
    private _position: number;
    private _projectId: string;
    private _scheduleId: string;


    constructor() {
        super();
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get startDate(): Date {
        return this._startDate;
    }

    set startDate(value: Date) {
        this._startDate = value;
    }

    get endDate(): Date {
        return this._endDate;
    }

    set endDate(value: Date) {
        this._endDate = value;
    }

    get duration(): number {
        return this._duration;
    }

    set duration(value: number) {
        this._duration = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get position(): number {
        return this._position;
    }

    set position(value: number) {
        this._position = value;
    }


    get projectId(): string {
        return this._projectId;
    }

    set projectId(value: string) {
        this._projectId = value;
    }

    get scheduleId(): string {
        return this._scheduleId;
    }

    set scheduleId(value: string) {
        this._scheduleId = value;
    }
}
