import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Entity} from '@edward-software/edw-fwk-angular-lib/models';

@Injectable({
    providedIn: 'root'
})
export class DragAndDropService {

    public draggedType: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public draggedItem: BehaviorSubject<Entity> = new BehaviorSubject<Entity>(null);

    constructor() {
    }

    onDragItem(type: string, data: Entity) {
        this.draggedItem.next(data);
        this.draggedType.next(type);
    }

    resetDragItem() {
        this.draggedType.next(null);
        this.draggedItem.next(null);
    }
}
