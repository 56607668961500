import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {MilestoneScheduleInfo} from './MilestoneScheduleInfo';

export class MilestoneScheduleInfoSerializer implements EntitySerializer {

    public mapping = new Map([
        ['milestoneId', 'milestone_id'],
        ['diffStartDate', 'diff_start_date'],
        ['stepDate', 'step_date'],
        ['position', 'position'],
        ['projectId', 'project_id'],
    ]);

    fromJson(json: any): MilestoneScheduleInfo {

        const milestoneScheduleInfo = new MilestoneScheduleInfo();
        this.mapping.forEach((value, key, map) => {
            milestoneScheduleInfo[key] = json[value];
        });
        return milestoneScheduleInfo;
    }

    toApi(milestoneScheduleInfo: MilestoneScheduleInfo): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = milestoneScheduleInfo[key];
        });
        return obj;
    }

}
