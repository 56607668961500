import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EnvConfig, ListResponse, QueryOption, User, UserSerializer} from '@edward-software/edw-fwk-angular-lib/models';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EntityService} from '../entity.service';

@Injectable({
    providedIn: 'root'
})
export class EdwUserService extends EntityService<User> {

    envConfig: EnvConfig;

    constructor(protected http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiAuthUrl,
            'users',
            new UserSerializer()
        );

        this.envConfig = JSON.parse(localStorage.getItem('ENV_CONFIG'));
    }

    /***********************************************************************
     * GET
     ***********************************************************************/

    /**
     * Récupération de l'utilisateur connecté avec son token
     */
    getMe(): Observable<User> {
        return this.http.get(`${this.url}/me`)
            .pipe(map(user => this.serializer.fromJson(user) as User));
    }

    /**
     * Appel API pour récupérer le token d'autologin qui servira à rediriger vers DCM V0
     */
    readV0AutologToken(): Observable<any> {
        return this.http.get(`${this.url}/me/v0_autolog_token`)
            .pipe(map((data: any) => {
                    return data.token;
                }
            ));
    }

    /**
     * Récupère la liste des utilisateurs d'un Workspace
     */
    listFromWorkspace(workspaceId: string, queryOption: QueryOption) {
        return this.http.get(`${this.url}/workspaces/${workspaceId}/${this.resource}?${queryOption.toQueryString()}`)
            .pipe(map((data: any) => {
                    return new ListResponse(
                        this.convertData(data.data),
                        this.paginationMetaSerializer.fromJson(data.meta)
                    );
                }
            ));
    }

    /**
     * Récupère la liste des utilisateurs d'une Organization
     */
    listFromOrganization(organizationId: string, queryOption: QueryOption) {
        return this.http.get(`${this.url}/organizations/${organizationId}/${this.resource}?${queryOption.toQueryString()}`)
            .pipe(map((data: any) => {
                    return new ListResponse(
                        this.convertData(data.data),
                        this.paginationMetaSerializer.fromJson(data.meta)
                    );
                }
            ));
    }

    /***********************************************************************
     * PUT
     ***********************************************************************/

    /**
     * Edition de l'avatar d'un utilisateur
     *  envoie le fichier en base 64
     */
    public editAvatar(userId: string, file: string) {
        return this.http.post(
            `${this.url}/${this.resource}/${userId}/avatar`,
            {
                avatar_file: file,
                encodage: 'b64'
            },
            this.options
        )
            .pipe(map(resp => resp));
    }


    /**
     * Invite un email à rejoindre un workspace
     */
    addUserByEmailFromWorkspace(workspaceId: string, email: string, role: string): Observable<User> {
        const invitationCallbackUrl = `${this.envConfig.frontHomeUrl}/invitation?email=[[email]]&token=[[token]]&returnMsg=[[returnMsg]]`;
        return this.http.post(`${this.url}/workspaces/${workspaceId}/users`,
            {
                email: email,
                roles: role,
                invitation_callback_url: invitationCallbackUrl
            },
            this.options)
            .pipe(map(
                user => {
                    return this.serializer.fromJson(user) as User;
                }
            ));
    }
}
