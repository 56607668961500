import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {EdwPublicValidation} from './PublicValidation';
import {EdwValidation} from './Validation';
import {EdwValidationSerializer} from './ValidationSerializer';
import {EdwWorkflowSerializer} from './WorkflowSerializer';
import {EdwValidationStepSerializer} from './ValidationStepSerializer';
import {EdwValidationStep} from './ValidationStep';

export class EdwPublicValidationSerializer implements EntitySerializer {

    private workflowSerializer = new EdwWorkflowSerializer();
    private validationStepSerializer = new EdwValidationStepSerializer();
    private validationSerializer = new EdwValidationSerializer();

    public mapping = new Map();

    fromJson(json: any): EdwPublicValidation {
        const publicValidation = new EdwPublicValidation();
        this.mapping.forEach((value, key, map) => {
            publicValidation[key] = json[value];
        });
        /**
         * Création d'un workflow
         */
        if (json['workflow'] !== null && typeof (json['workflow']) !== 'undefined') {
            publicValidation.workflow = this.workflowSerializer.fromJson(json['workflow']);
        }

        /**
         * Création des validationSteps
         */
        const validationSteps = json['validation_steps'];
        if (validationSteps && validationSteps instanceof Array) {
            const vSteps: EdwValidationStep[] = [];
            validationSteps.forEach(validationStep => {
                vSteps.push(this.validationStepSerializer.fromJson(validationStep));
            });
            publicValidation.validationSteps = vSteps;
        }

        /**
         * Création de la Validation
         */
        if (json['validation'] !== null && typeof (json['validation']) !== 'undefined') {
            publicValidation.validation = this.validationSerializer.fromJson(json['validation']);
        }

        return publicValidation;
    }

    toApi(validation: EdwValidation): any {
        return {};
    }
}
