import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {EdwValidation} from './Validation';
import {EdwValidationStep} from './ValidationStep';
import {EdwWorkflowSerializer} from './WorkflowSerializer';
import {EdwValidationStepSerializer} from './ValidationStepSerializer';
import {EdwValidationCustomSerializer} from './ValidationCustomSerializer';

export class EdwValidationSerializer implements EntitySerializer {

    private workflowSerializer = new EdwWorkflowSerializer();
    private validationStepSerializer = new EdwValidationStepSerializer();
    private validationCustomSerializer = new EdwValidationCustomSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['dateCreation', 'date_creation'],
        ['dateUpdate', 'date_update'],
        ['contactSenderId', 'contact_sender_id'],
        ['contactSenderSubscribe', 'contact_sender_subscribe'],
        ['contactSenderInfos', 'contact_sender_infos'],
        ['type', 'type'],
        ['locale', 'locale'],
        ['status', 'status'],
        ['launchDate', 'launch_date'],
        ['endDate', 'end_date'],
        ['name', 'name'],
        ['data', 'data']
    ]);


    fromJson(json: any): EdwValidation {
        const validation = new EdwValidation();
        this.mapping.forEach((value, key, map) => {
            validation[key] = json[value];
        });

        /**
         * Création d'un workflow
         */
        if (json['workflow'] !== null && typeof (json['workflow']) !== 'undefined') {
            validation.workflow = this.workflowSerializer.fromJson(json['workflow']);
        }

        /**
         * Création du ContactSender
         * Pas de sérialization car le backend nous le renvoie déjà en camelCase
         */
        if (json['contact_sender'] !== null && typeof (json['contact_sender']) !== 'undefined') {
            validation.contactSender = json['contact_sender'];
        }

        /**
         * Création des validationSteps
         */
        const validationSteps = json['validation_steps'];
        if (validationSteps && validationSteps instanceof Array) {
            const vSteps: EdwValidationStep[] = [];
            validationSteps.forEach(vStep => {
                vSteps.push(this.validationStepSerializer.fromJson(vStep));
            });
            validation.validationSteps = vSteps;
        }

        /**
         * Création de la ValidationCustom
         */
        if (json['custom'] !== null && typeof (json['custom']) !== 'undefined') {
            validation.custom = this.validationCustomSerializer.fromJson(json['custom']);
        }

        return validation;
    }

    toApi(validation: EdwValidation): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = validation[key];
        });

        return obj;
    }
}
