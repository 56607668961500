import {EdwContactCompany} from './EdwContactCompany';
import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {EdwCompanySerializer} from '../company-dir/EdwCompanySerializer';


export class EdwContactCompanySerializer implements EntitySerializer {

    private companySerializer = new EdwCompanySerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['comment', 'comment'],
        ['dateOfEntry', 'date_of_entry'],
        ['department', 'department'],
        ['isMain', 'is_main'],
        ['jobTitle', 'job_title'],

    ]);

    fromJson(json: any): EdwContactCompany {
        const contactCompany = new EdwContactCompany();
        this.mapping.forEach((value, key, map) => {
            contactCompany[key] = json[value];
        });

        /**
         * Création de la company
         */
        if (json['company'] !== null && typeof (json['company']) !== 'undefined') {
            contactCompany.company = this.companySerializer.fromJson(json['company']);
        }


        return contactCompany;
    }

    toApi(contactCompany: EdwContactCompany): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = contactCompany[key];
        });
        return obj;
    }

}
