import {Entity} from '../entity-dir/Entity';

export class EdwFeature extends Entity {
    private _text: string;
    private _type: string;

    get text(): string {
        return this._text;
    }

    set text(value: string) {
        this._text = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }
}
