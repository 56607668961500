import {Injectable} from '@angular/core';
import {EntityService} from '../entity.service';
import {HttpClient} from '@angular/common/http';
import {EdwFile, EdwFileSerializer} from '@edward-software/edw-fwk-angular-lib/models';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EdwFileService extends EntityService<EdwFile> {

    constructor(protected http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiFileUrl,
            'files',
            new EdwFileSerializer()
        );
    }


    /**
     * Récupération
     */
    download(file: EdwFile) {
        return this.http.get(`${this.url}/${this.resource}/${file.id}?download=1`, {responseType: 'arraybuffer'})
            .pipe(map(res => {
                const binaryData = [];
                binaryData.push(res);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: file.fileItem.mimeType}));
                downloadLink.setAttribute('download', file.name);
                document.body.appendChild(downloadLink);
                downloadLink.click();
            }));
    }
}
