export class EdwButtonSettings {
    label: string;
    className: string;
    icon: string;
    routerLink: any;

    constructor(label: string, className: string, icon: string, routerLink: any = null) {
        this.label = label;
        this.className = className;
        this.icon = icon;
        this.routerLink = routerLink;
    }
}
