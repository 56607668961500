import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {RefreshActivateService} from '../services/auth/refresh-activate.service';
import {EnvConfig} from '@edward-software/edw-fwk-angular-lib/models';
import {EdwToolsNavigatorService} from '../services/tools/edw-tools-navigator.service';

/**
 * Guard d'activation de
 */
@Injectable({
    providedIn: 'root'
})
export class RefreshGuard implements CanActivate {

    envConfig: EnvConfig;

    constructor(private refreshActivateService: RefreshActivateService,
                private navigatorService: EdwToolsNavigatorService) {

        this.envConfig = JSON.parse(localStorage.getItem('ENV_CONFIG'));
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.refreshActivateService.canActivateRefresh) {
            return true;
        } else {
            const referrerUrl = this.refreshActivateService.referrerUrl;
            if (referrerUrl) {
                if (referrerUrl.includes(this.envConfig.frontPpmUrl)) {
                    this.navigatorService.navigateToPpm();
                } else if (referrerUrl.includes(this.envConfig.frontAdbUrl)) {
                    this.navigatorService.navigateToAdb();
                } else if (referrerUrl.includes(this.envConfig.frontWorkflowUrl)) {
                    this.navigatorService.navigateToWorkflow();
                } else if (referrerUrl.includes(this.envConfig.frontSocialUrl)) {
                    this.navigatorService.navigateToSocial();
                } else {
                    this.navigatorService.navigateToHome();
                }
            } else {
                this.navigatorService.navigateToHome();
            }
        }
    }
}
