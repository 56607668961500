import {Workspace} from './Workspace';
import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {PictureSerializer} from '../picture-dir/PictureSerializer';
import {WorkspaceModuleSerializer} from '../workspace-module-dir/WorkspaceModuleSerializer';
import {WorkspaceModule} from '../workspace-module-dir/WorkspaceModule';
import {UserWorkspace} from '../user-workspace-dir/UserWorkspace';
import {UserWorkspaceSerializer} from '../user-workspace-dir/UserWorkspaceSerializer';

export class WorkspaceSerializer implements EntitySerializer {

    private pictureSerializer = new PictureSerializer();
    private workspaceModuleSerializer = new WorkspaceModuleSerializer();
    private userWorkspaceSerializer = new UserWorkspaceSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['dateCreation', 'date_creation'],
        ['name', 'name'],
        ['shortName', 'short_name'],
        ['systemName', 'system_name'],
        ['description', 'description'],
        ['organizationId', 'organization_id'],
        ['workspacePicture', 'workspace_picture'],
        ['organization', 'organization'],
        ['workspaceModules', 'workspace_modules'],
        ['userWorkspaces', 'user_workspaces']
    ]);

    fromJson(json: any): Workspace {
        const workspace = new Workspace();
        this.mapping.forEach((value, key, map) => {
            workspace[key] = json[value];
        });

        // /**
        //  * Création de l'Organization
        //  */
        // if (json['organization'] !== null && typeof (json['organization']) !== 'undefined') {
        //     workspace.organization = this.organizationSerializer.fromJson(json['organization']);
        // }

        /**
         * Création de la picture
         */
        if (json['workspace_picture'] !== null && typeof (json['workspace_picture']) !== 'undefined') {
            workspace.workspacePicture = this.pictureSerializer.fromJson(json['workspace_picture']);
        }

        /**
         * Création des roles
         */
        const roles = json['roles'];
        if (roles && roles instanceof Array) {
            workspace.roles = roles;
        }

        /**
         * Création des modules
         */
        const wksModule = json['workspace_modules'];
        if (wksModule && wksModule instanceof Array) {
            const wModules: WorkspaceModule[] = [];
            wksModule.forEach(module => {
                wModules.push(this.workspaceModuleSerializer.fromJson(module));
            });
            workspace.workspaceModules = wModules;
        }
        /**
         * Création des workspace users
         */
        const userWorkspaces = json['user_workspaces'];
        if (userWorkspaces && userWorkspaces instanceof Array) {
            const users: UserWorkspace[] = [];
            userWorkspaces.forEach(user => {
                users.push(this.userWorkspaceSerializer.fromJson(user));
            });
            workspace.userWorkspace = users;
        }
        return workspace;
    }

    toApi(workspace: Workspace): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = workspace[key];
        });

        if (workspace.organization) {
            obj['organization_id'] = workspace.organization.id;
        }
        return obj;
    }

}
