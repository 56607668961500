import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {PaginationMeta} from './PaginationMeta';

export class PaginationMetaSerializer implements EntitySerializer {

    public mapping = new Map([
        ['limit', 'limit'],
        ['currentItems', 'current_items'],
        ['totalItems', 'total_items'],
        ['offset', 'offset'],
        ['page', 'page'],
        ['totalPages', 'total_pages'],
        ['previousPage', 'previous_page'],
        ['nextPage', 'next_page'],

    ]);


    fromJson(json: any): PaginationMeta {
        const paginationMeta = new PaginationMeta();
        this.mapping.forEach((value, key, map) => {
            paginationMeta[key] = json[value];
        });
        return paginationMeta;
    }

    toApi(paginationMeta: PaginationMeta): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = paginationMeta[key];
        });
        return obj;
    }
}
