export class EdwError {
    private _message?: string;
    private _property?: string;
    private _system_message: string;

    constructor() {
        this.message = null;
        this.system_message = null;
        this.property = null;
    }

    get message(): string {
        return this._message;
    }

    set message(value: string) {
        this._message = value;
    }

    get property(): string {
        return this._property;
    }

    set property(value: string) {
        this._property = value;
    }

    get system_message(): string {
        return this._system_message;
    }

    set system_message(value: string) {
        this._system_message = value;
    }

    static fromError(err: any) {
        const edwError = new EdwError();
        for (const propEdw in edwError) {
            if (edwError.hasOwnProperty(propEdw)) {
                const formatProp = propEdw.substring(1);
                edwError[propEdw] = err[formatProp];
            }
        }
        return edwError;
    }
}
