import {EdwProject} from './EdwProject';
import {EntitySerializer} from '../entity-dir/EntitySerializer';

export class EdwProjectSerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['name', 'name'],
        ['shortName', 'short_name'],
        ['reference', 'reference'],
        ['comment', 'comment'],
        ['description', 'description'],
        ['type', 'type'],
        ['parent', 'parent'],
        ['roles', 'roles'],
    ]);

    fromJson(json: any): EdwProject {
        const project = new EdwProject();
        this.mapping.forEach((value, key, map) => {
            project[key] = json[value];
        });
        return project;
    }

    toApi(project: EdwProject): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = project[key];
        });
        return obj;
    }
}
