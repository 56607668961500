import {Entity} from '../entity-dir/Entity';

export class EdwFileItem extends Entity {

    private _mimeType: string;
    private _size: number;
    private _versionNumber: number;

    constructor() {
        super();
    }

    get mimeType(): string {
        return this._mimeType;
    }

    set mimeType(value: string) {
        this._mimeType = value;
    }

    get size(): number {
        return this._size;
    }

    set size(value: number) {
        this._size = value;
    }

    get versionNumber(): number {
        return this._versionNumber;
    }

    set versionNumber(value: number) {
        this._versionNumber = value;
    }
}
