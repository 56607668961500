import {Entity} from '../entity-dir/Entity';
import {EdwFeature} from '../release-feature-dir/EdwFeature';

export class EdwContent extends Entity {
    private _name: string;
    private _nickName: string;
    private _features?: EdwFeature[];

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get nickName(): string {
        return this._nickName;
    }

    set nickName(value: string) {
        this._nickName = value;
    }
    get feature(): EdwFeature[] {
        return this._features;
    }

    set feature(value: EdwFeature[]) {
        this._features = value;
    }
}
