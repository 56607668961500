import {Injectable} from '@angular/core';
import {EntityService} from '../entity.service';
import {EdwValidation, EdwValidationSerializer, QueryOption} from '@edward-software/edw-fwk-angular-lib/models';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EdwValidationService extends EntityService<EdwValidation> {

    constructor(protected http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiWorkflowUrl,
            'validations',
            new EdwValidationSerializer()
        );
    }

    launchValidation(workflowId: string, data) {
        return this.http.post(`${this.url}/workflows/${workflowId}/validations`,
            data,
            this.options
        )
            .pipe(map(
                resp => resp
            ));
    }
    updateValidationStatus(validationId: string, status: string) {
        return this.http.put(`${this.url}/${this.resource}/${validationId}/status`,
            {
                status: status
            },
            this.options
        )
            .pipe(map(
                resp => resp
            ));
    }

    listFromWorkspace(queryOption: QueryOption) {
        return this.http.get(`${this.url}/${this.resource}?get_nb_validations=1&get_steps=1&get_contact_steps=1&${queryOption.toQueryString()}`)
            .pipe(map((data: any) => {
                return this.convertData(data.data);
            }));
    }
}
