export class Order {
    private _field: string;
    private _order: number;


    constructor(field: string, order: number) {
        this._field = field;
        this._order = order;
    }

    get field(): string {
        return this._field;
    }

    set field(value: string) {
        this._field = value;
    }

    get order(): number {
        return this._order;
    }

    set order(value: number) {
        this._order = value;
    }
}
