import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {ShortcutItem} from './ShortcutItem';

export class ShortcutItemSerializer implements EntitySerializer {


    public mapping = new Map([
        ['id', 'id'],
        ['userId', 'user_id'],
        ['workspaceId', 'workspace_id'],
        ['url', 'url'],
        ['position', 'position'],
        ['name', 'name'],
        ['shortName', 'short_name'],
        ['appFront', 'app_front'],
        ['picto', 'picto']
    ]);

    fromJson(json: any): ShortcutItem {
        const shortcutItem = new ShortcutItem();
        this.mapping.forEach((value, key, map) => {
            shortcutItem[key] = json[value];
        });
        return shortcutItem;
    }

    toApi(shortcutItem: ShortcutItem): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = shortcutItem[key];
        });
        return obj;
    }

}
