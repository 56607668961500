import {Entity} from '../entity-dir/Entity';

export class EdwValidationAttachmentFile extends Entity {

    private _size: number;
    private _name: string;
    private _description: string;
    private _isMain: boolean;
    private _mimeType: string;
    private _url: string;
    private _visible?: boolean;
    private _fileIcon: string;
    private _sizeConvert: string;


    constructor() {
        super();
    }

    get size(): number {
        return this._size;
    }

    set size(value: number) {
        this._size = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get isMain(): boolean {
        return this._isMain;
    }

    set isMain(value: boolean) {
        this._isMain = value;
    }

    get mimeType(): string {
        return this._mimeType;
    }

    set mimeType(value: string) {
        this._mimeType = value;
    }

    get url(): string {
        return this._url;
    }

    set url(value: string) {
        this._url = value;
    }

    get visible(): boolean {
        return this._visible;
    }

    set visible(value: boolean) {
        this._visible = value;
    }

    get fileIcon(): string {
        return this._fileIcon;
    }

    set fileIcon(value: string) {
        this._fileIcon = value;
    }

    get sizeConvert(): string {
        return this._sizeConvert;
    }

    set sizeConvert(value: string) {
        this._sizeConvert = value;
    }
}
