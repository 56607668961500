import {Entity} from '../entity-dir/Entity';
import {EdwValidationAttachmentFile} from './ValidationAttachmentFile';
import {EdwCustomProject} from './CustomProject';


export class EdwValidationCustom extends Entity {

    private _validationAttachmentFiles: EdwValidationAttachmentFile[];
    private _customProject?: EdwCustomProject;
    private _zoneId?: string;
    private _content?: any;
    private _attachedFiles?: any;
    private _data?: any;

    constructor() {
        super();
        this.validationAttachmentFiles = [];
    }
    get validationAttachmentFiles(): EdwValidationAttachmentFile[] {
        return this._validationAttachmentFiles;
    }

    set validationAttachmentFiles(value: EdwValidationAttachmentFile[]) {
        this._validationAttachmentFiles = value;
    }

    get customProject(): EdwCustomProject {
        return this._customProject;
    }

    set customProject(value: EdwCustomProject) {
        this._customProject = value;
    }
    get zoneId(): string {
        return this._zoneId;
    }

    set zoneId(value: string) {
        this._zoneId = value;
    }
    get content(): any {
        return this._content;
    }

    set content(value: any) {
        this._content = value;
    }
    get attachedFiles(): any {
        return this._attachedFiles;
    }

    set attachedFiles(value: any) {
        this._attachedFiles = value;
    }
    get data(): any {
        return this._data;
    }

    set data(value: any) {
        this._data = value;
    }
}
