import {InjectionToken} from '@angular/core';

export const ENVIRONMENT_PARAMETER = new InjectionToken<EnvironmentParameter>('ENVIRONMENT_PARAMETER');

export interface EnvironmentParameter {
    edsStaticUrl: string;
    environment: string;
    app: string;
    production: boolean;
}
