import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'ng-template[edwTemplate]'
})
export class EdwTemplateDirective {
    @Input('edwTemplate') name: string;

    constructor(public template: TemplateRef<any>) {
    }

    getType() {
        return this.name;
    }
}
