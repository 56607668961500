import {Entity} from '../entity-dir/Entity';
import {GlobalSearchItem} from './GlobalSearchItem';

export abstract class GlobalSearch extends Entity {
    private _globalSearchItems: GlobalSearchItem[];

    constructor() {
        super();
    }

    get globalSearchItems(): GlobalSearchItem[] {
        return this._globalSearchItems;
    }

    set globalSearchItems(value: GlobalSearchItem[]) {
        this._globalSearchItems = value;
    }
}
