import {Organization} from './Organization';
import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {PictureSerializer} from '../picture-dir/PictureSerializer';
import {WorkspaceSerializer} from '../workspace-dir/WorkspaceSerializer';
import {Workspace} from '../workspace-dir/Workspace';


export class OrganizationSerializer implements EntitySerializer {

    private pictureSerializer = new PictureSerializer();
    private workspaceSerializer = new WorkspaceSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['name', 'name'],
        ['color', 'color'],
        ['locality', 'locality'],
        ['addressLine1', 'address_line1'],
        ['addressLine2', 'address_line2'],
        ['addressLine3', 'address_line3'],
        ['contactName', 'contact_name'],
        ['country', 'country'],
        ['dateCreation', 'date_creation'],
        ['dateUpdate', 'date_update'],
        ['deleted', 'deleted'],
        ['postalCode', 'postal_code'],
        ['position', 'position'],
        ['region', 'region'],
        ['roles', 'roles'],
        ['customSettings', 'custom_settings'],
        ['userOrganizations', 'user_organizations'],
        ['organizationPicture', 'organization_picture']
    ]);

    fromJson(json: any): Organization {
        const organization = new Organization();
        this.mapping.forEach((value, key, map) => {
            organization[key] = json[value];
        });

        /**
         * Création des roles
         */
        const roles = json['roles'];
        if (roles && roles instanceof Array) {
            organization.roles = roles;
        }

        /**
         * Création de la picture
         */
        if (json['organization_picture'] !== null && typeof (json['organization_picture']) !== 'undefined') {
            organization.organizationPicture = this.pictureSerializer.fromJson(json['organization_picture']);
        }

        /**
         * Création des Workspaces
         */
        const workspace = json['workspaces'];
        if (workspace && workspace instanceof Array) {
            const cWorkspaces: Workspace[] = [];
            workspace.forEach(wks => {
                cWorkspaces.push(this.workspaceSerializer.fromJson(wks));
            });
            organization.workspaces = cWorkspaces;
        }

        return organization;
    }

    toApi(organization: Organization): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = organization[key];
        });

        return obj;
    }

}
