import {Entity} from '../entity-dir/Entity';

export class MilestoneScheduleInfo extends Entity {
    private _milestoneId: string;
    private _diffStartDate: number;


    constructor() {
        super();
    }

    get milestoneId(): string {
        return this._milestoneId;
    }

    set milestoneId(value: string) {
        this._milestoneId = value;
    }

    get diffStartDate(): number {
        return this._diffStartDate;
    }

    set diffStartDate(value: number) {
        this._diffStartDate = value;
    }
}
