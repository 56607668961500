import {Entity} from '../entity-dir/Entity';
import {EdwContactStep} from './ContactStep';

export class EdwWorkflowStep extends Entity {

    private _contactSteps: EdwContactStep[];
    private _description: string;
    private _label: string;
    private _position: number;
    private _stepDuration: number;
    private _isSettingsShown = false;
    responsible: EdwContactStep;
    substitute: EdwContactStep;

    constructor() {
        super();
        this.contactSteps = [];
    }

    get contactSteps(): EdwContactStep[] {
        return this._contactSteps;
    }

    set contactSteps(value: EdwContactStep[]) {
        this._contactSteps = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get label(): string {
        return this._label;
    }

    set label(value: string) {
        this._label = value;
    }

    get position(): number {
        return this._position;
    }

    set position(value: number) {
        this._position = value;
    }

    get stepDuration(): number {
        return this._stepDuration;
    }

    set stepDuration(value: number) {
        this._stepDuration = value;
    }


    get isSettingsShown(): boolean {
        return this._isSettingsShown;
    }

    set isSettingsShown(value: boolean) {
        this._isSettingsShown = value;
    }
}
