import {WorkspaceModule} from './WorkspaceModule';
import {EntitySerializer} from '../entity-dir/EntitySerializer';

export class WorkspaceModuleSerializer implements EntitySerializer {
    public mapping = new Map([

        ['api', 'api'],
        ['endDate', 'end_date'],
        ['startDate', 'start_date']
    ]);

    fromJson(json: any): WorkspaceModule {
        const workspaceModule = new WorkspaceModule();
        this.mapping.forEach((value, key, map) => {
            workspaceModule[key] = json[value];
        });

        /**
         * Date
         */
        const currentTime = new Date();

        /**
         * On verifie la disponibilitie du module
         */
      /*  if (typeof workspaceModule.startDate === 'string') {
            workspaceModule.startDate = new Date(workspaceModule.startDate);
        }
        if (typeof workspaceModule.endDate === 'string') {
            workspaceModule.endDate = new Date(workspaceModule.endDate);
        }*/
        if (workspaceModule.startDate && workspaceModule.endDate && currentTime >= workspaceModule.startDate && currentTime <= workspaceModule.endDate) {
            workspaceModule.availability = true;
        } else if (workspaceModule.startDate && !workspaceModule.endDate && currentTime >= workspaceModule.startDate) {
            workspaceModule.availability = true;
        } else {
            workspaceModule.availability = false;
        }
        return workspaceModule;
    }



    toApi(workspaceModule: WorkspaceModule): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = workspaceModule[key];
        });

        return obj;
    }

}
