import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EntityService} from '../entity.service';
import {ENVIRONMENT_PARAMETER, Milestone, MilestoneSerializer} from '@edward-software/edw-fwk-angular-lib/models';

@Injectable({
    providedIn: 'root'
})
export class MilestoneService extends EntityService<Milestone> {

    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiScheduleUrl,
            'milestones',
            new MilestoneSerializer()
        );
    }


}
