import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {ScheduleYear} from './ScheduleYear';

export class ScheduleYearSerializer implements EntitySerializer {

    public mapping = new Map([
        ['year', 'year'],
        ['startDate', 'start_date'],
        ['endDate', 'end_date'],
        ['duration', 'duration'],
        ['nbDays', 'nb_days'],
    ]);

    fromJson(json: any): ScheduleYear {
        const scheduleYear = new ScheduleYear();
        this.mapping.forEach((value, key, map) => {
            scheduleYear[key] = json[value];
        });
        return scheduleYear;
    }

    toApi(scheduleYear: ScheduleYear): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = scheduleYear[key];
        });
        return obj;
    }

}
