import {Entity} from '../entity-dir/Entity';


export class GlobalSearchItem extends Entity {

    private _mainInformation: string;
    private _subInformation: string;
    private _type: string;
    constructor(
        id: string,
        mainInformation: string,
        subInformation: string,
        type: string
    ) {
        super();
        this.id = id;
        this._mainInformation = mainInformation;
        this._subInformation = subInformation;
        this._type = type;
    }

    get mainInformation(): string {
        return this._mainInformation;
    }

    set mainInformation(value: string) {
        this._mainInformation = value;
    }

    get subInformation(): string {
        return this._subInformation;
    }

    set subInformation(value: string) {
        this._subInformation = value;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }
}
