import {EdwColumnSettings} from './EdwColumnSettings';
import {MenuItem} from 'primeng/api';
import {EdwVScrollSettings} from './EdwVScrollSettings';

export class EdwTableSettings {
    tableId: string;
    cols: EdwColumnSettings[];
    mapping: Map<string, string>;
    value: any[];
    loading: boolean;
    totalRecords: number;
    rows: number;
    rowPerPage: number[];
    globalFilter: boolean;
    contextMenuItems: MenuItem[];
    buttons: MenuItem[];
    selectionMode: string;
    virtualScrollSettings: EdwVScrollSettings;
    resizableColumns: boolean;
    first: number;

    constructor(tableId: string,
                cols: EdwColumnSettings[],
                mapping: Map<string, string>,
                value: any[],
                loading: boolean,
                selectionMode: string,
                totalRecords: number,
                rows: number,
                rowPerPage: number[],
                globalFilter: boolean,
                virtualScrollSettings: EdwVScrollSettings,
                resizableColumns: boolean,
                contextMenuItems?: MenuItem[],
                buttons?: MenuItem[],
                first?: number) {
        this.tableId = tableId;
        this.cols = cols;
        this.mapping = mapping;
        this.value = value;
        this.loading = loading;
        this.selectionMode = selectionMode;
        this.totalRecords = totalRecords;
        this.rows = rows;
        this.rowPerPage = rowPerPage;
        this.globalFilter = globalFilter;
        this.virtualScrollSettings = virtualScrollSettings;
        this.resizableColumns = resizableColumns;
        this.contextMenuItems = contextMenuItems;
        this.buttons = buttons;
        this.first = first;
    }

    hasContextMenu() {
        return (this.contextMenuItems != null && this.contextMenuItems instanceof Array && this.contextMenuItems.length > 0);
    }

    hasButtons() {
        return (this.buttons != null && this.buttons instanceof Array && this.buttons.length > 0);
    }

    setValue(objs: any[]) {
        this.value = objs;
    }
}

