import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
    selector: '[edwTemplate]',
})
export class TemplateDirective {
    @Input() type: string;
    @Input('edwTemplate') name: string;

    constructor(public template: TemplateRef<any>) {
    }

    getType(): string {
        return this.name;
    }

}
