import {AbstractControl} from '@angular/forms';
import {FormGroup} from '@angular/forms';

export class CustomValidators {
    static emailValidator(control: AbstractControl): { [key: string]: boolean } | null {

        const REGEX = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);


        if (REGEX.test(control.value)) {
            return null;
        }

        return {emailInvalid: true};
    }

    static passwordStrengthValidator(control: AbstractControl): { [key: string]: boolean } | null {
        const REGEX = new RegExp(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/);

        if (REGEX.test(control.value)) {
            return null;
        }

        return {passwordStrengthInvalid: true};
    }
}


/**
 * A la différence la classe CustomValidators qui sont des validateurs qui ne s'appliquent qu'à un seul champ
 * Ici on fait des vérifications sur le formulaire dans sont intégralité
 *
 * Custom validator to check that two fields match
 */
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({mustMatch: true});
        } else {
            matchingControl.setErrors(null);
        }
    };
}
