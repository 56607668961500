import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {Schedule} from './Schedule';
import {StructureSerializer} from './StructureSerializer';

export class ScheduleSerializer implements EntitySerializer {

    private structureSerializer = new StructureSerializer();

    public mapping = new Map([
        ['name', 'name'],
        ['id', 'id']
    ]);

    fromJson(json: any): Schedule {
        const schedule = new Schedule();
        this.mapping.forEach((value, key, map) => {
            schedule[key] = json[value];
        });

        if (json['structure']) {
            schedule.structure = this.structureSerializer.fromJson(json['structure']);
        }

        return schedule;
    }

    toApi(schedule: Schedule): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = schedule[key];
        });
        if (schedule.structure) {
            obj['structure'] = this.structureSerializer.toApi(schedule.structure);
        }
        return obj;
    }

}
