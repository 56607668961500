import {Entity} from '../entity-dir/Entity';

export class EnvConfig extends Entity {

    private _platformName?: string;
    private _platformAbbreviation?: string;
    private _platformDescription?: string;
    private _platformLogo?: string;
    private _platformFavicon?: string;
    private _edsBranding?: boolean;
    private _intercomId?: string;
    private _loginShowLogo?: boolean;
    private _loginShowCgu?: boolean;
    private _loginShowSignup?: boolean;
    private _loginCopyRightContent?: boolean;
    private _loginFooterTemplateContent?: boolean;
    private _enableSso?: boolean;
    private _enableSsoGoogle?: boolean;
    private _enableSsoLinkedin?: boolean;
    private _enableSsoMsoffice?: boolean;
    private _enableSsoApple?: boolean;
    private _menuNavbarShowMyProfil?: boolean;
    private _menuNavbarShowHome?: boolean;
    private _menuNavbarShowNote?: boolean;
    private _menuNavbarShowSupport?: boolean;
    private _menuNavbarShowWorkspace?: boolean;
    private _moduleShowDisabled?: boolean;
    private _moduleShowAdb?: boolean;
    private _moduleShowPmov0?: boolean;
    private _moduleShowPpm?: boolean;
    private _moduleShowSocial?: boolean;
    private _moduleShowWkf?: boolean;
    private _moduleShowNgTools?: boolean;
    private _moduleShowFile?: boolean;
    private _moduleShowTsm?: boolean;
    private _moduleShowMdm?: boolean;
    private _moduleOrder?: string;
    private _monoWorkspaceEnabled?: boolean;
    private _apiAccessUrl?: string;
    private _apiAuthUrl?: string;
    private _apiProjectUrl?: string;
    private _apiScheduleUrl?: string;
    private _apiAdbUrl?: string;
    private _apiTranslationUrl?: string;
    private _apiWorkflowUrl?: string;
    private _apiRobotUrl?: string;
    private _apiTsmUrl?: string;
    private _apiMeetingUrl?: string;
    private _apiGovUrl?: string;
    private _apiIdaUrl?: string;
    private _apiMessageUrl?: string;
    private _apiFileUrl?: string;
    private _apiKbUrl?: string;
    private _apiSocialUrl?: string;
    private _apiPpmUrl?: string;
    private _apiToolsUrl?: string;
    private _apiMdmUrl?: string;
    private _frontHomeUrl?: string;
    private _frontAdbUrl?: string;
    private _frontWorkflowUrl?: string;
    private _frontFileUrl?: string;
    private _frontTsmUrl?: string;
    private _frontPpmUrl?: string;
    private _frontPmoUrl?: string;
    private _frontSocialUrl?: string;
    private _frontNgToolsUrl?: string;
    private _frontMdmUrl?: string;
    private _appName?: string;
    private _appAbbreviation?: string;
    private _appDescription?: string;
    private _appLogo?: string;
    private _appFavicon?: string;
    private _enableMonoWorkspace?: boolean;
    private _cdnUrl?: string;
    private _defaultRoute?: string;
    private _noWorkspaceUrls?: [];
    private _showLaunchpadHeader: boolean;
    private _appTitle: string;


    get platformName(): string {
        return this._platformName;
    }

    set platformName(value: string) {
        this._platformName = value;
    }

    get platformAbbreviation(): string {
        return this._platformAbbreviation;
    }

    set platformAbbreviation(value: string) {
        this._platformAbbreviation = value;
    }

    get platformDescription(): string {
        return this._platformDescription;
    }

    set platformDescription(value: string) {
        this._platformDescription = value;
    }

    get platformLogo(): string {
        return this._platformLogo;
    }

    set platformLogo(value: string) {
        this._platformLogo = value;
    }

    get platformFavicon(): string {
        return this._platformFavicon;
    }

    set platformFavicon(value: string) {
        this._platformFavicon = value;
    }

    get edsBranding(): boolean {
        return this._edsBranding;
    }

    set edsBranding(value: boolean) {
        this._edsBranding = value;
    }

    get intercomId(): string {
        return this._intercomId;
    }

    set intercomId(value: string) {
        this._intercomId = value;
    }

    get loginShowLogo(): boolean {
        return this._loginShowLogo;
    }

    set loginShowLogo(value: boolean) {
        this._loginShowLogo = value;
    }

    get loginShowCgu(): boolean {
        return this._loginShowCgu;
    }

    set loginShowCgu(value: boolean) {
        this._loginShowCgu = value;
    }

    get loginShowSignup(): boolean {
        return this._loginShowSignup;
    }

    set loginShowSignup(value: boolean) {
        this._loginShowSignup = value;
    }

    get loginCopyRightContent(): boolean {
        return this._loginCopyRightContent;
    }

    set loginCopyRightContent(value: boolean) {
        this._loginCopyRightContent = value;
    }

    get loginFooterTemplateContent(): boolean {
        return this._loginFooterTemplateContent;
    }

    set loginFooterTemplateContent(value: boolean) {
        this._loginFooterTemplateContent = value;
    }

    get enableSso(): boolean {
        return this._enableSso;
    }

    set enableSso(value: boolean) {
        this._enableSso = value;
    }

    get enableSsoGoogle(): boolean {
        return this._enableSsoGoogle;
    }

    set enableSsoGoogle(value: boolean) {
        this._enableSsoGoogle = value;
    }

    get enableSsoLinkedin(): boolean {
        return this._enableSsoLinkedin;
    }

    set enableSsoLinkedin(value: boolean) {
        this._enableSsoLinkedin = value;
    }

    get enableSsoMsoffice(): boolean {
        return this._enableSsoMsoffice;
    }

    set enableSsoMsoffice(value: boolean) {
        this._enableSsoMsoffice = value;
    }

    get enableSsoApple(): boolean {
        return this._enableSsoApple;
    }

    set enableSsoApple(value: boolean) {
        this._enableSsoApple = value;
    }

    get menuNavbarShowMyProfil(): boolean {
        return this._menuNavbarShowMyProfil;
    }

    set menuNavbarShowMyProfil(value: boolean) {
        this._menuNavbarShowMyProfil = value;
    }

    get menuNavbarShowHome(): boolean {
        return this._menuNavbarShowHome;
    }

    set menuNavbarShowHome(value: boolean) {
        this._menuNavbarShowHome = value;
    }

    get menuNavbarShowNote(): boolean {
        return this._menuNavbarShowNote;
    }

    set menuNavbarShowNote(value: boolean) {
        this._menuNavbarShowNote = value;
    }

    get menuNavbarShowSupport(): boolean {
        return this._menuNavbarShowSupport;
    }

    set menuNavbarShowSupport(value: boolean) {
        this._menuNavbarShowSupport = value;
    }

    get menuNavbarShowWorkspace(): boolean {
        return this._menuNavbarShowWorkspace;
    }

    set menuNavbarShowWorkspace(value: boolean) {
        this._menuNavbarShowWorkspace = value;
    }

    get moduleShowDisabled(): boolean {
        return this._moduleShowDisabled;
    }

    set moduleShowDisabled(value: boolean) {
        this._moduleShowDisabled = value;
    }

    get moduleShowAdb(): boolean {
        return this._moduleShowAdb;
    }

    set moduleShowAdb(value: boolean) {
        this._moduleShowAdb = value;
    }

    get moduleShowSocial(): boolean {
        return this._moduleShowSocial;
    }

    set moduleShowSocial(value: boolean) {
        this._moduleShowSocial = value;
    }

    get moduleShowWkf(): boolean {
        return this._moduleShowWkf;
    }

    set moduleShowWkf(value: boolean) {
        this._moduleShowWkf = value;
    }

    get moduleShowNgTools(): boolean {
        return this._moduleShowNgTools;
    }

    set moduleShowNgTools(value: boolean) {
        this._moduleShowNgTools = value;
    }

    get moduleOrder(): string {
        return this._moduleOrder;
    }

    set moduleOrder(value: string) {
        this._moduleOrder = value;
    }

    get apiAccessUrl(): string {
        return this._apiAccessUrl;
    }

    set apiAccessUrl(value: string) {
        this._apiAccessUrl = value;
    }

    get apiAuthUrl(): string {
        return this._apiAuthUrl;
    }

    set apiAuthUrl(value: string) {
        this._apiAuthUrl = value;
    }

    get apiProjectUrl(): string {
        return this._apiProjectUrl;
    }

    set apiProjectUrl(value: string) {
        this._apiProjectUrl = value;
    }

    get apiScheduleUrl(): string {
        return this._apiScheduleUrl;
    }

    set apiScheduleUrl(value: string) {
        this._apiScheduleUrl = value;
    }

    get apiAdbUrl(): string {
        return this._apiAdbUrl;
    }

    set apiAdbUrl(value: string) {
        this._apiAdbUrl = value;
    }

    get apiTranslationUrl(): string {
        return this._apiTranslationUrl;
    }

    set apiTranslationUrl(value: string) {
        this._apiTranslationUrl = value;
    }

    get apiWorkflowUrl(): string {
        return this._apiWorkflowUrl;
    }

    set apiWorkflowUrl(value: string) {
        this._apiWorkflowUrl = value;
    }

    get apiRobotUrl(): string {
        return this._apiRobotUrl;
    }

    set apiRobotUrl(value: string) {
        this._apiRobotUrl = value;
    }

    get apiTsmUrl(): string {
        return this._apiTsmUrl;
    }

    set apiTsmUrl(value: string) {
        this._apiTsmUrl = value;
    }

    get apiMeetingUrl(): string {
        return this._apiMeetingUrl;
    }

    set apiMeetingUrl(value: string) {
        this._apiMeetingUrl = value;
    }

    get apiGovUrl(): string {
        return this._apiGovUrl;
    }

    set apiGovUrl(value: string) {
        this._apiGovUrl = value;
    }

    get apiIdaUrl(): string {
        return this._apiIdaUrl;
    }

    set apiIdaUrl(value: string) {
        this._apiIdaUrl = value;
    }

    get apiMessageUrl(): string {
        return this._apiMessageUrl;
    }

    set apiMessageUrl(value: string) {
        this._apiMessageUrl = value;
    }

    get apiFileUrl(): string {
        return this._apiFileUrl;
    }

    set apiFileUrl(value: string) {
        this._apiFileUrl = value;
    }

    get apiKbUrl(): string {
        return this._apiKbUrl;
    }

    set apiKbUrl(value: string) {
        this._apiKbUrl = value;
    }

    get frontHomeUrl(): string {
        return this._frontHomeUrl;
    }

    set frontHomeUrl(value: string) {
        this._frontHomeUrl = value;
    }

    get frontAdbUrl(): string {
        return this._frontAdbUrl;
    }

    set frontAdbUrl(value: string) {
        this._frontAdbUrl = value;
    }

    get frontWorkflowUrl(): string {
        return this._frontWorkflowUrl;
    }

    set frontWorkflowUrl(value: string) {
        this._frontWorkflowUrl = value;
    }

    get frontFileUrl(): string {
        return this._frontFileUrl;
    }

    set frontFileUrl(value: string) {
        this._frontFileUrl = value;
    }

    get frontTsmUrl(): string {
        return this._frontTsmUrl;
    }

    set frontTsmUrl(value: string) {
        this._frontTsmUrl = value;
    }

    get frontSocialUrl(): string {
        return this._frontSocialUrl;
    }

    set frontSocialUrl(value: string) {
        this._frontSocialUrl = value;
    }

    get appName(): string {
        return this._appName;
    }

    set appName(value: string) {
        this._appName = value;
    }

    get appAbbreviation(): string {
        return this._appAbbreviation;
    }

    set appAbbreviation(value: string) {
        this._appAbbreviation = value;
    }

    get appDescription(): string {
        return this._appDescription;
    }

    set appDescription(value: string) {
        this._appDescription = value;
    }

    get appLogo(): string {
        return this._appLogo;
    }

    set appLogo(value: string) {
        this._appLogo = value;
    }

    get appFavicon(): string {
        return this._appFavicon;
    }

    set appFavicon(value: string) {
        this._appFavicon = value;
    }

    get enableMonoWorkspace(): boolean {
        return this._enableMonoWorkspace;
    }

    set enableMonoWorkspace(value: boolean) {
        this._enableMonoWorkspace = value;
    }

    get apiSocialUrl(): string {
        return this._apiSocialUrl;
    }

    set apiSocialUrl(value: string) {
        this._apiSocialUrl = value;
    }

    get moduleShowPmov0(): boolean {
        return this._moduleShowPmov0;
    }

    set moduleShowPmov0(value: boolean) {
        this._moduleShowPmov0 = value;
    }

    get moduleShowPpm(): boolean {
        return this._moduleShowPpm;
    }

    set moduleShowPpm(value: boolean) {
        this._moduleShowPpm = value;
    }

    get frontPpmUrl(): string {
        return this._frontPpmUrl;
    }

    set frontPpmUrl(value: string) {
        this._frontPpmUrl = value;
    }

    get frontPmoUrl(): string {
        return this._frontPmoUrl;
    }

    set frontPmoUrl(value: string) {
        this._frontPmoUrl = value;
    }


    get apiPpmUrl(): string {
        return this._apiPpmUrl;
    }

    set apiPpmUrl(value: string) {
        this._apiPpmUrl = value;
    }

    get frontNgToolsUrl(): string {
        return this._frontNgToolsUrl;
    }

    set frontNgToolsUrl(value: string) {
        this._frontNgToolsUrl = value;
    }

    get cdnUrl(): string {
        return this._cdnUrl;
    }

    set cdnUrl(value: string) {
        this._cdnUrl = value;
    }

    get apiToolsUrl(): string {
        return this._apiToolsUrl;
    }

    set apiToolsUrl(value: string) {
        this._apiToolsUrl = value;
    }

    get moduleShowFile(): boolean {
        return this._moduleShowFile;
    }

    set moduleShowFile(value: boolean) {
        this._moduleShowFile = value;
    }

    get moduleShowTsm(): boolean {
        return this._moduleShowTsm;
    }

    set moduleShowTsm(value: boolean) {
        this._moduleShowTsm = value;
    }

    get moduleShowMdm(): boolean {
        return this._moduleShowMdm;
    }

    set moduleShowMdm(value: boolean) {
        this._moduleShowMdm = value;
    }

    get frontMdmUrl(): string {
        return this._frontMdmUrl;
    }

    set frontMdmUrl(value: string) {
        this._frontMdmUrl = value;
    }

    get monoWorkspaceEnabled(): boolean {
        return this._monoWorkspaceEnabled;
    }

    set monoWorkspaceEnabled(value: boolean) {
        this._monoWorkspaceEnabled = value;
    }

    get defaultRoute(): string {
        return this._defaultRoute;
    }

    set defaultRoute(value: string) {
        this._defaultRoute = value;
    }

    get noWorkspaceUrls(): [] {
        return this._noWorkspaceUrls;
    }

    set noWorkspaceUrls(value: []) {
        this._noWorkspaceUrls = value;
    }

    get apiMdmUrl(): string {
        return this._apiMdmUrl;
    }

    set apiMdmUrl(value: string) {
        this._apiMdmUrl = value;
    }

    get showLaunchpadHeader(): boolean {
        return this._showLaunchpadHeader;
    }

    set showLaunchpadHeader(value: boolean) {
        this._showLaunchpadHeader = value;
    }

    get appTitle(): string {
        return this._appTitle;
    }

    set appTitle(value: string) {
        this._appTitle = value;
    }
}
