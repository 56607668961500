import {EdwFeature} from './EdwFeature';
import {EntitySerializer} from '../entity-dir/EntitySerializer';

export class EdwFeatureSerializer implements EntitySerializer {
    public mapping = new Map([
        ['id', 'id'],
        ['type', 'type'],
        ['text', 'text'],
    ]);

    fromJson(json: any): EdwFeature {
        const feature = new EdwFeature();
        this.mapping.forEach((value, key, map) => {
            feature[key] = json[value];
        });
        return feature;
    }

    toApi(feature: EdwFeature): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = feature[key];
        });
        return obj;
    }

}
