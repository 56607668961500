import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';
import {Router} from '@angular/router';

@Directive({
    selector: '[edwNewTabRouterLink]'
})
export class NewTabRouterLinkDirective {

    @HostListener('click', ['$event'])
    onClick($event) {
        // ctrl+click, cmd+click
        if ($event.ctrlKey || $event.metaKey || $event.wheel) {
            $event.preventDefault();
            $event.stopPropagation();
            window.open(this.hostElement.nativeElement.pathname + this.hostElement.nativeElement.search, '_blank');
        }
    }

    @HostListener('mouseup', ['$event'])
    onMouseUp($event) {
        // middleclick
        if ($event.which === 2) {
            $event.preventDefault();
            $event.stopPropagation();
            window.open(this.hostElement.nativeElement.pathname + this.hostElement.nativeElement.search, '_blank');
        }
    }

    constructor(private router: Router, private hostElement: ElementRef) {
    }
}
