import {EdwContact} from '../contact-dir/EdwContact';
import {Entity} from '../entity-dir/Entity';

export class EdwContactStep extends Entity {
    private _contact: EdwContact;
    private _priority: number;


    constructor() {
        super();
    }

    get contact(): EdwContact {
        return this._contact;
    }

    set contact(value: EdwContact) {
        this._contact = value;
    }

    get priority(): number {
        return this._priority;
    }

    set priority(value: number) {
        this._priority = value;
    }
}
