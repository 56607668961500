import {EntityService} from '../entity.service';
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EdwRelease, EdwReleaseSerializer, ENVIRONMENT_PARAMETER} from '@edward-software/edw-fwk-angular-lib/models';

@Injectable({
    providedIn: 'root'
})
export class EdwReleaseNoteService extends EntityService<EdwRelease> {

    constructor(private http: HttpClient) {

        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiToolsUrl,
            'releases',
            new EdwReleaseSerializer()
        );
    }

    getReleaseNote(): Observable<EdwRelease> {
        return this.http
            .get(`${this.url}/public/${this.resource}?`)
            .pipe(map(resp => this.serializer.fromJson(resp) as EdwRelease));

    }
}
