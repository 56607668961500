import {Workspace} from '../workspace-dir/Workspace';
import {Organization} from '../organization-dir/Organization';
import {Entity} from '../entity-dir/Entity';
import {Picture} from '../picture-dir/Picture';
import {EdwNote} from '../note-dir/EdwNote';
import {EdwPhoneNumber} from '../phone-number-dir/EdwPhoneNumber';

export class User extends Entity {
    private _dateCreation: Date;
    private _dateUpdate: Date;
    private _deleted: Date;
    private _enabled: Date;
    private _lastLogin: Date;
    private _username?: string;
    private _firstName?: string;
    private _lastName?: string;
    private _email?: string;
    private _forceLogin: boolean;
    private _locale?: string;
    private _dateLocale?: string;
    private _timezone?: string;
    private _banned: Date;
    private _suspended: Date;
    private _type: string;
    private _roles: string[];
    private _workspaces: Workspace[];
    private _password: string;
    private _organizations: Organization[];
    private _avatar: Picture;
    private _notes: EdwNote[];
    private _twoFactorProviders?: string[];
    private _phoneNumber?: EdwPhoneNumber;
    private _phoneNumberCountryCode?: string;
    private _showPublicKey?: boolean;


    constructor() {
        super();
        this.workspaces = [];
        this.organizations = [];
    }

    get username(): string {
        return this._username;
    }

    set username(value: string) {
        this._username = value;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value;
    }

    get lastName(): string {
        return this._lastName;
    }

    set lastName(value: string) {
        this._lastName = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get locale(): string {
        return this._locale;
    }

    set locale(value: string) {
        this._locale = value;
    }

    get dateLocale(): string {
        return this._dateLocale;
    }

    set dateLocale(value: string) {
        this._dateLocale = value;
    }

    get timezone(): string {
        return this._timezone;
    }

    set timezone(value: string) {
        this._timezone = value;
    }

    get dateCreation(): Date {
        return this._dateCreation;
    }

    set dateCreation(value: Date) {
        this._dateCreation = value;
    }

    get dateUpdate(): Date {
        return this._dateUpdate;
    }

    set dateUpdate(value: Date) {
        this._dateUpdate = value;
    }

    get deleted(): Date {
        return this._deleted;
    }

    set deleted(value: Date) {
        this._deleted = value;
    }

    get enabled(): Date {
        return this._enabled;
    }

    set enabled(value: Date) {
        this._enabled = value;
    }

    isEnabled(): boolean {
        return this.enabled !== null;
    }

    get lastLogin(): Date {
        return this._lastLogin;
    }

    set lastLogin(value: Date) {
        this._lastLogin = value;
    }

    get forceLogin(): boolean {
        return this._forceLogin;
    }

    set forceLogin(value: boolean) {
        this._forceLogin = value;
    }

    get banned(): Date {
        return this._banned;
    }

    set banned(value: Date) {
        this._banned = value;
    }

    isBanned(): boolean {
        return this.banned !== null;
    }

    get suspended(): Date {
        return this._suspended;
    }

    set suspended(value: Date) {
        this._suspended = value;
    }

    isSuspended(): boolean {
        return this.suspended !== null;
    }

    get type(): string {
        return this._type;
    }

    set type(value: string) {
        this._type = value;
    }

    get roles(): string[] {
        return this._roles;
    }

    set roles(value: string[]) {
        this._roles = value;
    }

    get workspaces(): Workspace[] {
        return this._workspaces;
    }

    set workspaces(value: Workspace[]) {
        this._workspaces = value;
    }


    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = value;
    }

    get organizations(): Organization[] {
        return this._organizations;
    }

    set organizations(value: Organization[]) {
        this._organizations = value;
    }

    get avatar(): Picture {
        return this._avatar;
    }

    set avatar(value: Picture) {
        this._avatar = value;
    }

    toString() {
        return this.firstName + ' ' + this.lastName;
    }

    get notes(): EdwNote[] {
        return this._notes;
    }

    set notes(value: EdwNote[]) {
        this._notes = value;
    }
    get twoFactorProviders(): string[] {
        return this._twoFactorProviders;
    }

    set twoFactorProviders(value: string[]) {
        this._twoFactorProviders = value;
    }
    get phoneNumber(): EdwPhoneNumber {
        return this._phoneNumber;
    }

    set phoneNumber(value: EdwPhoneNumber) {
        this._phoneNumber = value;
    }

    get phoneNumberCountryCode(): string {
        return this._phoneNumberCountryCode;
    }

    set phoneNumberCountryCode(value: string) {
        this._phoneNumberCountryCode = value;
    }
    get showPublicKey(): boolean {
        return this._showPublicKey;
    }

    set showPublicKey(value: boolean) {
        this._showPublicKey = value;
    }

}
