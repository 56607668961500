import {Inject, Injectable} from '@angular/core';
import {EntityService} from '../entity.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ENVIRONMENT_PARAMETER, ShortcutItem, ShortcutItemSerializer} from '@edward-software/edw-fwk-angular-lib/models';

@Injectable({
    providedIn: 'root'
})
export class ShortcutItemService extends EntityService<ShortcutItem> {


    constructor(private http: HttpClient,
                @Inject(ENVIRONMENT_PARAMETER) public environmentParameter: any) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiAuthUrl,
            'shortcuts',
            new ShortcutItemSerializer()
        );
    }

    listFromWorkspaceApp(workspaceId: string): Observable<ShortcutItem[]> {
        return this.http.get(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiAuthUrl}/${this.resource}/${this.environmentParameter.app}/${workspaceId}`)
            .pipe(map((data: any) => this.convertData(data)));
    }

    updatePositionList(ids: string[], workspaceId: string) {
        return this.http.put(`${JSON.parse(localStorage.getItem('ENV_CONFIG')).apiAuthUrl}/${this.resource}/${this.environmentParameter.app}/${workspaceId}/position`, {shortcuts: ids}, this.options);
    }
}
