import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {EntitySerializer, GlobalSearch, GlobalSearchItem} from '@edward-software/edw-fwk-angular-lib/models';
import {EntityService} from '../entity.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export abstract class GlobalSearchService extends EntityService<GlobalSearch> {

    constructor(
        protected httpClient: HttpClient,
        protected url: string,
        protected resource: string,
        protected serializer: EntitySerializer,
        protected handler?: HttpBackend) {
        super(
            httpClient,
            url,
            resource,
            serializer,
            handler
        );
    }
    search(param: string = ''): Observable<GlobalSearch> {
        return this.httpClient
            .get(`${this.url}/${this.resource}?${param}`)
            .pipe(map((data: any) => this.serializer.fromJson(data) as GlobalSearch));
    }

    /**
     * Méthode permettant de rediriger vers la bonne route au clic
     */
    abstract redirect(globalSearchItem: GlobalSearchItem): void;
}
