import {Entity} from '../entity-dir/Entity';
import {EdwNote} from '../note-dir/EdwNote';

export class EdwNoteColor extends Entity {
    private _dateCreation: Date;
    private _dateUpdate: Date;
    private _deleted: Date;
    private _color?: string;
    private _name?: string;
    private _userId?: string;
    private _notes: EdwNote[];

    get notes(): EdwNote[] {
        return this._notes;
    }

    set notes(value: EdwNote[]) {
        this._notes = value;
    }
    get userId(): string {
        return this._userId;
    }

    set userId(value: string) {
        this._userId = value;
    }
    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }
    get color(): string {
        return this._color;
    }

    set color(value: string) {
        this._color = value;
    }
    get deleted(): Date {
        return this._deleted;
    }

    set deleted(value: Date) {
        this._deleted = value;
    }
    get dateUpdate(): Date {
        return this._dateUpdate;
    }

    set dateUpdate(value: Date) {
        this._dateUpdate = value;
    }
    get dateCreation(): Date {
        return this._dateCreation;
    }

    set dateCreation(value: Date) {
        this._dateCreation = value;
    }

}
