import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[edwThreadNavbarTabDirective]'
})
export class ThreadNavbarTabDirective {

    constructor(public viewContainerRef: ViewContainerRef) {
    }

}
