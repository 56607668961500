import {Injectable} from '@angular/core';
import {EdwHeaderNavbarService} from '../header-navbar/edw-header-navbar.service';
import * as moment_ from 'moment-timezone';
import {User} from '@edward-software/edw-fwk-angular-lib/models';

@Injectable({
    providedIn: 'root'
})
export class EdwToolsDateService {

    private timezone: string;
    moment = moment_;

    constructor(private headerNavbarService: EdwHeaderNavbarService) {
        this.headerNavbarService.currentUser
            .subscribe(
                resp => {
                    if (resp && resp instanceof User && resp.timezone && resp.timezone !== '') {
                        this.timezone = resp.timezone;
                    } else {
                        this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    }
                }
            );
    }

    /**
     * Prend une date en param (Date venant du Front)
     * convertit la date en string pour l'envoyer le back
     */
    normalize(date: Date): string {
        const m = this.moment(date);
        return m.utc().format('YYYY-MM-DDTHH:mm:ss');
        // return this.moment(m).utc().add(m.utcOffset(), 'm').format('YYYY-MM-DDTHH:mm:ss');

    }

    /**
     * Récupère la date du backend (qui est en string dans le JSON)
     * la convertit en Object Date selon la timezone de l'user-dir
     */
    denormalize(date: string): Date {
        return this.moment.tz(this.moment.utc(date), this.timezone).toDate();
    }
}
