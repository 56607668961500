import {EdwContactValidationStep} from './ContactValidationStep';
import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {EdwContactSerializer} from '../contact-dir/EdwContactSerializer';
import {EdwContact} from '../contact-dir/EdwContact';

export class EdwContactValidationStepSerializer implements EntitySerializer {

    private contactSerializer = new EdwContactSerializer();

    public mapping = new Map([
        ['priority', 'priority'],
        ['sendingDate', 'sending_date'],
        ['subscribe', 'subscribe'],
        ['contactId', 'contact_id']
    ]);

    fromJson(json: any): EdwContactValidationStep {
        const contactValidationStep = new EdwContactValidationStep();
        this.mapping.forEach((value, key, map) => {
            contactValidationStep[key] = json[value];
        });

        if (json['contact_id'] && json['contact_infos']) {
            let contact = new EdwContact();
            contact = this.contactSerializer.fromJson(json['contact_infos']);
            contact.id = json['contact_id'];
            contact.contactPicture = json['contact_infos']['picture_url'];
            contactValidationStep.contact = contact;
        }
        return contactValidationStep;
    }

    toApi(contactValidationStep: EdwContactValidationStep): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = contactValidationStep[key];
        });
        return obj;
    }
}
