import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EdwToolsWorkspaceService} from '../tools/edw-tools-workspace.service';
import {Intercom} from 'ng-intercom';
import {EdwToolsJwtService} from '../tools/edw-tools-jwt.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private http: HttpClient,
        private intercom: Intercom,
        private jwtService: EdwToolsJwtService,
        private workspaceService: EdwToolsWorkspaceService) {
    }

    /**
     * Post le couple (username, mdp) vers le backend qui renvoie le token si c'est OK
     * si on a une réponse du back et token est reçu, on le stocke dans le localStorage
     */
    login(username: string, password: string, two_fa_code: string): Observable<any> {
        return this.http.post<any>(JSON.parse(localStorage.getItem('ENV_CONFIG')).apiAuthUrl + '/login_check', {username, password, two_fa_code})
            .pipe(map(tokens => {
                // login successful if there's a jwt token in the response
                if (tokens && tokens.token) {
                    // store jwt token in local storage to keep user logged in between page refreshes
                    this.jwtService.setAccessItem(tokens);
                }
                return tokens;
            }));
    }

    logout() {
        this.intercom.shutdown();
        this.jwtService.removeAccessToken();
        this.workspaceService.removeWorkspace();
    }

    /**
     * Récupération d'un token tout neuf à partir du refresh token
     * En cas de réponse positive à la requête, on stocke le token dans le localeStorage
     */
    getTokenWithRefreshToken(refresh_token: string) {
        return this.http.post<any>(JSON.parse(localStorage.getItem('ENV_CONFIG')).apiAuthUrl + '/token/refresh', {refresh_token})
            .pipe(map(tokens => {
                if (tokens && tokens.token) {
                    this.jwtService.setAccessItem(tokens);
                }
                return tokens;
            }));
    }


}
