import {Injectable} from '@angular/core';
import {EntityService} from '../entity.service';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {forkJoin, Observable} from 'rxjs';
import {EdwNote, EdwNoteSerializer} from '@edward-software/edw-fwk-angular-lib/models';

@Injectable({
    providedIn: 'root'
})
export class EdwNoteService extends EntityService<EdwNote> {

    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiAuthUrl,
            'notes',
            new EdwNoteSerializer()
        );
    }

    editNoteColor(noteId: string, noteColorId: string) {
        return this.http.put(`${this.url}/${this.resource}/${noteId}/note_color`,
            {
                note_color_id: noteColorId
            },
            this.options
        )
            .pipe(map(
                resp => resp
            ));
    }

    deleteMultiple(noteIds: string[]): Observable<any[]> {
        if (noteIds && noteIds.length > 0) {
            const notes = [];
            noteIds.forEach(id => {
                notes.push(this.http.delete(`${this.url}/${this.resource}/${id}`));
            });

            return forkJoin(notes);
        }
    }

}
