import {EdwContact} from './EdwContact';
import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {EdwContactCompanySerializer} from '../contact-company-dir/EdwContactCompanySerializer';
import {EdwContactCompany} from '../contact-company-dir/EdwContactCompany';
import {EdwPhoneNumberSerializer} from '../phone-number-dir/EdwPhoneNumberSerializer';

export class EdwContactSerializer implements EntitySerializer {

    private contactCompanySerializer = new EdwContactCompanySerializer();
    private phoneNumberSerializer = new EdwPhoneNumberSerializer();


    public mapping = new Map([
        ['id', 'id'],
        ['civility', 'civility'],
        ['firstName', 'first_name'],
        ['lastName', 'last_name'],
        ['firstName2', 'firstName'],
        ['lastName2', 'lastName'],
        ['nickname', 'nickname'],
        ['cellPhoneNumber', 'cell_phone_number'],
        ['jobTitle', 'job_title'],
        ['email', 'email'],
        ['comment', 'comment'],
        ['company', 'company'],
        ['associatedUser', 'associated_user'],
        ['contactPicture', 'contact_picture'],
        ['pictureUrl', 'pictureUrl'],
        ['secondName', 'second_name'],
        ['phoneNumber', 'phone_number'],
        ['phoneNumber2', 'phone_number_2'],
        ['phoneNumberCountryCode', 'phone_number_country_code'],
        ['phoneNumberCountryCode2', 'phone_number_country_code_2'],
    ]);

    fromJson(json: any): EdwContact {
        const contact = new EdwContact();
        this.mapping.forEach((value, key, map) => {
            contact[key] = json[value];
        });

        /**
         * Création des contacts companies
         */

        const ctcCompanies = json['contact_companies'];
        if (ctcCompanies && ctcCompanies instanceof Array) {
            const ctccps: EdwContactCompany[] = [];
            ctcCompanies.forEach(ctccp => {
                ctccps.push(this.contactCompanySerializer.fromJson(ctccp));
            });
            contact.contactCompanies = ctccps;
        }

        /**
         * Création de phone number
         */

        const ctcPhoneNumber = json['phone_number'];
        if (ctcPhoneNumber && ctcPhoneNumber !== null && typeof (ctcPhoneNumber !== 'undefined')){
            contact.phoneNumber = this.phoneNumberSerializer.fromJson(ctcPhoneNumber);
        }
        /**
         * Création de phone number2
         */
        const ctcPhoneNumber2 = json['phone_number_2'];
        if (ctcPhoneNumber2 && ctcPhoneNumber2 !== null && typeof (ctcPhoneNumber2 !== 'undefined')){
            contact.phoneNumber2 = this.phoneNumberSerializer.fromJson(ctcPhoneNumber2);
        }

        return contact;
    }

    toApi(contact: EdwContact): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = contact[key];
        });
        return obj;
    }

}
