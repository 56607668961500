import {EdwFile} from '../file-dir/EdwFile';
import {Entity} from '../entity-dir/Entity';

export class EdwAttachment extends Entity {

    private _attachZoneId?: string;
    private _objectId?: string;
    private _objectType?: string;
    private _file: EdwFile;

    get attachZoneId(): string {
        return this._attachZoneId;
    }

    set attachZoneId(value: string) {
        this._attachZoneId = value;
    }

    get objectId(): string {
        return this._objectId;
    }

    set objectId(value: string) {
        this._objectId = value;
    }

    get objectType(): string {
        return this._objectType;
    }

    set objectType(value: string) {
        this._objectType = value;
    }

    get file(): EdwFile {
        return this._file;
    }

    set file(value: EdwFile) {
        this._file = value;
    }
}
