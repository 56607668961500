import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {EdwToolsWorkspaceService} from '../services/tools/edw-tools-workspace.service';
import {EdwToolsJwtService} from '../services/tools/edw-tools-jwt.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              private workspaceService: EdwToolsWorkspaceService,
              private jwtService: EdwToolsJwtService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem('access_token') && !this.jwtService.isExpired()) {
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['login'], {queryParams: {returnUrl: state.url}});

    return false;
  }
}
