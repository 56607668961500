import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Entity} from '@edward-software/edw-fwk-angular-lib/models';
import {QueryOption} from '@edward-software/edw-fwk-angular-lib/models';
import {PaginationMetaSerializer} from '@edward-software/edw-fwk-angular-lib/models';
import {ListResponse} from '@edward-software/edw-fwk-angular-lib/models';
import {EntitySerializer} from '@edward-software/edw-fwk-angular-lib/models';

export class EntityService<T extends Entity> {

    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    paginationMetaSerializer = new PaginationMetaSerializer();

    constructor(
        protected httpClient: HttpClient,
        protected url: string,
        protected resource: string,
        protected serializer: EntitySerializer,
        protected handler?: HttpBackend) {


    }

    public create(item: T): Observable<T> {
        return this.httpClient
            .post<T>(`${this.url}/${this.resource}`, this.serializer.toApi(item), this.options)
            .pipe(map(data => this.serializer.fromJson(data) as T));
    }


    public update(item: T): Observable<T> {
        return this.httpClient
            .put<T>(`${this.url}/${this.resource}/${item.id}`, this.serializer.toApi(item), this.options)
            .pipe(map(data => this.serializer.fromJson(data) as T));
    }

    public updateField(item: T, field: string): Observable<T> {
        field = this.serializer.mapping.get(field);
        /**
         * JSON.stringify(obj, replacer) permet de récuérer seulement l'attribut souhaité de l'objet
         * On retransforme instantanément en Objet avec JSON.parse()
         * Si on laisse en string, cela fonctionne mais le DateInterceptor ne normalize pas les dates
         */
        return this.httpClient
            .put<T>(`${this.url}/${this.resource}/${item.id}/${field}`, JSON.parse(JSON.stringify(this.serializer.toApi(item), [field])), this.options)
            .pipe(map(data => this.serializer.fromJson(data) as T));
    }

    read(id: string, param: string = ''): Observable<T> {
        return this.httpClient
            .get(`${this.url}/${this.resource}/${id}?${param}`)
            .pipe(map((data: any) => this.serializer.fromJson(data) as T));
    }

    list(queryOption: QueryOption, param: string = ''): Observable<ListResponse<T>> {
        return this.httpClient
            .get(`${this.url}/${this.resource}?${queryOption.toQueryString()}&${param}`)
            .pipe(map((data: any) => {
                return new ListResponse(
                    this.convertData(data.data),
                    this.paginationMetaSerializer.fromJson(data.meta)
                );
            }));
    }

    listUnpaged(): Observable<T[]> {
        return this.httpClient
            .get(`${this.url}/${this.resource}`)
            .pipe(map((data: any) => this.convertData(data)));
    }

    delete(id: string) {
        return this.httpClient
            .delete(`${this.url}/${this.resource}/${id}`);
    }

    protected convertData(data: any): T[] {
        return data.map(item => this.serializer.fromJson(item));
    }
}
