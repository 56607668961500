import {Component, Inject, OnInit} from '@angular/core';
import {ENVIRONMENT_PARAMETER, Toast} from '@edward-software/edw-fwk-angular-lib/models';
import {Subscription} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {filter} from 'rxjs/operators';
import {EdwHeaderNavbarService, EdwToolsToastService, EdwToolsWorkspaceService} from '@edward-software/edw-fwk-angular-lib/providers';

@Component({
    selector: 'edw-common-app',
    templateUrl: 'common-app.component.html',
    styleUrls: ['./common-app.component.scss'],
    providers: [MessageService]
})
export class CommonAppComponent implements OnInit {


    /**
     * Pas besoin de "unsubscribe" car app.component est toujours présent
     */
    toastSubscription: Subscription;

    translationsLoaded = false;
    lang = '';


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        private messageService: MessageService,
        public workspaceService: EdwToolsWorkspaceService,
        private headerNavbarService: EdwHeaderNavbarService,
        private toastService: EdwToolsToastService,
        @Inject(ENVIRONMENT_PARAMETER) private environmentParameter: any) {


        /**
         * Récupération du workspace dans l'URL
         */
        router.events
            .pipe(
                filter(e => e instanceof NavigationEnd))
            .forEach(e => {
                /**
                 * On récupère la route du niveau Secure ou Public modules (s'il y a un workspace dans le routing Secure)
                 */
                const layoutsRoute = route.root.firstChild.snapshot.firstChild;
                /**
                 * Si il y a un workspace dans l'url (après '/w'), et s'il est différent du workspace actuel
                 * alors on enregistre ce workspace dans le localStorage pour l'envoyer ensuite dans le header de toutes les requêtes
                 *
                 * On ajoute aussi {workspaceStorageName} dans le localStorage
                 * Si on se connecte à l'app sans préciser le worspace dans l'url, on est automatiquement rediriger vers
                 * le workspace enregistré s'il y en a un
                 * Sinon on est redirigé vers /w/{workspace}/{homeRoute}
                 */
                if (layoutsRoute && JSON.parse(localStorage.getItem('ENV_CONFIG')).defaultRoute.startsWith('/w/')) {
                    const urlWorkspace = layoutsRoute.paramMap.get('workspace');
                    if (urlWorkspace && urlWorkspace.length > 0) {
                        // if (urlWorkspace !== this.workspaceService.getSystemName()) {
                        //     this.workspaceService.setWorkspace(urlWorkspace);
                        // }
                        this.workspaceService.setWorkspace(urlWorkspace);
                    } else {
                        const url = this.router.url;
                        if (url === '/' || url.startsWith('/w/')) {
                            const workflowWorkspace = this.workspaceService.getSystemName();
                            if (workflowWorkspace && workflowWorkspace !== '') {
                                this.router.navigate(['/w', workflowWorkspace]);
                            } else {
                                this.router.navigate(['/login']);
                            }
                        }
                    }
                }
            });

        this.toastSubscription = this.toastService.getToast()
            .subscribe(toast => {
                if (toast) {
                    this.showToast(toast);
                }
            });
    }

    ngOnInit(): void {


    }

    showToast(toast: Toast) {
        this.messageService.add({severity: toast.severity, summary: toast.summary, detail: toast.detail, life: toast.life, data: toast.data});
    }


}
