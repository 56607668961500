import {Injectable} from '@angular/core';
import {EntityService} from '../entity.service';
import {EdwNoteColor, EdwNoteColorSerializer} from '@edward-software/edw-fwk-angular-lib/models';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class EdwNoteColorService extends EntityService<EdwNoteColor> {

    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiAuthUrl,
            'noteColors',
            new EdwNoteColorSerializer()
        );
    }
}
