import {Entity} from '../entity-dir/Entity';

export class EdwWorkflowValidation extends Entity {
    private _status: string;

    constructor() {
        super();
    }


    get status(): string {
        return this._status;
    }

    set status(value: string) {
        this._status = value;
    }
}
