import {Inject, Injectable} from '@angular/core';
import {EntityService} from '../entity.service';
import {HttpClient} from '@angular/common/http';
import {EdwAttachment, EdwAttachmentSerializer, ENVIRONMENT_PARAMETER} from '@edward-software/edw-fwk-angular-lib/models';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EdwAttachmentService extends EntityService<EdwAttachment> {

    constructor(private http: HttpClient) {
        super(
            http,
            JSON.parse(localStorage.getItem('ENV_CONFIG')).apiFileUrl,
            'attachments',
            new EdwAttachmentSerializer()
        );
    }

    /**
     * Création d'un attachment à partir de
     * - objectId:
     * - objectType
     * - attachZoneId
     * - file
     */
    addFromObjectIdAndObjectTypeAndAttachZoneId(objectId: string, objectType: string, attachZoneId: string, file) {
        const options = {} as any;
        const formData = new FormData();

        /**
         * On ajoute le fichier au formulaire virtuel
         */
        formData.append(`upload_content`, file);
        if (file.name) {
            formData.append(`name`, file.name);
            formData.append(`description`, file.name);
        }

        /**
         * On ajoute les données annexes
         */
        formData.append(`upload_type`, 'binary');
        formData.append(`object_id`, objectId);
        formData.append(`object_type`, objectType.toUpperCase());
        formData.append(`attach_zone_id`, attachZoneId);

        return this.http.post(`${this.url}/${this.resource}`, formData, options)
            .pipe(map(res => this.serializer.fromJson(res) as EdwAttachment
            ));
    }


    deleteMultiple(attachmentIds: string[]): Observable<any[]> {
        if (attachmentIds && attachmentIds.length > 0) {
            const attachments = [];
            attachmentIds.forEach(id => {
                attachments.push(this.http.delete(`${this.url}/${this.resource}/${id}`));
            });

            return forkJoin(attachments);
        }
    }

    /**
     * Récupération attachement et téléchargement dans le navigateur
     */
    download(attachment: EdwAttachment) {
        return this.http.get(`${this.url}/${this.resource}/${attachment.id}?get_file=1&download=1`, {responseType: 'arraybuffer'})
            .pipe(map(res => {
                const binaryData = [];
                binaryData.push(res);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: attachment.file.fileItem.mimeType}));
                downloadLink.setAttribute('download', attachment.file.name);
                document.body.appendChild(downloadLink);
                downloadLink.click();
            }));
    }

    /**
     * Permet d'ouvrir le fichier dans un nouvel onglet (Function pour ne pas devoir télécharger le fichier directement)
     */
    openInNewTab(attachment: EdwAttachment) {
        return this.http.get(`${this.url}/${this.resource}/${attachment.id}?get_file=1&download=1`, {responseType: 'arraybuffer'})
            .pipe(map(res => {
                const binaryData = [];
                binaryData.push(res);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: attachment.file.fileItem.mimeType}));
                downloadLink.setAttribute('download', attachment.file.name);
                document.body.appendChild(downloadLink);
                return downloadLink;
            }));
    }

    /**
     * Utilise pour afficher filchier attache dans le component wkf attach panel
     */
    getAttachUrlById(attachment: EdwAttachment) {
        return this.http.get(`${this.url}/${this.resource}/${attachment.id}?get_file=1&get_public_url=1`)
            .pipe(map((data: any) => this.serializer.fromJson(data) as EdwAttachment));
    }
}
