import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LaunchpadService {

    private visible = new BehaviorSubject<boolean>(false);

    constructor() {
    }

    setVisible(val: boolean) {
        this.visible.next(val);
    }

    getVisible(): Observable<boolean> {
        return this.visible.asObservable();
    }
}
