import {Entity} from '../entity-dir/Entity';

export class Milestone extends Entity {

    private _name: string;
    private _stepDate: Date;
    private _position: number;
    private _projectId: string;


    constructor() {
        super();
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get stepDate(): Date {
        return this._stepDate;
    }

    set stepDate(value: Date) {
        this._stepDate = value;
    }

    get position(): number {
        return this._position;
    }

    set position(value: number) {
        this._position = value;
    }

    get projectId(): string {
        return this._projectId;
    }

    set projectId(value: string) {
        this._projectId = value;
    }
}
