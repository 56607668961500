export class JwtWorkspace {
  private _id: string;
  private _roles: string[];
  private _systemName: string;


  get id(): string {
    return this._id;
  }

  get roles(): string[] {
    return this._roles;
  }

  get systemName(): string {
    return this._systemName;
  }

}
