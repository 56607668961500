import {EdwContent} from './EdwContent';
import {EdwFeature} from '../release-feature-dir/EdwFeature';
import {EdwFeatureSerializer} from '../release-feature-dir/EdwFeatureSerializer';
import {EntitySerializer} from '../entity-dir/EntitySerializer';

export class EdwContentSerializer implements EntitySerializer {
    private featureSerializer = new EdwFeatureSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['name', 'name'],
        ['nickName', 'nickname'],
    ]);

    fromJson(json: any): EdwContent {
        const content = new EdwContent();
        this.mapping.forEach((value, key, map) => {
            content[key] = json[value];
        });
        /**
         * Création des EdwFeatures
         */
        const ftr = json['features'];
        if (ftr && ftr instanceof Array) {
            const features: EdwFeature[] = [];
            ftr.forEach(feature => {
                features.push(this.featureSerializer.fromJson(feature));
            });
            content.feature = features;
        }
        return content;
    }

    toApi(content: EdwContent): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = content[key];
        });
        return obj;
    }

}
