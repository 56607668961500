import {Entity} from '../entity-dir/Entity';
import {Picture} from '../picture-dir/Picture';
import {Workspace} from '../workspace-dir/Workspace';

export class Organization extends Entity {
    private _name: string;
    private _color: string;
    private _locality: string;
    private _addressLine1: string;
    private _addressLine2: string;
    private _addressLine3: string;
    private _contactName: string;
    private _country: string;
    private _dateCreation: string;
    private _dateUpdate: string;
    private _deleted: string;
    private _postalCode: string;
    private _position: number;
    private _region: string;
    private _roles: string[];
    private _customSettings: {};
    private _userOrganizations: { roles: string[] }[]; // Utilisé dans Home
    private _organizationPicture: Picture;
    private _workspaces: Workspace[];
    private _isFavorite?: boolean;
    private _isHide?: boolean;

    constructor() {
        super();
        this.userOrganizations = [];
        this.workspaces = [];
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get color(): string {
        return this._color;
    }

    set color(value: string) {
        this._color = value;
    }

    get locality(): string {
        return this._locality;
    }

    set locality(value: string) {
        this._locality = value;
    }

    get addressLine1(): string {
        return this._addressLine1;
    }

    set addressLine1(value: string) {
        this._addressLine1 = value;
    }

    get addressLine2(): string {
        return this._addressLine2;
    }

    set addressLine2(value: string) {
        this._addressLine2 = value;
    }

    get addressLine3(): string {
        return this._addressLine3;
    }

    set addressLine3(value: string) {
        this._addressLine3 = value;
    }

    get contactName(): string {
        return this._contactName;
    }

    set contactName(value: string) {
        this._contactName = value;
    }

    get country(): string {
        return this._country;
    }

    set country(value: string) {
        this._country = value;
    }

    get dateCreation(): string {
        return this._dateCreation;
    }

    set dateCreation(value: string) {
        this._dateCreation = value;
    }

    get dateUpdate(): string {
        return this._dateUpdate;
    }

    set dateUpdate(value: string) {
        this._dateUpdate = value;
    }

    get deleted(): string {
        return this._deleted;
    }

    set deleted(value: string) {
        this._deleted = value;
    }

    get postalCode(): string {
        return this._postalCode;
    }

    set postalCode(value: string) {
        this._postalCode = value;
    }

    get position(): number {
        return this._position;
    }

    set position(value: number) {
        this._position = value;
    }

    get region(): string {
        return this._region;
    }

    set region(value: string) {
        this._region = value;
    }

    get roles(): string[] {
        return this._roles;
    }

    set roles(value: string[]) {
        this._roles = value;
    }

    get customSettings(): {} {
        return this._customSettings;
    }

    set customSettings(value: {}) {
        this._customSettings = value;
    }

    get userOrganizations(): { roles: string[] }[] {
        return this._userOrganizations;
    }

    set userOrganizations(value: { roles: string[] }[]) {
        this._userOrganizations = value;
    }

    get organizationPicture(): Picture {
        return this._organizationPicture;
    }

    set organizationPicture(value: Picture) {
        this._organizationPicture = value;
    }

    get workspaces(): Workspace[] {
        return this._workspaces;
    }

    set workspaces(value: Workspace[]) {
        this._workspaces = value;
    }
    get isFavorite(): boolean {
        return this._isFavorite;
    }

    set isFavorite(value: boolean) {
        this._isFavorite = value;
    }

    get isHide(): boolean {
        return this._isHide;
    }

    set isHide(value: boolean) {
        this._isHide = value;
    }

}
