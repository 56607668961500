import {User} from './User';
import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {PictureSerializer} from '../picture-dir/PictureSerializer';
import {WorkspaceSerializer} from '../workspace-dir/WorkspaceSerializer';
import {Workspace} from '../workspace-dir/Workspace';
import {EdwPhoneNumberSerializer} from '../phone-number-dir/EdwPhoneNumberSerializer';

export class UserSerializer implements EntitySerializer {

    workspaceSerializer = new WorkspaceSerializer();
    pictureSerializer = new PictureSerializer();
    private phoneNumberSerializer = new EdwPhoneNumberSerializer();

    // noteSerializer = new NoteSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['dateCreation', 'date_creation'],
        ['dateUpdate', 'date_update'],
        ['deleted', 'deleted'],
        ['enabled', 'enabled'],
        ['lastLogin', 'last_login'],
        ['username', 'username'],
        ['firstName', 'first_name'],
        ['lastName', 'last_name'],
        ['email', 'email'],
        ['forceLogin', 'force_login'],
        ['locale', 'locale'],
        ['dateLocale', 'date_locale'],
        ['type', 'type'],
        ['roles', 'roles'],
        ['timezone', 'timezone'],
        ['banned', 'banned'],
        ['suspended', 'suspended'],
        ['password', 'password'],
        ['organizations', 'organizations'],
        ['workspaces', 'workspaces'],
        ['avatar', 'avatar'],
        ['twoFactorProviders', 'two_factor_providers'],
        ['phoneNumber', 'phone_number'],
        ['phoneNumberCountryCode', 'phone_number_country_code'],
        ['showPublicKey', 'show_public_key']
    ]);

    fromJson(json: any): User {
        const user = new User();
        this.mapping.forEach((value, key, map) => {
            user[key] = json[value];
        });

        /**
         * Création des workspaces
         */
        const workspaces = json['workspaces'];
        if (workspaces && workspaces instanceof Array) {
            const wks: Workspace[] = [];
            workspaces.forEach(wk => {
                wks.push(this.workspaceSerializer.fromJson(wk));
            });
            user.workspaces = wks;
        }

        /**
         * Création de phone number
         */

        const usrPhoneNumber = json['phone_number'];
        if (usrPhoneNumber && typeof (usrPhoneNumber !== 'undefined')) {
            user.phoneNumber = this.phoneNumberSerializer.fromJson(usrPhoneNumber);
        }
        // /**
        //  * Création des notes
        //  */
        // const notes = json['notes'];
        // if (notes && notes instanceof Array) {
        //     const nts: Note[] = [];
        //     notes.forEach(nt => {
        //         nts.push(this.noteSerializer.fromJson(nt));
        //     });
        //     user.notes = nts;
        // }

        /**
         * Création de l'avatar
         */
        const avatar = json['avatar'];
        if (avatar) {
            user.avatar = this.pictureSerializer.fromJson(avatar);
        }

        return user;
    }

    toApi(user: User): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = user[key];
        });
        return obj;
    }

}
