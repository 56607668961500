import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {EdwValidationStep} from './ValidationStep';
import {EdwContactValidationStep} from './ContactValidationStep';
import {EdwValidationStepResult} from './ValidationStepResult';
import {EdwContactValidationStepSerializer} from './ContactValidationStepSerializer';
import {EdwValidationStepResultSerializer} from './ValidationStepResultSerializer';

export class EdwValidationStepSerializer implements EntitySerializer {

    private contactValidationStepSerializer = new EdwContactValidationStepSerializer();
    private validationStepResultSerializer = new EdwValidationStepResultSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['finalResult', 'final_result'],
        ['validationDate', 'validation_date'],
        ['label', 'label'],
        ['position', 'position'],
        ['stepDuration', 'step_duration'],
        ['description', 'description']

    ]);


    fromJson(json: any): EdwValidationStep {
        const validationStep = new EdwValidationStep();
        this.mapping.forEach((value, key, map) => {
            validationStep[key] = json[value];
        });

        /**
         * Création des contactValidationSteps
         */
        const contactValidationStep = json['contact_validation_steps'];
        if (contactValidationStep && contactValidationStep instanceof Array) {
            const cVSteps: EdwContactValidationStep[] = [];
            contactValidationStep.forEach(cVStep => {
                cVSteps.push(this.contactValidationStepSerializer.fromJson(cVStep));
            });
            validationStep.contactValidationSteps = cVSteps;
        }

        /**
         * Création des validationStepResult
         */
        const validationStepResults = json['validation_step_results'];
        if (validationStepResults && validationStepResults instanceof Array) {
            const vStepResult: EdwValidationStepResult[] = [];
            validationStepResults.forEach(vStep => {
                vStepResult.push(this.validationStepResultSerializer.fromJson(vStep));
            });
            validationStep.validationStepResults = vStepResult;
        }


        return validationStep;
    }

    toApi(validationStep: EdwValidationStep): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = validationStep[key];
        });

        return obj;
    }
}
