import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {PictureSerializer} from '../picture-dir/PictureSerializer';
import {UserData} from './UserData';

export class UserDataSerializer implements EntitySerializer {

    pictureSerializer = new PictureSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['username', 'username'],
        ['firstName', 'first_name'],
        ['lastName', 'last_name'],
        ['avatar', 'avatar'],
    ]);

    fromJson(json: any): UserData {
        const user = new UserData();
        this.mapping.forEach((value, key, map) => {
            user[key] = json[value];
        });
        /**
         * Création de l'avatar
         */
        const avatar = json['avatar'];
        if (avatar) {
            user.avatar = this.pictureSerializer.fromJson(avatar);
        }

        return user;
    }

    toApi(user: UserData): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = user[key];
        });
        return obj;
    }

}
