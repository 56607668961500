import {Inject, Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EdwToolsWorkspaceService} from '../services/tools/edw-tools-workspace.service';
import {EnvConfig, ENVIRONMENT_PARAMETER} from '@edward-software/edw-fwk-angular-lib/models';

/**
 * A chaque fois que l'on fait une requête vers le back,
 * Si on a un access_token dans le localStorage, on ajoute le token du access_token aux Headers de la requête
 */
@Injectable()
export class WorkspaceInterceptor implements HttpInterceptor {

    envConfig: EnvConfig;

    constructor(private workspaceService: EdwToolsWorkspaceService) {
        this.envConfig = JSON.parse(localStorage.getItem('ENV_CONFIG'));
    }

    /**
     * Prend le workspaceId et l'ajoute dans le locale storage
     * Si il y a un workspaceID temporaire, on l'utilise
     * Sinon, on prend le workspaceId par défault en localeStorage
     * On vérifie que ce n'est pas une URL qui n'accepte pas EDW-Workspace en header
     */

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

            let workspaceId: string = null;
            if (this.workspaceService.getTemporaryId()) {
                workspaceId = this.workspaceService.getTemporaryId();
            } else {
                workspaceId = this.workspaceService.getId();
            }
            if (workspaceId !== null && !this.isUrlInNoWorkspaceHeader(request.url)) {
                request = request.clone({
                    setHeaders: {
                        'EDW-Workspace': workspaceId
                    }
                });
            }
            this.workspaceService.setTemporaryId(null);
            return next.handle(request);


    }

    /**
     * Teste si l'api appelée fait partie des API qui n'acceptent pas un EDW-Workspace dans le header de la requête
     * Return true si l'API appelée d'accepte pas EDW-Workspace header
     * return false sinon
     */
    isUrlInNoWorkspaceHeader(url: string) {
            let found = false;
            if (this.envConfig && this.envConfig.noWorkspaceUrls) {
                this.envConfig.noWorkspaceUrls.forEach(apiUrl => {
                    if (url.includes(apiUrl)) {
                        found = true;
                    }
                });
            }

            return found;

    }

}
