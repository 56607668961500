import {Entity} from '../entity-dir/Entity';

export class EdwPublicationContent extends Entity {
    private _content: string;
    private _locale: string;
    private _sendNotification: boolean;


    constructor() {
        super();
    }

    get content(): string {
        return this._content;
    }

    set content(value: string) {
        this._content = value;
    }

    get locale(): string {
        return this._locale;
    }

    set locale(value: string) {
        this._locale = value;
    }

    get sendNotification(): boolean {
        return this._sendNotification;
    }

    set sendNotification(value: boolean) {
        this._sendNotification = value;
    }
}
