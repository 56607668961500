import {EdwWorkflow} from './Workflow';
import {EntitySerializer} from '../entity-dir/EntitySerializer';

export class EdwWorkflowSerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['name', 'name'],
        ['description', 'description'],
        ['type', 'type'],
        ['api', 'api'],
        ['apiType', 'api_type'],
        ['validationUrl', 'validation_url'],
        ['workflowId', 'workflow_id'],
        ['workflowName', 'workflow_name'],
        ['workflowDescription', 'workflow_description']
    ]);

    fromJson(json: any): EdwWorkflow {
        const workflow = new EdwWorkflow();
        this.mapping.forEach((value, key, map) => {
            workflow[key] = json[value];
        });

        if (workflow.workflowId) {
            workflow.id = workflow.workflowId;
        }
        if (workflow.workflowName) {
            workflow.name = workflow.workflowName;
        }
        if (workflow.workflowDescription) {
            workflow.description = workflow.workflowDescription;
        }

        return workflow;
    }

    toApi(workflow: EdwWorkflow): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = workflow[key];
        });
        return obj;
    }
}
