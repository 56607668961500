import {EdwFileItem} from './EdwFileItem';
import {Entity} from '../entity-dir/Entity';


export class EdwFile extends Entity {
    private _name: string;
    private _description: string;
    private _locked: Date;
    private _fileItem: EdwFileItem;
    private _systemDirectory: string;
    private _publicUrl?: string;

    constructor() {
        super();
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get description(): string {
        return this._description;
    }

    set description(value: string) {
        this._description = value;
    }

    get locked(): Date {
        return this._locked;
    }

    set locked(value: Date) {
        this._locked = value;
    }

    get fileItem(): EdwFileItem {
        return this._fileItem;
    }

    set fileItem(value: EdwFileItem) {
        this._fileItem = value;
    }

    get systemDirectory(): string {
        return this._systemDirectory;
    }

    set systemDirectory(value: string) {
        this._systemDirectory = value;
    }
    get publicUrl(): string {
        return this._publicUrl;
    }

    set publicUrl(value: string) {
        this._publicUrl = value;
    }

}
