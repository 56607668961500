import {EdwFile} from './EdwFile';
import {EdwFileItemSerializer} from './EdwFileItemSerializer';
import {EdwFileItem} from './EdwFileItem';
import {EntitySerializer} from '../entity-dir/EntitySerializer';

export class EdwFileSerializer implements EntitySerializer {

    private fileItemSerializer: EdwFileItemSerializer = new EdwFileItemSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['name', 'name'],
        ['description', 'description'],
        ['locked', 'locked'],
        ['publicUrl', 'public_url'],
    ]);

    fromJson(json: any): EdwFile {
        const file = new EdwFile();
        this.mapping.forEach((value, key, map) => {
            file[key] = json[value];
        });

        /**
         * Création de fileItem
         */
        const fileItem = json['file_item'];
        if (fileItem) {
            file.fileItem = this.fileItemSerializer.fromJson(fileItem);
        }

        return file;
    }

    toApi(file: EdwFile): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = file[key];
        });
        if (file.fileItem !== null && file.fileItem instanceof EdwFileItem) {
            obj['file_item_id'] = file.fileItem.id;
        }
        return obj;
    }
}
