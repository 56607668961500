import {Type} from '@angular/core';

export class ThreadNavbarItem {

    private _key: string;
    private _component: Type<any>;
    private _searchComponent: Type<any>;
    private _appComponent: Type<any>;
    private _tooltipTranslateKey: string;
    private _icon: string;
    private _data: any;

    constructor(key: string = null, component: Type<any> = null, searchComponent: Type<any> = null, appComponent: Type<any>, tooltipTranslateKey: string, icon: string = null, data: any = null) {
        this.key = key;
        this.component = component;
        this.searchComponent = searchComponent;
        this.appComponent = appComponent;
        this.tooltipTranslateKey = tooltipTranslateKey;
        this.icon = icon;
        this.data = data;
    }

    get key(): string {
        return this._key;
    }

    set key(value: string) {
        this._key = value;
    }

    get component(): Type<any> {
        return this._component;
    }

    set component(value: Type<any>) {
        this._component = value;
    }

    get searchComponent(): Type<any> {
        return this._searchComponent;
    }

    set searchComponent(value: Type<any>) {
        this._searchComponent = value;
    }

    get appComponent(): Type<any> {
        return this._appComponent;
    }

    set appComponent(value: Type<any>) {
        this._appComponent = value;
    }

    get tooltipTranslateKey(): string {
        return this._tooltipTranslateKey;
    }

    set tooltipTranslateKey(value: string) {
        this._tooltipTranslateKey = value;
    }

    get icon(): string {
        return this._icon;
    }

    set icon(value: string) {
        this._icon = value;
    }

    get data(): any {
        return this._data;
    }

    set data(value: any) {
        this._data = value;
    }
}
