import {LazyLoadEvent} from 'primeng/api';
import {Order} from './Order';

export interface QueryBuilder {
    toQueryMap: () => Map<string, string>;
    toQueryString: () => string;
}

export class QueryOption implements QueryBuilder {
    public offset: number;
    public limit: number;
    public search: string;
    public orders: Order[];
    public params: { key: string, value: string }[];

    constructor() {
        this.orders = [];
        this.params = [];
    }

    /**
     * Configure un QueryOption à partir d'un LazyLoadEvent
     * LazyLoadEvent est un evenement généré par une Table de PrimeNG
     *
     * Retour sameParam qui est false si les paramètres orders ou globalFilter ont changé
     */
    fromLazyLoadEvent(event: LazyLoadEvent) {
        let sameParam = true;
        /**
         * Si le filtre global a changé, on passe sameParma à false
         */
        if (event.globalFilter !== this.search) {
            sameParam = false;
        }
        /**
         * Vérification que les paramètres de tri sont identiques
         * s'ils ont changé, alors on passe sameParam à false
         */
        if ((this.orders && event.multiSortMeta) && this.orders.length !== event.multiSortMeta.length) {
            sameParam = false;
            if (this.orders.length > 0) {
                this.orders.forEach(order => {
                    let found = false;
                    if (event.multiSortMeta.length > 0) {
                        event.multiSortMeta.forEach(sortMeta => {
                            if (sortMeta.field === order.field && sortMeta.order === order.order) {
                                found = true;
                            }
                        });
                    }
                    if (!found) {
                        sameParam = false;
                    }
                });
            }
        }

        /**
         * Modification des paramètres du QueryOption en fonciton du LazyLoadEvent
         */
        this.offset = event.first;
        this.limit = event.rows;
        this.search = event.globalFilter;
        this.orders = [];
        if (event.multiSortMeta && event.multiSortMeta.length > 0) {
            event.multiSortMeta.forEach(order => {
                this.orders.push(new Order(order.field, order.order));
            });
        }

        return sameParam;
    }

    /**
     * Parcourt les paramètres d'une QueryOption et crée un Map en fonction des paramètres à envoyer dans la requête
     *
     * offset, limit et search sont basiques
     *
     * orders et un tableau d'Order : pour chaque Order on a le nom de le nom de la colonne et la direction qui donne ex : orders[0][order]=name&orders[0][by]=asc
     *
     * params est un tableau de paramètre {key, value}
     * pour chaque param on rajoute &key=value
     */
    toQueryMap() {
        const queryMap = new Map<string, string>();
        if (this.offset) {
            queryMap.set('offset', `${this.offset}`);
        }
        if (this.limit) {
            queryMap.set('limit', `${this.limit}`);
        }
        if (this.search && typeof this.search !== 'undefined' && this.search.length > 0) {
            queryMap.set('search', `${this.search}`);
        }
        if (this.orders && this.orders.length > 0) {
            this.orders.forEach((item, i) => {
                queryMap.set(`orders[${i}][order]`, item.field);
                let order: string;
                if (item.order > 0) {
                    order = 'asc';
                } else {
                    order = 'desc';
                }
                queryMap.set(`orders[${i}][by]`, order);
            });
        }
        if (this.params && this.params.length > 0) {
            this.params.forEach(param => {
                queryMap.set(param.key, param.value);
            });
        }
        return queryMap;
    }

    /**
     * Construit la chaîne de caractères que l'on va concaténer à l'url à partir du queryMap
     */
    toQueryString() {
        let queryString = '';
        this.toQueryMap().forEach((value: string, key: string) => {
            queryString = queryString.concat(`${key}=${value}&`);
        });

        return queryString.substring(0, queryString.length - 1);
    }


}
