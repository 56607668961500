import {EdwDateV0} from './EdwDateV0';
import {EntitySerializer} from '../entity-dir/EntitySerializer';


export class EdwDateVoSerializer implements EntitySerializer {

    public mapping = new Map([
        ['id', 'id'],
        ['name', 'name'],


    ]);

    fromJson(json: any): EdwDateV0 {
        const dateV0 = new EdwDateV0();
        this.mapping.forEach((value, key, map) => {
            dateV0[key] = json[value];
        });
        return dateV0;
    }

    toApi(dateV0: EdwDateV0): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = dateV0[key];
        });
        return obj;
    }

}
