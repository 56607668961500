import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'ng-template[colTemplate]'
})
export class ColumnTemplateDirective {
    @Input('colTemplate') name: string;

    constructor(public template: TemplateRef<any>) {}
}
