import {Entity} from '../entity-dir/Entity';
import {User} from '../user-dir/User';
import {UserData} from './UserData';


export class UserWorkspace extends Entity {

    private _position: string;
    private _roles: string[];
    private _user: UserData;

    constructor() {
        super();
    }

    get position(): string {
        return this._position;
    }

    set position(value: string) {
        this._position = value;
    }

    get roles(): string[] {
        return this._roles;
    }

    set roles(value: string[]) {
        this._roles = value;
    }

    get user(): UserData {
        return this._user;
    }

    set user(value: UserData) {
        this._user = value;
    }
}
