import {Entity} from '../entity-dir/Entity';

export class EdwChanges extends Entity {
    private _userId?: string;
    private _date?: number;
    private _objectId?: string;
    private _field?: string;
    private _value?: string;

    get userId(): string {
        return this._userId;
    }

    set userId(value: string) {
        this._userId = value;
    }

    get date(): number {
        return this._date;
    }

    set date(value: number) {
        this._date = value;
    }

    get objectId(): string {
        return this._objectId;
    }

    set objectId(value: string) {
        this._objectId = value;
    }

    get field(): string {
        return this._field;
    }

    set field(value: string) {
        this._field = value;
    }

    get value(): string {
        return this._value;
    }

    set value(value: string) {
        this._value = value;
    }
}
