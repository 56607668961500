import {Entity} from '../entity-dir/Entity';

export class PaginationMeta extends Entity {
    private _limit: number;
    private _currentItems: number;
    private _totalItems: number;
    private _offset: number;
    private _page: number;
    private _totalPages: number;
    private _previousPage: number;
    private _nextPage: number;


    constructor() {
        super();
    }

    get limit(): number {
        return this._limit;
    }

    set limit(value: number) {
        this._limit = value;
    }

    get currentItems(): number {
        return this._currentItems;
    }

    set currentItems(value: number) {
        this._currentItems = value;
    }

    get totalItems(): number {
        return this._totalItems;
    }

    set totalItems(value: number) {
        this._totalItems = value;
    }

    get offset(): number {
        return this._offset;
    }

    set offset(value: number) {
        this._offset = value;
    }

    get page(): number {
        return this._page;
    }

    set page(value: number) {
        this._page = value;
    }

    get totalPages(): number {
        return this._totalPages;
    }

    set totalPages(value: number) {
        this._totalPages = value;
    }

    get previousPage(): number {
        return this._previousPage;
    }

    set previousPage(value: number) {
        this._previousPage = value;
    }

    get nextPage(): number {
        return this._nextPage;
    }

    set nextPage(value: number) {
        this._nextPage = value;
    }
}
