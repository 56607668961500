import {Entity} from '../entity-dir/Entity';

export class ScheduleQuarter extends Entity {

    private _year: number;
    private _quarter: number;
    private _startDate: Date;
    private _endDate: Date;
    private _duration: string;
    private _nbDays: number;


    constructor() {
        super();
    }

    get year(): number {
        return this._year;
    }

    set year(value: number) {
        this._year = value;
    }

    get quarter(): number {
        return this._quarter;
    }

    set quarter(value: number) {
        this._quarter = value;
    }

    get startDate(): Date {
        return this._startDate;
    }

    set startDate(value: Date) {
        this._startDate = value;
    }

    get endDate(): Date {
        return this._endDate;
    }

    set endDate(value: Date) {
        this._endDate = value;
    }

    get nbDays(): number {
        return this._nbDays;
    }

    set nbDays(value: number) {
        this._nbDays = value;
    }


    get duration(): string {
        return this._duration;
    }

    set duration(value: string) {
        this._duration = value;
    }
}
