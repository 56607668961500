import {Entity} from '../entity-dir/Entity';
import {EdwContactValidationStep} from './ContactValidationStep';

export class EdwValidationStepResult extends Entity {

    private _comment: string;
    private _result: string;
    private _sendingDate: Date;
    private _validationDate: Date;
    private _contactId: string;
    private _contactValidationStep: EdwContactValidationStep;

    constructor() {
        super();
    }

    get comment(): string {
        return this._comment;
    }

    set comment(value: string) {
        this._comment = value;
    }

    get result(): string {
        return this._result;
    }

    set result(value: string) {
        this._result = value;
    }

    get sendingDate(): Date {
        return this._sendingDate;
    }

    set sendingDate(value: Date) {
        this._sendingDate = value;
    }

    get validationDate(): Date {
        return this._validationDate;
    }

    set validationDate(value: Date) {
        this._validationDate = value;
    }

    get contactId(): string {
        return this._contactId;
    }

    set contactId(value: string) {
        this._contactId = value;
    }

    get contactValidationStep(): EdwContactValidationStep {
        return this._contactValidationStep;
    }

    set contactValidationStep(value: EdwContactValidationStep) {
        this._contactValidationStep = value;
    }
}
