import {AfterViewInit, Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

@Directive({
    selector: '[edwEllipsisAddTitle]'
})
export class EllipsisAddTitleDirective implements AfterViewInit {
    domElement: any;

    constructor(private renderer: Renderer2, private elementRef: ElementRef) {
        this.domElement = this.elementRef.nativeElement;
    }

    ngAfterViewInit(): void {
        this.renderer.setProperty(this.domElement, 'scrollTop', 1);
        this.isTitleAttribute();
    }

    @HostListener('window:resize', ['$event.target'])
    isTitleAttribute() {
        // to add or remove title attribute on the element when it is changing width.
        (this.domElement.offsetWidth < this.domElement.scrollWidth) ?
            this.renderer.setAttribute(this.domElement, 'title', this.domElement.textContent) :
            this.renderer.removeAttribute(this.domElement, 'title');
    }

}
