import {EdwRelease} from './EdwRelease';
import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {EdwContent} from '../release-content-dir/EdwContent';
import {EdwContentSerializer} from '../release-content-dir/EdwContentSerializer';


export class EdwReleaseSerializer implements EntitySerializer {
    private contentSerializer = new EdwContentSerializer();

    public mapping = new Map([
        ['id', 'id'],
        ['name', 'name'],
        ['publicationDate', 'publication_date'],
        ['version', 'version'],
    ]);

    fromJson(json: any): EdwRelease {
        const release = new EdwRelease();
        this.mapping.forEach((value, key, map) => {
            release[key] = json[value];
        });
        /**
         * Création des EdwContent
         */
        const ctnt = json['content'];
        if (ctnt && ctnt instanceof Array) {
            const contents: EdwContent[] = [];
            ctnt.forEach(content => {
                contents.push(this.contentSerializer.fromJson(content));
            });
            release.content = contents;
        }
        return release;
    }

    toApi(release: EdwRelease): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = release[key];
        });
        return obj;
    }

}
