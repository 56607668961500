import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Toast} from '@edward-software/edw-fwk-angular-lib/models';
import {EdwError} from '@edward-software/edw-fwk-angular-lib/models';
import {EdwHttpError} from '@edward-software/edw-fwk-angular-lib/models';

@Injectable({
    providedIn: 'root'
})
export class EdwToolsToastService {

    private subject = new Subject<Toast>();

    constructor() {
    }


    sendToast(toast: Toast) {
        this.subject.next(toast);
    }

    sendSuccess(summary: string, detail: string) {
        const toast = new Toast('success', summary, detail);
        this.subject.next(toast);
    }

    /**
     * Affichage d'un toast de succès selon un temps défini
     */
    sendSuccessWithTime(summary: string, detail: string, life: number) {
        const toast = new Toast('success', summary, detail);
        if (life) {
            toast.life = life;
        }
        this.subject.next(toast);
    }

    /**
     * Affichage d'un toast de succès selon un temps défini avec une url cliquable
     */
    sendSuccessWithUrl(summary: string, detail: string, life: number, url: string) {
        const toast = new Toast('success', summary, detail);
        if (life) {
            toast.life = life;
        }
        toast.data = url;
        this.subject.next(toast);
    }

    sendInfo(summary: string, detail: string) {
        const toast = new Toast('info', summary, detail);
        this.subject.next(toast);
    }

    sendWarn(summary: string, detail: string) {
        const toast = new Toast('warn', summary, detail);
        this.subject.next(toast);
    }

    /**
     * On empeche d'envoyer un toast si l'erreur est vide.
     * Correction pour empecher d'avoir un toast d'erreur sur la page 401
     */
    sendError(summary: string, detail: string) {
        if (summary && summary !== '' && summary !== null) {
            const toast = new Toast('error', summary, detail);
            this.subject.next(toast);
        }
    }

    sendEdwError(edwError: EdwError) {
        if (edwError && edwError.system_message && edwError.message) {
            const toast = new Toast('error', edwError.system_message, edwError.message);
            this.subject.next(toast);
        }
    }

    sendEdwHttpError(edwHttpError: EdwHttpError, translationPrefix: string = '') {
        if (edwHttpError.message !== null) {
            if (edwHttpError.errors && edwHttpError.errors.length > 0) {
                edwHttpError.errors.forEach((error: EdwError) => {
                    this.sendEdwError(error);
                });
            } else {
                if (edwHttpError.code === 500 && edwHttpError.message === 'systemError') {
                    this.sendError('global.toast.error.summary.systemError', 'global.toast.error.detail.systemError');
                } else {
                    this.sendError(`${(translationPrefix) ? translationPrefix + 'toast.error.summary.' : ''}${edwHttpError.message}`, `${(translationPrefix) ? translationPrefix + 'toast.error.detail.' : ''}${edwHttpError.message}`);
                }
            }
        }
    }


    getToast(): Observable<Toast> {
        return this.subject.asObservable();
    }
}
