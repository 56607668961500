import {Entity} from '../entity-dir/Entity';


export class EdwDateV0 extends Entity {
    private _date: Date;
    private _timezone: string;
    private _timezoneType: number;


    constructor() {
        super();
    }

    get date(): Date {
        return this._date;
    }

    set date(value: Date) {
        this._date = value;
    }

    get timezone(): string {
        return this._timezone;
    }

    set timezone(value: string) {
        this._timezone = value;
    }

    get timezoneType(): number {
        return this._timezoneType;
    }

    set timezoneType(value: number) {
        this._timezoneType = value;
    }
}
