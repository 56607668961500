export class EdwColumnSettings {
    field: any;
    subField: any;
    header: string;
    isEditable: boolean;
    isSortable: boolean;
    styleClassTh: string;
    styleClassTd: string;
    styleClassBody: string;

    constructor(field: any,
                subField: any,
                header: string,
                isEditable: boolean = false,
                isSortable: boolean = false,
                styleClassTh: string = '',
                styleClassTd: string = '',
                styleClassBody: string = '') {
        this.field = field;
        this.subField = subField;
        this.header = header;
        this.isEditable = isEditable;
        this.isSortable = isSortable;
        this.styleClassTh = styleClassTh;
        this.styleClassTd = styleClassTd;
        this.styleClassBody = styleClassBody;

    }
}
