import {EntitySerializer} from '../entity-dir/EntitySerializer';
import {Activity} from './Activity';

export class ActivitySerializer implements EntitySerializer {

    // constructor(private dateService: EdwToolsDateService) {
    // }

    public mapping = new Map([
        ['id', 'id'],
        ['name', 'name'],
        ['startDate', 'start_date'],
        ['endDate', 'end_date'],
        ['duration', 'duration'],
        ['description', 'description'],
        ['position', 'position'],
        ['projectId', 'project_id'],
        ['scheduleId', 'schedule_id'],
    ]);

    fromJson(json: any): Activity {
        const activity = new Activity();
        this.mapping.forEach((value, key, map) => {
            activity[key] = json[value];
        });
        return activity;
    }

    toApi(activity: Activity): any {
        const obj = {};
        this.mapping.forEach((value, key, map) => {
            obj[value] = activity[key];
        });
        return obj;
    }

}
