import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ThreadNavbarContentDirective} from './thread-navbar-content.directive';
import {ColumnTemplateDirective} from './column-template.directive';
import {AutofocusDirective} from './autofocus.directive';
import {ThreadNavbarTabDirective} from './thread-navbar-tab.directive';
import {LoaderDirective} from './loader.directive';
import {NewTabRouterLinkDirective} from './new-tab-router-link.directive';
import {TemplateDirective} from './template.directive';
import {ColumnHeaderTemplateDirective} from './column-header-template.directive';
import {EdwTemplateDirective} from './edw-template.directive';
import { EllipsisAddTitleDirective } from './ellipsis-add-title.directive';

@NgModule({
    declarations: [
        ThreadNavbarContentDirective,
        ThreadNavbarTabDirective,
        ColumnTemplateDirective,
        ColumnHeaderTemplateDirective,
        EdwTemplateDirective,
        AutofocusDirective,
        LoaderDirective,
        NewTabRouterLinkDirective,
        TemplateDirective,
        EllipsisAddTitleDirective,
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ThreadNavbarContentDirective,
        ThreadNavbarTabDirective,
        ColumnTemplateDirective,
        ColumnHeaderTemplateDirective,
        EdwTemplateDirective,
        AutofocusDirective,
        LoaderDirective,
        NewTabRouterLinkDirective,
        TemplateDirective,
        EllipsisAddTitleDirective
    ]
})
export class EdwDirectivesModule {
}
