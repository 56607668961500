import {Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
    selector: '[edwLoader]'
})
export class LoaderDirective {


    @Input()
    set edwLoader(loading: boolean) {
        this.toggleLoader(loading);
    }

    @Input() defaultClass: string;
    @Input() hasLoader = true;

    constructor(private renderer: Renderer2, private hostElement: ElementRef) {
    }

    toggleLoader(loading: boolean) {
        if (loading) {
            this.renderer.removeClass(this.hostElement.nativeElement, `edw-button--${this.defaultClass}`);
            if (this.hasLoader) {
                this.renderer.addClass(this.hostElement.nativeElement, 'edw-button--loading');
            }
            this.renderer.addClass(this.hostElement.nativeElement, 'edw-button--inactive');
            this.renderer.setAttribute(this.hostElement.nativeElement, 'disabled', 'disabled');
        } else if (loading === false) {
            this.renderer.removeClass(this.hostElement.nativeElement, 'edw-button--loading');
            this.renderer.removeClass(this.hostElement.nativeElement, 'edw-button--inactive');
            this.renderer.removeAttribute(this.hostElement.nativeElement, 'disabled');
            this.renderer.addClass(this.hostElement.nativeElement, `edw-button--${this.defaultClass}`);
        }
    }

}
