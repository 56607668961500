import {EdwDateV0} from '../dateVO-dir/EdwDateV0';
import {EdwContent} from '../release-content-dir/EdwContent';
import {Entity} from '../entity-dir/Entity';

export class EdwRelease extends Entity {

    private _name: string;
    private _publicationDate: EdwDateV0;
    private _content?: EdwContent[];
    private _version: string;

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get content(): EdwContent[] {
        return this._content;
    }

    set content(value: EdwContent[]) {
        this._content = value;
    }

    get publicationDate(): EdwDateV0 {
        return this._publicationDate;
    }

    set publicationDate(value: EdwDateV0) {
        this._publicationDate = value;
    }

    get version(): string {
        return this._version;
    }

    set version(value: string) {
        this._version = value;
    }
}
