export class EdwVScrollSettings {
    tableHeight: number;
    rowHeight: number;

    constructor(tableHeight: number,
                rowHeight: number) {
        this.tableHeight = tableHeight;
        this.rowHeight = rowHeight;
    }
}
