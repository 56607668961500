import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class EdwToolsJwtService {

    JWTHelper: JwtHelperService;

    constructor() {
        this.JWTHelper = new JwtHelperService();
    }

    /**
     * return the token as a string
     * return null if no token exists
     */
    private getToken() {
        const access_token = localStorage.getItem('access_token');
        if (access_token == null) {
            return null;
        }
        return JSON.parse(access_token).token;
    }


    getDecodedToken() {
        const rawToken = this.getToken();
        if (rawToken == null) {
            return null;
        }
        return this.JWTHelper.decodeToken(this.getToken());
    }

    getExpirationDate() {
        return this.JWTHelper.getTokenExpirationDate(this.getToken());
    }

    isExpired() {
        return this.JWTHelper.isTokenExpired(this.getToken());
    }

    removeAccessToken() {
        localStorage.removeItem('access_token');
    }

    setAccessItem(tokens: any) {
        localStorage.setItem('access_token', JSON.stringify(tokens));
    }

}
